import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  ViewChild
} from '@angular/core';
import {WsRtcModel} from "../../../../model/ws-rtc.model";
import {UserService} from "../../../../services/user.service";
import {Subject, Subscription} from "rxjs";
import {Room} from "../../../../model/room.model";
import {UserRoomParamService} from "../../../../services/user-room-param.service";
import {MediaStreamService} from "../../../../services/media-stream-service";
import {Content} from "../../../../model/content.model";

declare const $: any;

@Component({
  selector: '[app-video-player-record-grid]',
  templateUrl: './video-player-record-grid.component.html',
  styleUrls: ['./video-player-record-grid.component.css']
})
export class VideoPlayerRecordGridComponent implements OnDestroy, AfterViewInit, AfterContentInit {
  @ViewChild('gridContainer', { static: false }) gridContainer: ElementRef;
  @ViewChild('savedVideo', { static: false }) savedVideo: ElementRef;

  @Input() room: Room;
  @Input() highlightSpeaking: boolean;
  @Input() contents: Content[];
  airContents: Content[] = [];
  contentsStartMap: Map<Content, number> = new Map<Content, number>();

  streams: WsRtcModel[] = [];
  subscriptions: Subscription[] = [];
  speaking: boolean[] = [];
  firstVideo: HTMLVideoElement;

  firstContentHtmlElSub: Subject<HTMLVideoElement> = new Subject<HTMLVideoElement>();

  constructor(public userRoomParamService: UserRoomParamService,
              public userService: UserService,
              public mediaStreamService: MediaStreamService) {
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(value => value.unsubscribe());
  }

  ngAfterViewInit(): void {
    // console.log("RoomPlayerFinishedTranslationBlockComponent " + this.contents);

    // let firstContent: Content = this.contents.splice(0, 1)[0];

    // let htmlVideoElement = document.getElementById(this.savedVideo.nativeElement.id) as HTMLVideoElement;
    //
    // let firstContentDateStart = new Date(firstContent.startTime);
    //
    // let urlStartDateTime = firstContent.startTime.replace('T', '-').substring(0, 19);
    // let sourceUrl = `https://dvr02-tele2test.bradburylab.tv/stream/${firstContent.contentId}/hls/playlist.m3u8?start=${urlStartDateTime}&duration=9999999999`;
    // let sourceUrl = `https://bitdash-a.akamaihd.net/content/MI201109210084_1/m3u8s/f08e80da-bf1d-4e3d-8899-f0f6155f6efa.m3u8`;

    // console.log("RoomPlayerFinishedTranslationBlockComponent video url " + this.sourceUrl);
    // this.mediaStreamService.initializeHlsVideo(htmlVideoElement, sourceUrl);

  }

  ngAfterContentInit(): void {

    let firstContent = this.contents.splice(0, 1)[0];
    let firstContentDateStart = new Date(firstContent.startTime);

    this.contents.forEach(content => {
      const userDateStart = new Date(content.startTime);
      const diffTime = userDateStart.getTime() - firstContentDateStart.getTime();
      const secondsToStart = diffTime / 1000;
      this.contentsStartMap.set(content, secondsToStart)
    });

    this.airContents.push(firstContent);
  }

  contentTrackBy(i: number, content: Content) {
    return content?.contentId;
  }

  addMediaStream(stream: WsRtcModel): void {
    if (this.streams.filter(s => stream.id === s.id).length === 0) {
      this.streams.push(stream);
    }
  }

  setMediaStreams(streams: WsRtcModel[]): void {
    this.streams = streams;
  }

  removeMediaStream(stream: WsRtcModel): void {
    this.streams = this.streams.filter(s => stream.id !== s.id);
  }

  clearAllMediaStreams(): void {
    this.streams = [];
  }

  getName(userId: number): string {
    let member = this.userService.members?.find((member) => member.userId === userId);
    return member ? member.userName : "UNKNOWN";
  }

  getPicture(userId: number): string {
    let member = this.userService.members?.find((member) => member.userId === userId);
    return member?.userPictureUrl;
  }

  isMuted(userId): boolean {
    return this.userRoomParamService.getUserParams(userId)?.muted;
  }

  isCameraDisabled(userId): boolean {
    return this.userRoomParamService.getUserParams(userId)?.cameraDisabled
      || this.userRoomParamService.getUserParams(userId)?.cameraNotAllowed;
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.resizeGrid();
  }

  resizeGrid(): void {
    const calcBlocksSize = (gridContainer) => {
      const { vpWidth, vpHeight, vpArea } = getViewportSize(gridContainer);
      const aspectRatio = 16 / 9;
      const { blocksQuantity } = getBlocksQuantity(gridContainer);
      const isWide = vpWidth / vpHeight > 3;
      const blockGap = isWide ? 6 : 14;

      // console.log('vpWidth', vpWidth, '||| vpHeight', vpHeight, '||| vpArea', vpArea);

      const maxBlockSizeHeight = Math.sqrt(vpArea / blocksQuantity / aspectRatio);
      const maxBlockSizeWidth = maxBlockSizeHeight * aspectRatio;

      // console.log('maxBlockSizeWidth', maxBlockSizeWidth, '||| maxBlockSizeHeight', maxBlockSizeHeight);

      const maxHorizontal = vpWidth / maxBlockSizeWidth;
      const cols = Math.round(maxHorizontal) || 1;
      const rows = Math.ceil(blocksQuantity / cols) || 1;

      // console.log('blocksQuantity', blocksQuantity, '||| maxHorizontal', maxHorizontal);
      // console.log('rows', rows, '||| cols', cols);

      let blockWidth;
      let blockHeight;
      let checkIndex = 0;

      blockWidth = vpWidth / cols - blockGap;
      blockHeight = blockWidth / aspectRatio;

      while(!((rows * (blockHeight + blockGap)) < vpHeight)) {
        checkIndex++;
        blockWidth = blockWidth - checkIndex;
        blockHeight = blockWidth / aspectRatio;
      }

      // console.log('blockWidth', blockWidth, '||| blockHeight', blockHeight);

      return { blockWidth, blockHeight, blockGap };
    };

    const setBlockSize = (gridContainer) => {
      const { blockWidth, blockHeight, blockGap } = calcBlocksSize(gridContainer);

      gridContainer.find('.grid__item').each(function() {
        $(this).css({
          'width': blockWidth,
          'height': blockHeight,
          'margin': blockGap / 2
        });
      });
    };

    const getViewportSize = (gridContainer) => {
      const vpWidth = gridContainer.width();
      const vpHeight = gridContainer.height();
      const vpArea = vpWidth * vpHeight;

      return { vpWidth, vpHeight, vpArea};
    };

    const getBlocksQuantity = (gridContainer) => {
      const blocksQuantity = gridContainer.children().length;

      return { blocksQuantity };
    };

    setBlockSize($(this.gridContainer.nativeElement));
  }
}
