export enum WsRtcModelStateEnum {
  NEW,
  CONNECTED,
  TO_REMOVE
}

export enum PCStateEnum {
  CONNECTED,
  CONNECTION_FAILED,
  RECONNECTING,
}
