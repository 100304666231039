import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-devices-camera-not-allowed-modal',
  templateUrl: './devices-camera-not-allowed-modal.component.html',
  styleUrls: ['./devices-camera-not-allowed-modal.component.css']
})
export class DevicesCameraNotAllowedModalComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
