import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Globals} from "../../../globals";
import {Room} from "../../../../model/room.model";

@Component({
  selector: '[app-video-player-side-controls]',
  templateUrl: './video-player-side-controls.component.html',
  styleUrls: ['./video-player-side-controls.component.css']
})
export class VideoPlayerSideControlsComponent implements OnInit {
  @Input() room: Room;

  @Input() newMessagesCount: number;
  @Input() newQuestionsCount: number;
  @Input() newMembersCount: number;
  @Input() newAttachmentsCount: number;

  @Output() sideControlClick = new EventEmitter<string>();

  constructor(public globals: Globals) { }

  ngOnInit(): void {
  }

}
