import {Component, OnInit} from '@angular/core';

@Component({
  selector: '[app-video-player-record-template-block]',
  templateUrl: './video-player-record-template-block.component.html',
  styleUrls: ['./video-player-record-template-block.component.css']
})
export class VideoPlayerRecordTemplateBlockComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
