import {ComponentFactoryResolver, Injectable} from '@angular/core';
import {StreamTypeEnum} from "../model/enum/stream-type-enum";
import {VideoPreviewBlockDirective} from "../directives/video-preview-block.directive";
import * as Hls from "hls.js";
import {RoomPlayerRecordBlockComponent} from "../pages/room-page/video-player-block/common/record-block/room-player-record-block.component";
import {StateService} from "./state.service";
import {
  InputAModifiedStreamContainer,
  InputAVModifiedStreamContainer,
  InputScreenStreamContainer
} from "../model/stream-container";
import {MediaDevicesService} from "./media-devices.service";
import {DeviceErrorEnum} from "../model/enum/device-error.enum";
import {AudioContextHolder} from "./audio-context.holder";
import {NGXLogger} from "ngx-logger";

@Injectable({ providedIn: 'root' })
export class MediaStreamService {
  private readonly _videoConstraints = {
    width: {
      min: 640,
      ideal: 1280,
      max: 1920
    },
    height: {
      min: 360,
      ideal: 720,
      max: 1080
    },
    frameRate: {
      min: 1,
      max: 30
    },
    deviceId: undefined

    // width: { ideal: 1280 },
    // height: { min: 720, ideal: 720, max: 720 },
    // frameRate: { min: 25, ideal: 25, max: 25 }}
  };

  public videoDeviceId: string;
  public audioInDeviceId: string;
  public audioOutDeviceId: string;
  public audioInVolumeLevel: number = 1;
  public isCameraDisabled: boolean = false;
  public isMuted: boolean = false;
  public isCameraNotAllowed: boolean;
  public deviceSettingsIsChecked: boolean = false;
  public deviceError: DeviceErrorEnum;

  private synchronize_count: number = 0;
  private processedErrorReasons: DeviceErrorEnum[] = [
    DeviceErrorEnum.Permission,
    DeviceErrorEnum.Source,
    DeviceErrorEnum.Unknown,
    DeviceErrorEnum.DeviceNotFound
  ];


  constructor(private resolver: ComponentFactoryResolver,
              private subscriptionService: StateService,
              private logger: NGXLogger
  ) {
  }

  async createInputModifiedStreamContainer(): Promise<InputAModifiedStreamContainer> {
    let userMediaConstraints;

    let videoConstraint;
    if (this.isCameraNotAllowed) {
      this.logger.debug("Web camera not found. Create only audio stream.");
      videoConstraint = false;
    } else {
      this._videoConstraints.deviceId = this.videoDeviceId ? this.videoDeviceId : undefined;
      videoConstraint = this._videoConstraints;
    }

    let audioConstraint = { deviceId: this.audioInDeviceId ? this.audioInDeviceId : undefined };

    userMediaConstraints = {
      video: videoConstraint,
      audio: audioConstraint
    };

    return await navigator.mediaDevices.getUserMedia(userMediaConstraints).then((stream) => {
      let audioContext = AudioContextHolder.audioContext;
      let mediaStreamSource = audioContext.createMediaStreamSource(stream);
      let mediaStreamDestination = audioContext.createMediaStreamDestination();
      let gainNode = audioContext.createGain();

      mediaStreamSource.connect(gainNode);
      gainNode.connect(mediaStreamDestination);
      gainNode.gain.value = this.audioInVolumeLevel;

      let iStreamContainer: InputAModifiedStreamContainer;

      if (userMediaConstraints.video === false) {

        iStreamContainer = new InputAModifiedStreamContainer(
          stream,
          mediaStreamDestination.stream,
          mediaStreamSource,
          gainNode);
      } else {
        stream.getVideoTracks().forEach((videoTrack: MediaStreamTrack) => {
          mediaStreamDestination.stream.addTrack(videoTrack);
          this.logger.info("selected for broadcast video device(videoTrack label): " + videoTrack.label);
        });

        let avStreamContainer = new InputAVModifiedStreamContainer(
          stream,
          mediaStreamDestination.stream,
          mediaStreamSource,
          gainNode);

        avStreamContainer.disableVideo(this.isCameraDisabled);
        iStreamContainer = avStreamContainer;
      }

      stream.getAudioTracks().forEach((audioTrack: MediaStreamTrack) => {
        this.logger.info("selected for broadcast audio device(audioTrack label): " + audioTrack.label);
      });

      this.logger.debug("iStreamContainer.mute(this.isMuted);");
      iStreamContainer.mute(this.isMuted);

      return iStreamContainer;

    }).catch((error) => {
      throw new Error("Error when trying getUserMedia for userMediaConstraints - " + userMediaConstraints + ", error - " + error.toString());
    });

  }

  async createInputScreenContainer(): Promise<InputScreenStreamContainer> {
    let settings = {
      video: {
        aspectRatio: 1.7695852534562213,
        deviceId: 'screen:3:0',
        frameRate: 25,
        height: 1080,
        resizeMode: 'crop-and-scale',
        width: 1920,
        cursor: 'always',
        displaySurface: 'monitor',
        logicalSurface: true
      },
      audio: false
    };

    let stream: MediaStream;
    // @ts-ignore
    if (navigator.getDisplayMedia) {
      // @ts-ignore
      stream = await navigator.getDisplayMedia({ video: true });
      // @ts-ignore
    } else if (navigator.mediaDevices.getDisplayMedia) {
      // @ts-ignore
      stream = await navigator.mediaDevices.getDisplayMedia({ video: true });
    } else {
      // @ts-ignore
      stream = await navigator.mediaDevices.getUserMedia({ video: { mediaSource: 'screen' } });
    }

    return new InputScreenStreamContainer(stream);
  }

  muteStream(stream: MediaStream): void {
    if (stream && stream.getAudioTracks().length > 0) {
      stream.getAudioTracks().forEach((track) => track.enabled = false);
    }
  }

  getCleanInputRoomUserStream(inputId: string): string {
    return inputId.substring(inputId.indexOf(":") + 1);
  }

  createInputId(streamId: string, mask?: string): string {
    if (!mask) {
      mask = 'cam_input';
    }
    return mask + ":" + streamId + ":" + new Date().getTime();
  }

  createOutputId(streamId: string, userId: number, mask?: string): string {
    if (!mask) {
      mask = 'cam_output';
    }
    let cleanInputId = streamId.replace(StreamTypeEnum.CAM_INPUT + ":", "").replace(StreamTypeEnum.SCREEN_INPUT + ":", "");
    return mask + ":" + cleanInputId + ':' + userId;
  }

  createOutputIdByInputId(inputId: string, userId: number): string {
    let maskType = StreamTypeEnum.CAM_OUTPUT;
    if (inputId.startsWith(StreamTypeEnum.CAM_INPUT + ":")) {
      maskType = StreamTypeEnum.CAM_OUTPUT;
      inputId = inputId.replace(StreamTypeEnum.CAM_INPUT + ":", "");
    } else if (inputId.startsWith(StreamTypeEnum.SCREEN_INPUT + ":")) {
      maskType = StreamTypeEnum.SCREEN_OUTPUT;
      inputId = inputId.replace(StreamTypeEnum.SCREEN_INPUT + ":", "");
    }

    return maskType + ":" + inputId + ":" + userId;
  }

  public setDevices(service: MediaDevicesService) {
    if (service.selectedVideoDevice) {
      this.videoDeviceId = service.selectedVideoDevice.deviceId;
    }
    if (service.selectedAudioInputDevice) {
      this.audioInDeviceId = service.selectedAudioInputDevice.deviceId;
    }
    if (service.selectedAudioOutputDevice) {
      this.audioOutDeviceId = service.selectedAudioOutputDevice.deviceId;
    }

    this.audioInVolumeLevel = service.selectedMicrophoneVolume;

    this.isCameraDisabled = service.isCameraDisabled;
    this.isMuted = service.isMuted;

    this.deviceSettingsIsChecked = true;
  }

  public getCurrentDevice(mediaDeviceArr: MediaDeviceInfo[], deviceId: string): MediaDeviceInfo {
    if (mediaDeviceArr && mediaDeviceArr.length > 0) {
      let selDevice = mediaDeviceArr.find(device => {
        return device.deviceId === deviceId;
      });

      return selDevice != null ? selDevice : mediaDeviceArr[0];
    } else {
      return null;
    }
  }

  public createVideoRecord(videoPreview: VideoPreviewBlockDirective,
                           contentId: string,
                           startTime: string,
                           roomOwnerDateStart: any,
                           contentUserId: number): void {

    console.log("createVideoRecord start");
    const userDateStart = new Date(startTime);
    const diffTime = userDateStart.getTime() - roomOwnerDateStart.getTime();
    const constDiffForUser = diffTime / 1000;

    console.log("createVideoRecord  diffSeconds " + constDiffForUser);

    let urlStartDateTime = startTime.replace('T', '-').substring(0, 19);
    let sourceUrl = `https://dvr02-tele2test.bradburylab.tv/stream/${contentId}/hls/playlist.m3u8?start=${urlStartDateTime}&duration=9999999999`;

    const componentFactory = this.resolver.resolveComponentFactory(RoomPlayerRecordBlockComponent);
    const componentRef = videoPreview.viewContainerRef.createComponent(componentFactory);

    componentRef.instance.viewChildLoaded.asObservable().subscribe((htmlVideoElement: HTMLVideoElement) => {
      console.log("createVideoRecord viewChildLoaded start htmlVideoElement " + htmlVideoElement);

      if (htmlVideoElement === null) {
        return;
      }

      this.initializeHlsVideo(htmlVideoElement, sourceUrl);

      this.subscriptionService.subscriptions
        .onAdminRecordStop
        .subscribe(() => {
          htmlVideoElement.pause();
        });

      this.subscriptionService.subscriptions
        .onAdminRecordTimeUpdate
        .subscribe((adminCurrentTime) => {

          let currentDiffForUser = adminCurrentTime - htmlVideoElement.currentTime;
          let diffForCheck = constDiffForUser - currentDiffForUser;
          let newCurrentTime = adminCurrentTime - constDiffForUser;

          // if needed synchronization
          if (!htmlVideoElement.paused && Math.abs(diffForCheck) > 0.4) {
            htmlVideoElement.currentTime = newCurrentTime;
            console.log(`needed synchronization userId ${contentUserId}, synchronize_count ${this.synchronize_count++}, diffForCheck ${diffForCheck}, constDiffForUser ${constDiffForUser}, currentDiffForUser ${currentDiffForUser}`);
          }

          if (currentDiffForUser >= constDiffForUser) {
            if (htmlVideoElement.paused) {
              // console.log(`video started userId ${contentUserId}, synchronize_count ${this.synchronize_count++}, diffForCheck ${diffForCheck}, constDiffForUser ${constDiffForUser}, currentDiffForUser ${currentDiffForUser}`);
              htmlVideoElement.currentTime = newCurrentTime;
              htmlVideoElement.play();
            }
          }

          if (contentUserId == 174) {
            // console.log("htmlVideoElement " + componentRef + " " + adminCurrentTime)
            // let currentDiff = adminCurrentTime - htmlVideoElement.currentTime;
            // console.log("firstDiff " + diffSeconds);
            // console.log("currentDiff " + currentDiff);
            // console.log("diffForCheck " + diffForCheck);
          }

          //
        })
    });
  }

  public initializeHlsVideo(htmlVideoElement, sourceUrl): void {
    if (Hls.isSupported()) {
      var hls = new Hls();
      // bind them together
      hls.attachMedia(htmlVideoElement);
      hls.on(Hls.Events.MEDIA_ATTACHED, function () {
        console.log("createVideoRecord video and hls.js are now bound together !");
        // hls.loadSource(comp_this.sourceUrl);
        hls.loadSource(sourceUrl);
        hls.on(Hls.Events.MANIFEST_PARSED, function (event, data) {
          console.log("createVideoRecord manifest loaded, found " + data.levels.length + " quality level");
        });
      });
    } else {
      console.error("createVideoRecord hls is not supported");
    }
  }

  getDeviceErrorMessage(): string {

    if (this.deviceError === DeviceErrorEnum.Permission) {
      return "Для правильной работы функционала необходимо предоставить разрешения на доступ к камере, микрофону и обновить страницу.";
    } else if (this.deviceError === DeviceErrorEnum.Source) {
      return "В другом браузере или программе уже запущено вещание. Закройте другие вкладки, браузеры или программы, которые сейчас используют ваше оборудование и обновите страницу.";
    } else if (this.deviceError === DeviceErrorEnum.Unknown) {
      return "При получении потока с камеры или микрофона произошла ошибка. Проверьте работоспособность устройств и обновите страницу.";
    } else if (this.deviceError === DeviceErrorEnum.DeviceNotFound) {
      return "На вашем устройстве не обнаружено веб-камеры. Трансляция будет воспроизводится только с аудиовещанием.";
    }

    return "Неизвестная ошибка.";
  }

  iSee() {
    this.deviceError = undefined;
  }

  processErrorReason(reason): DeviceErrorEnum {
    if (reason.message === "Permission denied") {
      this.deviceError = DeviceErrorEnum.Permission;
    } else if (reason.message === "Could not start video source") {
      this.deviceError = DeviceErrorEnum.Source;
    } else if (reason.message === "Requested device not found") {
      this.deviceError = DeviceErrorEnum.DeviceNotFound;
    } else {
      this.deviceError = DeviceErrorEnum.Unknown;
    }

    return this.deviceError;
  }

  public existValidErrorReason(): boolean {
    return this.processedErrorReasons.includes(this.deviceError)
  }
}
