import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {share} from 'rxjs/operators';
import {environment} from '../environments/environment';
import {Room} from '../model/room.model';

@Injectable({ providedIn: 'root' })
export class RoomService {
  private readonly baseUrl = environment.apiUrl;

  constructor(
    private http: HttpClient) {
  }

  getLink(room: Room): string {
    const origin = window.location.origin;
    return room.shortLink ? origin + room.shortLink : `${origin}/room/id/${room.id}`;
  }

  getAll(): Observable<Room[]> {
    return this.http.get<Room[]>(this.baseUrl + 'room/all', {
      withCredentials: true
    }).pipe(share());
  }

  getByLink(id: string): Observable<Room> {
    return this.http.get<Room>(this.baseUrl + `room/${id}`, {
      withCredentials: true
    }).pipe(share());
  }

  createNewRoom(room: Room): Observable<Room> {
    return this.http.post<Room>(this.baseUrl + 'room/new', room, {
      withCredentials: true,
    }).pipe(share());
  }

  updateRoom(room: Room): Observable<Room> {
    return this.http.post<Room>(this.baseUrl + 'room/update', room, {
      withCredentials: true,
    }).pipe(share());
  }
}
