import {Injectable} from "@angular/core";

@Injectable({ providedIn: 'root' })
export class NotificationsService {
  private notificationsMap = new Map<string, Date>();

  public sendNew5Messages(roomName: string): void {
    this.sendNotification('5 новых сообщейний в чате в трансляции "' + roomName + '"');
  }

  public sendTranslationStarted(roomName: string): void {
    this.sendNotification('Трансляция "' + roomName + '" началась');
  }

  public sendNewQuestion(roomName): void {
    this.sendNotification('Новый вопрос от зрителя в трансляции "' + roomName + '"');
  }

  public sendQuestionApproved(roomName): void {
    this.sendNotification('Ваш вопрос одобрен модератором в трансляции "' +roomName + '"');
  }

  public sendTranslationWillStart(roomName): void {
    this.sendNotification('Трансляция "' + roomName + '" начнётся через 5 минут');
  }

  public sendYouAreCalled(roomName): void {
    this.sendNotification('Вас приглашают в эфир в трансляции "' + roomName + '"');
  }

  isSendAvailable(text): boolean {
    return !this.notificationsMap.has(text) || this.notificationsMap.get(text).getTime() + 10000 < new Date().getTime();
  }

  private sendNotification(text: string, callback?) {
    if (this.isSendAvailable(text)) {
      this.notificationsMap.set(text, new Date());

      if (!callback) {
        callback = () => window.focus();
      }
      const title = 'Глаголь';
      const options: NotificationOptions = {
        body: text,
        dir: 'auto',
        icon: '/assets/img/favicon/favicon-32x32.png'
      };
      if (!("Notification" in window)) {
        console.log('Notifications are not supported.');
      } else if (Notification.permission === "granted") {
        const notification = new Notification(title, options);

        notification.onclick = callback;
      } else if (Notification.permission !== 'denied') {
        Notification.requestPermission((permission) => {
          if (permission === "granted") {
            let notification = new Notification(title, options);

            notification.onclick = callback;
          }
        });
      }
    }
  }
}
