import {ChatMessageTypeEnum} from '../enum/chat-message-type.enum';
import {MessageTypeEnum} from "../enum/message-type.enum";
import {BaseMessage} from "./base.message";
import {Role} from "../role.model";
import {ChatAttachment} from "../chatAttachment";

export class ChatMessage extends BaseMessage {
  dateAdded: string;
  roomId: number;
  userId: number;
  userName: string;
  userPictureUrl: string;
  message: string;
  messageType: ChatMessageTypeEnum;
  roomRole: Role;
  type: MessageTypeEnum = MessageTypeEnum.CHAT;
  attachments: ChatAttachment[];

  decode(json: Object): BaseMessage {
    super.decode(json);
    if (this.roomRole) {
      this.roomRole = new Role(this.roomRole.role); //Object.assign<Role, object>(new Role(), this.roomRole);
    }
    return this;
  }
}
