import {FormGroup} from "@angular/forms";
import {StringUtils} from "./string-utils";

export class FormUtils {
  public static arePasswordsMatch(group: FormGroup): void {
    let pass = group.controls['password'];
    let confirmPass = group.controls['confirmPassword'];
    if (pass.value !== confirmPass.value) {
      confirmPass.setErrors({ mustMatch: true });
    } else if (StringUtils.isEmpty(confirmPass.value)) {
      confirmPass.setErrors({ required: true })
    } else {
      confirmPass.setErrors(null);
    }
  }
}
