export enum VideoStreamCommandTypeEnum {
  PUBLISH_STREAM = "PUBLISH_STREAM",
  CONNECTED_TO_STREAM = "CONNECTED_TO_STREAM",
  UPDATE_STREAM = "UPDATE_STREAM",
  STOP_STREAM = "STOP_STREAM",
  FINISH_STREAM = "FINISH_STREAM",
  INVITE_USER_TO_STREAM = "INVITE_USER_TO_STREAM",
  ERROR_STREAM = "ERROR_STREAM",

  STREAM_ONLINE = "STREAM_ONLINE",

  USER_MUTE_STREAM = 'USER_MUTE_STREAM',
  USER_CAMERA_DISABLE = 'USER_CAMERA_DISABLE'
}
