import {Component, Input, OnDestroy} from '@angular/core';
import {Globals} from '../../../globals';
import {UserService} from '../../../../services/user.service';
import {VideoStreamService} from '../../../../services/video-stream.service';
import {Room} from '../../../../model/room.model';
import {UtilsService} from '../../../../services/utils.service';
import {StateService} from "../../../../services/state.service";
import {filter} from "rxjs/operators";
import {Subscription, timer} from "rxjs";
import {MediaDevicesService} from "../../../../services/media-devices.service";
import {UserRoomParamMessage} from "../../../../model/message/user-room-param.message";
import {UserRoomParamService} from "../../../../services/user-room-param.service";
import {MediaStreamService} from "../../../../services/media-stream-service";
import {RoomType} from "../../../../model/enum/room-type";
import {NGXLogger} from "ngx-logger";
import {MediaServerService} from "../../../../services/media-server.service";

@Component({
  selector: '[room-user-invite-block-component]',
  templateUrl: './room-user-invite-block.component.html'
})
export class RoomUserInviteBlockComponent implements OnDestroy {
  @Input('room') room: Room;

  public tryStartTranslation = false;
  public isConnectedToStream = false;
  public currentTime: string;

  private _timerSubscription: Subscription;
  private subscriptions: Subscription[] = [];

  constructor(public globals: Globals,
              public userService: UserService,
              public videoStreamService: VideoStreamService,
              public subscriptionService: StateService,
              public mediaDevicesService: MediaDevicesService,
              public mediaStreamService: MediaStreamService,
              public userRoomParamService: UserRoomParamService,
              public stateService: StateService,
              private mediaServerService: MediaServerService,
              private logger: NGXLogger) {
    this.currentTime = UtilsService.emptyTimeValue;

    this.initConnectedToStreamSubscription();
    this.initConnectUserToStreamSubscription();
  }

  public connectionText(): string {

    if (this.tryStartTranslation) {
      return 'room.stream-connecting';
    }

    if (this.subscriptionService.userIsInvitedToStream && !this.subscriptionService.userIsConnectedToStream) {
      return 'room.stream-connect';
    }

    if (this.subscriptionService.userIsConnectedToStream) {
      return 'room.stream-connected';
    }

    return '';
  }

  connectUserToStream(): void {
    this.logger.info("connectUserToStream, tryStartTranslation: {}", this.tryStartTranslation);
    if (!this.tryStartTranslation) {
      this.tryStartTranslation = true;
      this.mediaDevicesService.checkDevicePermissions(() => {
        this.initTimer();
        this.videoStreamService
          .sendPublishCamStreamMessage(this.globals.user.id, "Try to connect user to stream");
        this.logger.info("Try to connect user to stream, tryStartTranslation: {}"
          , this.tryStartTranslation);

        if (this.mediaStreamService.isCameraNotAllowed) {
          this.userRoomParamService.send(new UserRoomParamMessage(this.globals.user.id, this.room.id, false,
            true, true));
        }
      }, (reason) => {
        this.tryStartTranslation = false;
        this.mediaStreamService.processErrorReason(reason);
        this.logger.error("Connection user to stream error", reason);
      });
    }
  }

  private initConnectUserToStreamSubscription(): void {
    this.subscriptions.push(this.subscriptionService.subscriptions.connectUserToStream.subscribe(() => {
      if (this.room.type == RoomType.CONFERENCE) {
        this.connectUserToStream();
      }
    }));
  }

  private initConnectedToStreamSubscription(): void {
    this.isConnectedToStream = this.mediaServerService.existsConnectedOutputModelByStreamUserId(this.globals.user.id);

    this.logger.debug("RoomMemberStreamControlsBlockComponent.userId: {}", this.globals.user.id);
    this.logger.debug("RoomMemberStreamControlsBlockComponent.isConnectedToStream: {}", this.isConnectedToStream);
    this.logger.debug("RoomMemberStreamControlsBlockComponent.tryStartTranslation: {}", this.tryStartTranslation);

    this.subscriptions.push(this.subscriptionService.subscriptions
      .onConnectedToCreatedStream
      .pipe(filter((createStreamInfo) => createStreamInfo.userId === this.globals.user.id))
      .subscribe((createStreamInfo) => {
        this.logger.debug("Connected to stream, createStreamInfo: {}", createStreamInfo);
        this.isConnectedToStream = createStreamInfo.status;
        if (createStreamInfo.status) {
          this.tryStartTranslation = false;
        }
      }));
  }

  private initTimer(): void {
    this.logger.info("TIME INIT");
    if (this._timerSubscription) {
      this.logger.info("TIME UNSUBSCRIBE");
      this._timerSubscription.unsubscribe();
      this._timerSubscription = null;
    }

    this._timerSubscription = timer(20000)
      .subscribe(() => {
        this.tryStartTranslation = false;
        this._timerSubscription.unsubscribe();
        this._timerSubscription = null;
        this.logger.info("TIME UNSUBSCRIBE");
      });
  }

  ngOnDestroy(): void {
    if (this._timerSubscription) {
      this._timerSubscription.unsubscribe();
    }
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  openSettings() {
    this.stateService.isRoomSettingsOpened = true;
  }
}
