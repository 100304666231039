import {Header} from "./header-param";
import {WsEventEnum} from "../../enum/ws-event.enum";
import {BaseWsEvent} from "./base-ws.event";

export class OutputsNewEvent implements BaseWsEvent {
  readonly event: WsEventEnum = WsEventEnum.OUTPUTS_NEW;
  readonly header: Header;
  readonly body: OutputsNewBody;

  constructor(outputId: string, inputId: string, sdp: string) {
    this.header = new Header(outputId);
    this.body = new OutputsNewBody(inputId, sdp);
  }
}

export class OutputsNewBody {
  readonly id: string;
  readonly sdp: string;

  constructor(inputId: string, sdp: string) {
    this.id = inputId;
    this.sdp = sdp;
  }
}
