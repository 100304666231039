import {Injectable, OnDestroy} from "@angular/core";

@Injectable({ providedIn: 'root' })
export class RecordPlayerStateService implements OnDestroy {

  private _firstContentVideo: HTMLVideoElement;

  get firstContentVideo(): HTMLVideoElement {
    return this._firstContentVideo;
  }

  set firstContentVideo(value: HTMLVideoElement) {
    this._firstContentVideo = value;
  }

  ngOnDestroy(): void {
  }

}
