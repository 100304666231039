import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {QuestionMessage} from "../../../../model/message/question.message";
import {Room} from "../../../../model/room.model";
import {Globals} from "../../../globals";
import {QuestionService} from "../../../../services/question.service";
import {UserService} from "../../../../services/user.service";
import {VideoStreamService} from "../../../../services/video-stream.service";
import {StateService} from "../../../../services/state.service";
import {NotificationsService} from "../../../../services/notifications.service";
import {Subscription} from "rxjs";
import {QuestionMessageStatusTypeEnum} from "../../../../model/enum/question-message-status-type.enum";
import {QuestionsFilterTypeEnum} from "../../../../model/enum/questions-filter-type.enum";
import {DropdownItem} from "../../dropdown/dropdown.component";
import {StringUtils} from "../../../../utils/string-utils";
import {UtilsService} from "../../../../services/utils.service";
import {Member} from "../../../../model/member.model";
import {MediaServerService} from "../../../../services/media-server.service";

@Component({
  selector: '[app-room-questions-tab]',
  templateUrl: './room-questions-tab.component.html',
  styleUrls: ['./room-questions-tab.component.css']
})
export class RoomQuestionsTabComponent implements OnInit, OnDestroy {
  @ViewChild("questionsContainer", {static: false}) questionsContainer: ElementRef;
  @Input() room: Room;
  @Output() showMembersHint = new EventEmitter<boolean>();

  readonly filters: DropdownItem[] = [{
    name: 'fields.all',
    value: QuestionsFilterTypeEnum.ALL
  },{
    name: 'fields.new',
    value: QuestionsFilterTypeEnum.NEW
  },{
    name: 'fields.answered',
    value: QuestionsFilterTypeEnum.ANSWERED
  },{
    name: 'fields.declined',
    value: QuestionsFilterTypeEnum.DECLINED
  },{
    name: 'fields.approved',
    value: QuestionsFilterTypeEnum.APPROVED
  },{
    name: 'fields.by-author',
    value: QuestionsFilterTypeEnum.BY_AUTHOR
  }];

  questionText: string;
  allQuestions: QuestionMessage[];
  questionsFilteredByUser: QuestionMessage[];
  questionsToShow: QuestionsGroup[];
  questionsNumber: number;

  questionsFilter: QuestionsFilterTypeEnum;

  private _subscriptions: Subscription[];
  private _moderatorOnline: boolean;
  private isUserCalledFromQuestions: boolean;

  constructor(public globals: Globals,
              private questionService: QuestionService,
              private userService: UserService,
              private videoStreamService: VideoStreamService,
              public subscriptionService: StateService,
              private mediaServerService: MediaServerService,
              private notificationsService: NotificationsService) {
    this._subscriptions = [];
    this.questionText = '';
    this.allQuestions = [];
    this.questionsFilteredByUser = [];
    this.questionsToShow = [];
    this.isUserCalledFromQuestions = false;

    if (this.globals.userRoomRole.isAdmin) {
      this.questionsFilter = QuestionsFilterTypeEnum.NEW;
    } else {
      this.questionsFilter = QuestionsFilterTypeEnum.ALL;
    }

    this.initOnMessageSubscription();
    this.initOnMessagesSubscription();
    this.initRoleUpdateSubscription();
    this.initMemberUpdatedSubscription();
    this.initOnOutputStatusChangeSubscription();
  }

  @Input()
  set moderatorOnline(value: boolean) {
    this._moderatorOnline = value;
    this.updateQuestionsList();
  }

  private initOnMessageSubscription(): void {
    this._subscriptions.push(this.questionService.onMessage.subscribe(incomingQuestion => {
      const isNewQuestion = !this.allQuestions.some(q => q.id === incomingQuestion.id);
      incomingQuestion.isNewQuestion = isNewQuestion;

      if ((isNewQuestion && (this.globals.userRoomRole.isModerator ||
        (this.globals.userRoomRole.isAdmin && !this._moderatorOnline))) ||
        (this.globals.userRoomRole.isAdmin && incomingQuestion.statusType === QuestionMessageStatusTypeEnum.APPROVED)) {
        this.notificationsService.sendNewQuestion(this.room?.name);
      }

      if (isNewQuestion) {
        this.allQuestions.push(incomingQuestion);
        this.scrollQuestionsToBottom();
      } else {
        this.allQuestions.forEach(question => {
          if (question.id === incomingQuestion.id) {
            if (question.userId === this.globals.user.id &&
              question.statusType !== QuestionMessageStatusTypeEnum.APPROVED &&
              incomingQuestion.statusType === QuestionMessageStatusTypeEnum.APPROVED) {
              this.notificationsService.sendQuestionApproved(this.room?.name);
              this.subscriptionService.moderatorApproveFirstQuestion = true;
            }
            question.statusType = incomingQuestion.statusType;
          }
        });
      }
      this.updateQuestionsList();
    }));
  }

  private initOnMessagesSubscription(): void {
    this._subscriptions.push(this.questionService.onMessages.subscribe(questions => {
      this.allQuestions = questions.reverse();
      this.updateQuestionsList();
      this.scrollQuestionsToBottom();
    }));
  }

  private initRoleUpdateSubscription(): void {
    this._subscriptions.push(this.userService.onCurrentUserRoomRoleUpdate.subscribe(() => {
      this.updateQuestionsList();
    }));
  }

  private initMemberUpdatedSubscription(): void {
    this._subscriptions.push( this.userService.onMembersUpdated.subscribe(() => {
      this.updateQuestionsList();
    }));
  }

  private initOnOutputStatusChangeSubscription(): void {
    this._subscriptions.push(this.mediaServerService.wsOnOutputStatusChange
      .subscribe((wsRtcModel) => {
        if (!wsRtcModel.isConnected) {
          this.allQuestions.filter(q => q.userId === wsRtcModel.streamUserId).forEach(q => {
            if (q.statusType === QuestionMessageStatusTypeEnum.APPROVED ||
              q.statusType === QuestionMessageStatusTypeEnum.ANSWERED ||
              (q.statusType === QuestionMessageStatusTypeEnum.PENDING_APPROVAL && !this._moderatorOnline)) {
              this.questionService.changeStatus(q, QuestionMessageStatusTypeEnum.ANSWERED_ON_AIR);
            }
          });
        }
      }));
  }

  sendMessageToQuestions(): void {
    if (this.subscriptionService.translationIsFinished) {
      return;
    }

    if (this.questionText.trim()) {
      const message = new QuestionMessage();
      message.roomId = this.room.id;
      message.userId = this.globals.user.id;
      message.userName = this.globals.user.name;
      message.question = this.questionText;
      message.statusType = QuestionMessageStatusTypeEnum.PENDING_APPROVAL;
      this.questionService.send(message);
      this.questionText = '';
      document.getElementById('question-message').focus();
    }
    this.scrollQuestionsToBottom();
  }

  getTextAreaDisabled() {
    return this.subscriptionService.translationIsFinished;
  }

  getStatusForUser(question: QuestionMessage): string {
    if (question.statusType === QuestionMessageStatusTypeEnum.PENDING_APPROVAL) {
      return 'room.questions-pending-approval'
    } else if (question.statusType === QuestionMessageStatusTypeEnum.APPROVED) {
      return 'room.questions-approved';
    } else if (question.statusType === QuestionMessageStatusTypeEnum.DISAPPROVED_BY_MODERATOR ||
      question.statusType === QuestionMessageStatusTypeEnum.DISAPPROVED_BY_SPEAKER) {
      return 'room.questions-disapproved';
    } else if (question.statusType === QuestionMessageStatusTypeEnum.ANSWERED) {
      return 'room.questions-answered';
    } else if (question.statusType === QuestionMessageStatusTypeEnum.ANSWERED_ON_AIR) {
      return 'room.questions-processed';
    }
  }

  changeStatusToPending(question: QuestionMessage): void {
    const status = !this.globals.userRoomRole.isAdmin || !this._moderatorOnline ?
      QuestionMessageStatusTypeEnum.PENDING_APPROVAL : QuestionMessageStatusTypeEnum.APPROVED;
    this.questionService.changeStatus(question, status);
  }

  changeStatusToApproved(question: QuestionMessage): void {
    this.questionService.changeStatus(question, QuestionMessageStatusTypeEnum.APPROVED);
  }

  changeStatusToAnswered(question: QuestionMessage): void {
    this.questionService.changeStatus(question, QuestionMessageStatusTypeEnum.ANSWERED);
  }

  changeStatusToDisapproved(question: QuestionMessage): void {
    this.questionService.changeStatus(question, this.globals.userRoomRole.isAdmin ?
      QuestionMessageStatusTypeEnum.DISAPPROVED_BY_SPEAKER : QuestionMessageStatusTypeEnum.DISAPPROVED_BY_MODERATOR);
  }

  questionMessageOnKeyDownEnter(event: any): void {
    event.stopPropagation();
    event.preventDefault();
    this.sendMessageToQuestions();
  }

  questionMessageAddNewLine(event: any): void {
    event.stopPropagation();
    event.preventDefault();
    this.questionText += '\n';
  }

  scrollQuestionsToBottom(): void {
    if (this.questionsContainer?.nativeElement) {
      setTimeout(() =>
        this.questionsContainer.nativeElement.scrollTop = this.questionsContainer.nativeElement.scrollHeight, 100)
    }
  }

  filterChanged(filter: QuestionsFilterTypeEnum): void {
    this.questionsFilter = filter;

    this.updateQuestionsList();
  }

  updateQuestionsList(): void {
    if (this.globals.userRoomRole.isAdmin || this.globals.userRoomRole.isModerator) {
      this.questionsFilteredByUser = this.getQuestionsByUser();

      if (this.questionsFilter === QuestionsFilterTypeEnum.ALL) {
        this.fillQuestionsGroupsPerQuestion(this.questionsFilteredByUser);
      } else if (this.questionsFilter === QuestionsFilterTypeEnum.NEW) {
        this.fillQuestionsGroupsPerQuestion(this.questionsFilteredByUser
          .filter(q => q.statusType === QuestionMessageStatusTypeEnum.PENDING_APPROVAL ||
            (this.globals.userRoomRole.isAdmin && q.statusType === QuestionMessageStatusTypeEnum.APPROVED)));
      } else if (this.questionsFilter === QuestionsFilterTypeEnum.ANSWERED) {
        this.fillQuestionsGroupsPerQuestion(this.questionsFilteredByUser
          .filter(q => q.statusType === QuestionMessageStatusTypeEnum.ANSWERED ||
                       q.statusType === QuestionMessageStatusTypeEnum.ANSWERED_ON_AIR));
      } else if (this.questionsFilter === QuestionsFilterTypeEnum.APPROVED) {
        this.fillQuestionsGroupsPerQuestion(this.questionsFilteredByUser
          .filter(q => q.statusType === QuestionMessageStatusTypeEnum.APPROVED));
      } else if (this.questionsFilter === QuestionsFilterTypeEnum.DECLINED) {
        this.fillQuestionsGroupsPerQuestion(this.questionsFilteredByUser
          .filter(q => q.statusType === QuestionMessageStatusTypeEnum.DISAPPROVED_BY_SPEAKER ||
          (this.globals.userRoomRole.isModerator && q.statusType === QuestionMessageStatusTypeEnum.DISAPPROVED_BY_MODERATOR)));
      } else if (this.questionsFilter === QuestionsFilterTypeEnum.BY_AUTHOR) {
        this.fillQuestionsGroupsByAuthor(this.questionsFilteredByUser);
      }
    } else {
      this.questionsFilteredByUser = this.allQuestions.filter(q => q.userId === this.globals.user.id);
      this.fillQuestionsGroupsPerQuestion(this.questionsFilteredByUser);
    }
  }

  getQuestionsByUser(): QuestionMessage[] {
    if (this.globals.userRoomRole.isModerator) {
      return this.allQuestions;
    } else {
      return this.allQuestions.filter(q => q.statusType === QuestionMessageStatusTypeEnum.APPROVED ||
        q.statusType === QuestionMessageStatusTypeEnum.DISAPPROVED_BY_SPEAKER ||
        q.statusType === QuestionMessageStatusTypeEnum.ANSWERED ||
        q.statusType === QuestionMessageStatusTypeEnum.ANSWERED_ON_AIR ||
        (!this._moderatorOnline && q.statusType === QuestionMessageStatusTypeEnum.PENDING_APPROVAL));
    }
  }

  private fillQuestionsGroupsPerQuestion(questions: QuestionMessage[]): void {
    this.questionsToShow = [];
    questions.forEach(q => {
      let questionsGroup = new QuestionsGroup(false);
      questionsGroup.addQuestion(q, this.userService);
      this.questionsToShow.push(questionsGroup);
    });

    this.questionsNumber = questions.length;
  }

  private fillQuestionsGroupsByAuthor(questions: QuestionMessage[]): void {
    this.questionsToShow = [];
    const questionsGroupMap = new Map<number, QuestionsGroup>();
    questions.forEach(q => {
      let questionsGroup;

      if (questionsGroupMap.has(q.userId)) {
        questionsGroup = questionsGroupMap.get(q.userId);
      } else {
        questionsGroup = new QuestionsGroup(true);
        questionsGroupMap.set(q.userId, questionsGroup);
        this.questionsToShow.push(questionsGroup);
      }
      questionsGroup.addQuestion(q, this.userService);
    });

    this.questionsToShow.sort((q1, q2) => {
      return q1.userName.localeCompare(q2.userName);
    });
    this.questionsNumber = questions.length;
  }

  questionTrigger(event: Event): void {
    UtilsService.questionTrigger(event.currentTarget);
  }

  sendCommandInviteUser(member: Member): void {
    if (!this.isUserCalledFromQuestions) {
      this.isUserCalledFromQuestions = true;
      setTimeout(() => this.showMembersHint.emit(true), 100);
    }
    this.videoStreamService.sendInviteUserToStreamMessage(this.globals.currentStreamId, member.userId);
    member.invited = true;
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(s => s.unsubscribe());
  }
}
class QuestionsGroup {
  private _questions: QuestionMessage[];
  private _userId: number;
  private _userName: string;
  private _userPicture: string;
  private readonly _showExpand: boolean;
  private _member: Member;
  private _avatarSymbols: string;
  private _avatarBackground: string;


  constructor(showExpand: boolean) {
    this._showExpand = showExpand;
  }

  get questions(): QuestionMessage[] {
    return this._questions;
  }

  get userName(): string {
    return this._userName;
  }

  get userPicture(): string {
    return this._userPicture;
  }

  get userId(): number {
    return this._userId;
  }

  get questionStatus(): QuestionMessageStatusTypeEnum {
    return this._questions && this._questions.length === 1 ? this._questions[0].statusType : null;
  }

  get showExpand(): boolean {
    return this._showExpand;
  }

  get member(): Member {
    return this._member;
  }

  get avatarSymbols(): string {
    return this._avatarSymbols;
  }

  get avatarBackground(): string {
    return this._avatarBackground;
  }

  addQuestion(question: QuestionMessage, userService: UserService): void {
    if (!this._questions) {
      this._questions = [];
    }

    if (!this._userId) {
      this._userId = question.userId;
      this._userName = question.userName;
      this._userPicture = question.userPicture;
      const members = userService.members.filter(m => m.userId === this._userId);
      this._member = members && members.length > 0 ? members[0] : null;
      this._avatarSymbols = StringUtils.getFirstSymbols(question.userName);
      this._avatarBackground = StringUtils.getBackgroundColor(question.userName);
    }

    if (question.userId === this._userId) {
      this._questions.push(question);
    }
  }
}
