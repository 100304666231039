import {Globals} from '../../globals';
import {ActivatedRoute, Router} from '@angular/router';
import {RoomService} from '../../../services/room.service';
import {HallService} from '../../../services/hall.service';
import {Component} from '@angular/core';
import {CreateNewRoomHallPageComponent} from "./create-new-room-hall-page.component";
import {NGXLogger} from "ngx-logger";

@Component({
  selector: 'app-create-new-room-page',
  templateUrl: './create-new-room-hall-page.component.html',
  styleUrls: ['./create-new-room-hall-page.component.css']
})
export class CreateNewHallPageComponent extends CreateNewRoomHallPageComponent {
  constructor(globals: Globals, route: ActivatedRoute, router: Router, roomService: RoomService, hallService: HallService, logger: NGXLogger) {
    super(globals, route, router, roomService, hallService, logger);
    this.tabId = 'hall';
  }
}
