import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-errors-page',
  templateUrl: './errors-page.component.html',
  styleUrls: ['./errors-page.component.css']
})
export class ErrorsPageComponent implements OnInit, OnDestroy {
  private paramsSubscription: Subscription;
  public errorHeader: string;
  public errorText: string;
  public showToMainPageBtn: boolean;

  constructor(public route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.paramsSubscription = this.route.params.subscribe((params) => {
      switch (params['errorId']) {
        case '403':
          this.errorHeader = 'no-access.title';
          this.errorText = 'no-access.text';
          this.showToMainPageBtn = true;
          break;
        case '404':
          this.errorHeader = 'not-found.title';
          this.errorText = 'not-found.text';
          this.showToMainPageBtn = true;
          break;
        case '502':
          this.errorHeader = 'bad-gateway.title';
          this.errorText = 'bad-gateway.text';
          this.showToMainPageBtn = false;
          break;
        case '504':
          this.errorHeader = 'gateway-timeout.title';
          this.errorText = 'gateway-timeout.text';
          this.showToMainPageBtn = false;
          break;
        case '500':
        default:
          this.errorHeader = 'internal-server-error.title';
          this.errorText = 'internal-server-error.text';
          this.showToMainPageBtn = false;
          break;
      }
    });
  }

  ngOnDestroy(): void {
    this.paramsSubscription.unsubscribe();
  }
}
