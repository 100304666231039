import {AfterViewInit, Component, HostListener, Input} from '@angular/core';
import {Room} from "../../../../model/room.model";
import {ChatAttachment} from "../../../../model/chatAttachment";
import {AttachmentService} from "../../../../services/attachment.service";
import {ChatMessage} from "../../../../model/message/chat.message";
import {ChatMessageTypeEnum} from "../../../../model/enum/chat-message-type.enum";
import {Globals} from "../../../globals";
import {ChatService} from "../../../../services/chat.service";

declare const $;

@Component({
  selector: '[app-room-attachments-tab]',
  templateUrl: './room-attachments-tab.component.html',
  styleUrls: ['./room-attachments-tab.component.css']
})
export class RoomAttachmentsTabComponent {
  @Input() room: Room;
  @Input() attachments: ChatAttachment[];

  highlight: boolean = false;

  constructor(private attachmentService: AttachmentService,
              private chatService: ChatService,
              private globals: Globals) {
  }

  onDragEnter(event: DragEvent): void {
    event.stopPropagation();
    event.preventDefault();
    this.highlight = true;
  }

  onDragOver(event: DragEvent): void {
    event.stopPropagation();
    event.preventDefault();
    this.highlight = true;
  }

  onDragLeave(event: DragEvent): void {
    event.stopPropagation();
    event.preventDefault();
    this.highlight = false;
  }

  onDrop(event: DragEvent): void {
    event.stopPropagation();
    event.preventDefault();
    this.highlight = false;

    this.uploadFiles(event.dataTransfer.files);
  }

  filesSelected(event): void {
    this.uploadFiles(event.target.files);
  }

  uploadFiles(files: FileList): void {
    this.attachmentService.attach(this.room.id, files,
      (attachment) => {
        this.attachments.splice(this.attachments.indexOf(attachment), 1);
        const chatMessage = new ChatMessage();
        chatMessage.roomId = this.room.id;
        chatMessage.userId = this.globals.user.id;
        chatMessage.userName = this.globals.user.name;
        chatMessage.userPictureUrl = this.globals.user.pictureUrl;
        chatMessage.message = '';
        chatMessage.messageType = ChatMessageTypeEnum.USER;
        chatMessage.attachments = [attachment];
        this.chatService.send(chatMessage);
      }).forEach(a => this.attachments.push(a));
  }
}
