import {Injectable} from "@angular/core";
import {RoomParamMessage} from "../model/message/room-param.message";
import {BaseStompSupportedService} from "./base-stomp-supported.service";
import {NGXLogger} from "ngx-logger";

@Injectable({ providedIn: 'root' })
export class RoomParamService extends BaseStompSupportedService<RoomParamMessage> {
  protected readonly WS_PATH: string = "room-param";

  constructor(logger: NGXLogger) {
    super(logger, RoomParamMessage);
    // this.logSubjects("RoomParamService");
  }
}
