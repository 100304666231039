import {Globals} from "../app/globals";
import {RoomUserMessage} from "../model/message/room-user.message";
import {RoomUserCommandTypeEnum} from "../model/enum/room-user-command-type.enum";
import {RoleTypeEnum} from "../model/enum/role-type.enum";

export class RoomUserMessageCreationUtils {


  constructor(private globals: Globals) {
  }

  public createJoinMessage(reason: string): RoomUserMessage {
    return this.createRoomUserMessage(RoomUserCommandTypeEnum.JOIN, this.globals.user.id, reason);
  }

  public createBrowserInfoMessage(deviceInfo: string): RoomUserMessage {
    return this.createRoomUserMessage(RoomUserCommandTypeEnum.BROWSER_INFO, this.globals.user.id, deviceInfo);
  }

  public createLeaveMessage(reason: string): RoomUserMessage {
    return this.createRoomUserMessage(RoomUserCommandTypeEnum.LEAVE, this.globals.user.id, reason);
  }

  public createOnlineMessage(status: boolean): RoomUserMessage {
    return this.createRoomUserMessage(RoomUserCommandTypeEnum.ONLINE, this.globals.user.id, "", status);
  }

  public createChangeRoleMessage(userId: number, newRole: RoleTypeEnum, reason: string): RoomUserMessage {
    let message = this.createRoomUserMessage(RoomUserCommandTypeEnum.CHANGE_ROLE, userId, reason);
    message.newRole = newRole;
    return message;
  }

  public createReadyForCallMessage(userId: number, streamId: number, status: boolean): RoomUserMessage {
    let message = this.createRoomUserMessage(RoomUserCommandTypeEnum.READY_FOR_CALL, userId, "", status);
    message.streamId = streamId;
    return message;
  }

  private createRoomUserMessage(commandTypeEnum: RoomUserCommandTypeEnum, userId: number, reason?: string, status?: boolean): RoomUserMessage {
    let message = new RoomUserMessage();
    message.userId = userId;
    // message.streamId = streamId;
    message.userName = this.globals.user.name;
    message.userPictureUrl = this.globals.user.pictureUrl;
    // message.newRole = RoleTypeEnum.ROLE_ADMIN;
    message.commandType = commandTypeEnum;
    // message.isOnline = false;
    message.statusParam = status;
    // message.roomRole = new Role();
    message.reason = reason ? reason : "";

    return message;
  }
}
