import {Observable, Subject} from "rxjs";
import {AudioContextHolder} from "../services/audio-context.holder";

export abstract class StreamContainer {
  protected onSpeakSubject: Subject<boolean>;
  stream: MediaStream;

  constructor(stream: MediaStream) {
    this.onSpeakSubject = new Subject<boolean>();
    this.stream = stream;
  }

  toString(): string {
    return this.toStreamString(this.stream);
  }

  protected toStreamString(stream: MediaStream): string {
    let toString = "{ id=" + stream?.id + ", active=" + stream?.active;
    if (stream) {
      stream.getTracks().forEach((track) => {
        toString = toString + ", track.kind=" + track.kind + ", track.enabled=" + track.enabled;
      });
    }

    toString = toString + "}";

    return toString;
  }

  get onSpeak(): Observable<boolean> {
    return this.onSpeakSubject.asObservable();
  }

  public stopStreams(): void {
    this.stopTracks(this.stream);
    this.onSpeakSubject.complete();
  }

  protected stopTracks(stream: MediaStream): void {
    if (stream && stream.getTracks()) {
      stream.getTracks()
        .forEach((track) => {
          track.stop();
          stream.removeTrack(track);
        });
    }
  }
}

export class trackStreamDto {
  track: MediaStreamTrack;
  stream: MediaStream;
}

export abstract class InputStreamContainer extends StreamContainer {

  getTracksForRTCPeerConnection(): trackStreamDto[] {
    let result = [];
    this.getProcessedStream().getTracks().forEach((track) => {
      result.push({ track: track, stream: this.stream });
    });
    return result;
  }

  abstract getProcessedStream(): MediaStream;
}

export class InputScreenStreamContainer extends InputStreamContainer {
  getTracksForRTCPeerConnection(): trackStreamDto[] {
    let result = [];
    this.getProcessedStream().getTracks().forEach((track) => {
      result.push({ track: track, stream: this.stream });
    });
    return result;
  }

  getProcessedStream(): MediaStream {
    return this.stream;
  }
}

export abstract class InputAStreamContainerAbstract extends InputStreamContainer {
  abstract getProcessedStream(): MediaStream;

  public mute(val: boolean): void {
    this.getProcessedStream().getAudioTracks().forEach((track) => {
      track.enabled = !val;
    });
  }

  public getAudioTrackForReplace(): MediaStreamTrack {
    if (this.getProcessedStream().getAudioTracks().length != 1) {
      throw "Error when getAudioTrackForReplace AudioTracks length != 1. InputAStreamContainerAbstract";
    }
    return this.getProcessedStream().getAudioTracks()[0];
  }
}

export class InputAModifiedStreamContainer extends InputAStreamContainerAbstract {
  modifiedStream: MediaStream;
  mediaStreamSource: MediaStreamAudioSourceNode;
  gainNode: GainNode;

  toString(): string {
    let toString = "stream " + this.toStreamString(this.stream);
    toString = toString + ", modifiedStream " + this.toStreamString(this.modifiedStream);
    toString = toString + ", mediaStreamSource { state=" + this.mediaStreamSource.context.state + "}" +
      ", gainNode { sampleRate=" + this.gainNode.context.sampleRate + "}"
    return toString;
  }

  constructor(stream: MediaStream, modifiedAudioStream: MediaStream, mediaStreamSource: MediaStreamAudioSourceNode,
              gainNode: GainNode) {
    super(stream);

    if (modifiedAudioStream.getAudioTracks().length != 1) {
      throw new Error("Error in constructor InputAModifiedStreamContainer. Wrong audio track count.")
    }

    this.modifiedStream = modifiedAudioStream;
    this.mediaStreamSource = mediaStreamSource;
    this.gainNode = gainNode;
  }

  getProcessedStream(): MediaStream {
    return this.modifiedStream;
  }

  public stopStreams(): void {
    super.stopStreams();
    this.mediaStreamSource.disconnect();
    this.gainNode.disconnect();
    this.stopTracks(this.modifiedStream);
  }
}

export class InputAVModifiedStreamContainer extends InputAModifiedStreamContainer {

  constructor(stream: MediaStream, modifiedAudioStream: MediaStream, mediaStreamSource: MediaStreamAudioSourceNode,
              gainNode: GainNode) {
    super(stream, modifiedAudioStream, mediaStreamSource, gainNode);

    if (modifiedAudioStream.getVideoTracks().length != 1) {
      throw new Error("Error in constructor InputAVModifiedStreamContainer. Wrong video track count.")
    }
  }

  public disableVideo(disable: boolean) {
    this.getProcessedStream().getVideoTracks().forEach((track) => {
      track.enabled = !disable;
    });
  }

  public getVideoTrackForReplace(): MediaStreamTrack {
    if (this.getProcessedStream().getVideoTracks().length != 1) {
      throw "Error when call getVideoTrackForReplace length != 1.";
    }
    return this.getProcessedStream().getVideoTracks()[0];
  }
}

export abstract class OutputStreamContainer extends StreamContainer {
}

export class OutputAudioVideoStreamContainer extends OutputStreamContainer {
  private mediaStreamSource: MediaStreamAudioSourceNode;
  private mediaStreamDestination: MediaStreamAudioDestinationNode;
  private scriptProcessor: ScriptProcessorNode;
  private isMuted: boolean;

  constructor(stream: MediaStream) {
    super(stream);

    if (this.stream.getAudioTracks().length > 0) {
      const audioContext = AudioContextHolder.audioContext;

      this.mediaStreamSource = audioContext.createMediaStreamSource(stream);
      this.mediaStreamDestination = audioContext.createMediaStreamDestination();

      const blockSize = 4096;
      const channelCount = 1;

      this.scriptProcessor = audioContext.createScriptProcessor(blockSize, channelCount, channelCount);
      this.mediaStreamSource.connect(this.scriptProcessor);
      this.scriptProcessor.connect(audioContext.destination);

      this.scriptProcessor.onaudioprocess = (event: AudioProcessingEvent): void => {
        const inputBuffer = event.inputBuffer;
        const outputBuffer = event.outputBuffer;
        const len = blockSize * outputBuffer.numberOfChannels;
        let total = 0.0;
        for (let channel = 0; channel < outputBuffer.numberOfChannels; channel++) {
          const inputData = inputBuffer.getChannelData(channel);
          const outputData = outputBuffer.getChannelData(channel);

          for (let sample = 0; sample < inputBuffer.length; sample++) {
            total += Math.abs(inputData[sample]);

            if (this.isMuted) {
              outputData[sample] = 0;
            } else {
              outputData[sample] = inputData[sample];
            }
          }
        }
        const newSpeakValue = Math.sqrt(total / len) * 100 > 5;
        this.onSpeakSubject.next(newSpeakValue);
      };
    }
  }

  mute(val: boolean) {
    this.isMuted = val;
  }

  protected stopTracks(stream: MediaStream) {
    super.stopTracks(stream);
    if (this.mediaStreamSource) {
      this.mediaStreamSource.disconnect();
      this.mediaStreamSource = null;
    }
    if (this.scriptProcessor) {
      this.scriptProcessor.disconnect();
      this.scriptProcessor.onaudioprocess = null;
      this.scriptProcessor = null;
    }
    if (this.mediaStreamDestination) {
      this.mediaStreamDestination.disconnect();
      this.mediaStreamDestination = null;
    }
  }
}
