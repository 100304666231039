import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {environment} from "../../../environments/environment";
import {NGXLogger} from "ngx-logger";

@Component({
  selector: 'app-request-password-page',
  templateUrl: './request-password-page.component.html',
  styleUrls: ['./request-password-page.component.css']
})
export class RequestPasswordPageComponent implements OnInit {
  private readonly apiUrl = environment.apiUrl;

  public emailSent = false;
  public email: string;
  public form: FormGroup;

  constructor(private fb: FormBuilder,
              private http: HttpClient,
              private logger: NGXLogger) {
    this.form = this.fb.group({
        email: new FormControl('', [Validators.email, Validators.required])
      },
      {
        validators: this.emailCheck
      });
  }

  ngOnInit(): void {
  }

  emailCheck(group: FormGroup): void {
    if (group.controls['email'].hasError('notFound') ||
      group.controls['email'].hasError('serverError')) {
      group.controls['email'].setErrors(null);
    }
  }

  submitRestore(): void {
    if (!this.form.pristine && this.form.valid) {
      this.restore();
    }
  }

  restore(): void {
    this.email = this.form.get('email').value;
    let formData: any = new FormData();
    formData.append("email", this.email);

    this.http.post(this.apiUrl + 'login/restore', formData, { responseType: 'text' }).subscribe(
      (response) => {
        this.emailSent = true;
      },
      (error: HttpErrorResponse) => {
        if (error.status === 404) {
          this.form.controls["email"].setErrors({ notFound: true });
        } else {
          this.form.controls["email"].setErrors({ serverError: true });
        }
        this.logger.error("Restore password error", error);
      }
    )
  }
}
