import {Hall} from "../../../model/hall.model";
import {Component} from "@angular/core";
import {Room} from "../../../model/room.model";
import {Subject} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {HallService} from "../../../services/hall.service";
import {HallPageComponent} from "../hall-page.component";
import {UtilsService} from "../../../services/utils.service";
import {Picture} from "../../../model/picture";
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {NGXLogger} from "ngx-logger";


@Component({
  selector: 'create-hall-room-page',
  templateUrl: './create-hall-room-page.component.html'
})
export class CreateHallRoomPageComponent extends HallPageComponent {
  private readonly baseUrl = environment.apiUrl;

  public hall: Hall;
  public rooms: Room[];
  public pictureUrl: string;
  public pictureLabel: string;
  public room: Room;
  public hallFetchSubject: Subject<Hall>;
  public attr: string;
  public isValid: boolean;

  constructor(route: ActivatedRoute,
              router: Router,
              hallService: HallService,
              private httpClient: HttpClient,
              logger: NGXLogger) {
    super(route, router, hallService, logger);

    this.pictureLabel = "Загрузить изображение";
    this.pictureUrl = "/assets/img/cover-empty.png";
    this.room = new Room();
    this.room.dateStart = UtilsService.moment().format('DD/MM/YYYY');
    this.room.timeStart = UtilsService.moment().format('HH:mm');
    this.room.link = UtilsService.getGUIDWithoutSeparators();
    this.isValid = false;
  }


  public uploadPicture(element): void {
    let formData = new FormData();

    formData.append('picture', element.target.files[0]);
    document.getElementById("picFile").setAttribute("value", UtilsService.getGUIDWithoutSeparators());

    this.httpClient.post<Picture>(this.baseUrl + 'picture', formData)
      .subscribe((picture) => {
        console.log(picture.pictureUrl);
        this.pictureUrl = picture.pictureUrl;
        this.room.pictureUrl = picture.pictureUrl;
        this.pictureLabel = "Изменить изображение"
      });
  }

  public onDateStartInput(): void {
    const dateStart: string = this.room.dateStart;
    if (dateStart.length === 10) {
      // console.log("dateStart", dateStart.replace(/\//g, "."));
      const firstPart = dateStart.substring(0, 6);
      const secondPart = dateStart.substring(6, 10);
      this.room.dateStart = firstPart + secondPart;
    }

    this.validate();
  }

  public validate(): void {
    this.isValid = this.room.name.length > 0 &&
      !UtilsService.isBeforeNow(this.room.dateStart + ' ' + this.room.timeStart, 'DD/MM/YYYY HH:mm');

  }

  public saveRoom(): void {
    this.room.hallId = this.hall.id;
    this.room.dateAdded = UtilsService.moment().format('YYYY-MM-DD');
    this.hallService.createNewRoomHall(this.room)
      .subscribe((hall) => {
        this.router.navigate([`hall/id/${this.hall.link}`])
      });
  }

  public close(): void {
    this.router.navigate([`hall/id/${this.hall.link}`]);
  }
}
