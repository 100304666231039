import {VideoPlayerControlsComponent} from "./video-player-controls.component";
import {Component} from "@angular/core";
import {Globals} from "../../../globals";
import {StateService} from "../../../../services/state.service";
import {VideoStreamService} from "../../../../services/video-stream.service";
import {MediaDevicesService} from "../../../../services/media-devices.service";
import {MediaServerService} from "../../../../services/media-server.service";
import {UserRoomParamService} from "../../../../services/user-room-param.service";
import {UserService} from "../../../../services/user.service";
import {MediaStreamService} from "../../../../services/media-stream-service";
import {NGXLogger} from "ngx-logger";

@Component({
  selector: '[app-video-player-user-controls]',
  templateUrl: './video-player-controls.component.html',
  styleUrls: ['./video-player-controls.component.css']
})
export class VideoPlayerUserControlsComponent extends VideoPlayerControlsComponent {
  public isConnectedToStream = false;
  public isShareStreamStarted = false;
  public isUserInvited = false;
  public isMuted = false;
  public isCameraDisabled = false;
  public currentTime: string;
  public controlBack: boolean;

  constructor(globals: Globals,
              subscriptionService: StateService,
              videoStreamService: VideoStreamService,
              mediaDevicesService: MediaDevicesService,
              mediaStreamService: MediaStreamService,
              userRoomParamService: UserRoomParamService,
              mediaServerService: MediaServerService,
              userService: UserService,
              public stateService: StateService,
              logger: NGXLogger) {
    super(globals, subscriptionService, videoStreamService, mediaDevicesService, mediaStreamService, userRoomParamService,
      mediaServerService, userService, logger, 'user');
  }

  public startCamStream(): void {
    if (!this.mediaStreamService.deviceSettingsIsChecked) {
      this.stateService.isRoomSettingsOpened = true;
    } else {
      super.startCamStream();
    }
  }
}
