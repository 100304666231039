import {Injectable, OnDestroy} from '@angular/core';
import {ChatMessage} from '../model/message/chat.message';
import {UtilsService} from './utils.service';
import {BaseStompSupportedService} from "./base-stomp-supported.service";
import {NGXLogger} from "ngx-logger";

@Injectable({ providedIn: 'root' })
export class ChatService extends BaseStompSupportedService<ChatMessage> implements OnDestroy {
  protected readonly WS_PATH: string = "chat";

  constructor(logger: NGXLogger) {
    super(logger, ChatMessage);
    // this.logSubjects("ChatService");
  }

  correctTimeZoneInMessage(message: ChatMessage): void {
    if (message.dateAdded) {
      message.dateAdded = UtilsService.correctTimeZone(message.dateAdded);
    }
  }

  correctNewLineInMessage(message: ChatMessage): void {
    if (message.message) {
      message.message = message.message.replace(/\r?\n/g, '<br/>');
    }
  }
}
