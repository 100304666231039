import {Component, OnInit} from '@angular/core';
import {Globals} from "../../globals";
import {UtilsService} from "../../../services/utils.service";

@Component({
  selector: 'app-invite-user-modal',
  templateUrl: './invite-user-modal.component.html',
  styleUrls: ['./invite-user-modal.component.css']
})
export class InviteUserModalComponent implements OnInit {
  linkCopied: boolean;

  constructor(public globals: Globals) {
    this.linkCopied = false;
  }

  ngOnInit(): void {
  }

  copyLink(): void {
    if (this.globals.invitePass) {
      UtilsService.copyToClipboard('ссылка: ' + this.globals.inviteLink + '\n' + 'пароль: ' +
        this.globals.invitePass);
    } else {
      UtilsService.copyToClipboard(this.globals.inviteLink);
    }
    this.linkCopied = true;
    setTimeout(() => {
      UtilsService.closeFancyBox();
      setTimeout(() => this.linkCopied = false, 300);
    }, 500);
  }
}
