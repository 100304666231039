import {Component, Input, OnInit} from '@angular/core';
import {UserService} from "../../../../services/user.service";
import {Room} from "../../../../model/room.model";
import {MembersFilterTypeEnum} from "../../../../model/enum/members-filter-type.enum";
import {Member} from "../../../../model/member.model";
import {StateService} from "../../../../services/state.service";
import {VideoStreamService} from "../../../../services/video-stream.service";
import {filter} from "rxjs/operators";
import {Globals} from "../../../globals";
import {DropdownItem} from "../../dropdown/dropdown.component";
import {StringUtils} from "../../../../utils/string-utils";
import {RoomType} from "../../../../model/enum/room-type";

@Component({
  selector: '[app-room-users-tab]',
  templateUrl: './room-users-tab.component.html',
  styleUrls: ['./room-users-tab.component.css']
})
export class RoomUsersTabComponent implements OnInit {
  private _room: Room;

  filters: DropdownItem[] = [];

  usersFiltered: Member[] = [];
  selectedFilter: MembersFilterTypeEnum;
  nameFilter: string;

  private _adminStreamId: number;

  constructor(public globals: Globals,
              public subscriptionService: StateService,
              public userService: UserService,
              public videoStreamService: VideoStreamService) {
    this.selectedFilter = MembersFilterTypeEnum.ALL;
    this.usersFiltered = userService.members;
    this.initConnectedToAdminStreamSubscription();
    this.initMemberUpdatedSubscription();
  }

  get room(): Room {
    return this._room;
  }

  @Input()
  set room(value: Room) {
    this._room = value;
    this.filters = [{
      name: 'fields.all',
      value: MembersFilterTypeEnum.ALL
    },{
      name: 'fields.members-active',
      value: MembersFilterTypeEnum.ACTIVE
    }];

    if (value.type === RoomType.CONFERENCE) {
      this.filters.push({
        name: 'fields.members-blocked',
        value: MembersFilterTypeEnum.BLOCKED
      });
    }

    this.filters.push({
      name: 'fields.members-deleted',
      value: MembersFilterTypeEnum.DELETED
    },{
      name: 'fields.members-on-air',
      value: MembersFilterTypeEnum.ONAIR
    });
  }

  ngOnInit(): void {
  }

  usersTrackByFn(index, member: Member) {
    return member.userId;
  }

  changeMembersFilterType(filter: MembersFilterTypeEnum): void {
    this.selectedFilter = filter;
    this.updatedMembersList(this.userService.members);
  }

  isInviteAllActive(): boolean {
    return this.userService.members
      .filter(m => m.isReadyForCall)
      .filter(m => m.userRole.isInviteAllowed)
      .some(m => !m.streamId && !m.isInvited);
  }

  inviteAllOnlineMembers(): void {
    if (this.isInviteAllActive()) {
      this.userService.members
        .filter(member => !member.isInvited && member.userRole.isInviteAllowed)
        .filter((member) => member.isReadyForCall)
        .forEach((member) => {
          member.invited = true;
          this.videoStreamService
            .sendInviteUserToStreamMessage(this._adminStreamId, member.userId);
        });
    }
  }

  filterByName(): void {
    this.updatedMembersList(this.userService.members);
  }

  updatedMembersList(members: Member[]): void {
    if (this.selectedFilter == MembersFilterTypeEnum.ALL) {
      this.usersFiltered = members;
    } else if (this.selectedFilter == MembersFilterTypeEnum.ACTIVE) {
      this.usersFiltered = members.filter((member) => {
        return !member.userRole.isBlocked && !member.userRole.isDeleted
      });
    } else if (this.selectedFilter == MembersFilterTypeEnum.BLOCKED) {
      this.usersFiltered = members.filter((member) => {
        return member.userRole.isBlocked;
      });
    } else if (this.selectedFilter == MembersFilterTypeEnum.DELETED) {
      this.usersFiltered = members.filter((member) => {
        return member.userRole.isDeleted;
      });
    } else if (this.selectedFilter == MembersFilterTypeEnum.ONAIR) {
      this.usersFiltered = members.filter((member) => {
        return !!member.streamId;
      });
    }

    if (StringUtils.isNotEmpty(this.nameFilter)) {
      this.usersFiltered = this.usersFiltered.filter(m => m.userName.toLowerCase()
                                                           .indexOf(this.nameFilter.toLowerCase()) >= 0);
    }

    this.sortMembersList();
  }

  sortMembersList(): void {
    this.usersFiltered = this.usersFiltered.sort((a: Member, b: Member): number => {
      const val1 = a.userRole.isAdmin ? 0 : a.streamId ? 1 : a.userRole.isModerator ? 2 : a.userRole.isDeleted ? 3 :
                   a.userRole.isBlocked ? 4 : a.userRole.isUser ? 5 : 6;

      const val2 = b.userRole.isAdmin ? 0 : b.streamId ? 1 : b.userRole.isModerator ? 2 : b.userRole.isDeleted ? 3 :
                   b.userRole.isBlocked ? 4 : b.userRole.isUser ? 5 : 6;

      return val1 - val2 !== 0 ? val1 - val2 : a.userName.localeCompare(b.userName);
    });
  }

  private initMemberUpdatedSubscription(): void {
    this.userService.onMembersUpdated
      .subscribe(() => {
        this.changeMembersFilterType(this.globals.userRoomRole.isAdmin ? this.selectedFilter :
          MembersFilterTypeEnum.ALL);
      });
  }

  private initConnectedToAdminStreamSubscription(): void {
    this.subscriptionService.subscriptions
      .onConnectedToCreatedStream
      .pipe(filter((createdStreamInfo) => createdStreamInfo.userId === this.globals.adminUserId))
      .subscribe((createdStreamInfo) => {
        if (createdStreamInfo.status && !createdStreamInfo.isShareScreen) {
          this._adminStreamId = createdStreamInfo.streamId;
        }
      });
  }
}
