import {Component, OnInit} from '@angular/core';
import {StateService} from "../../../services/state.service";
import {Globals} from "../../globals";
import {RoomType} from "../../../model/enum/room-type";

@Component({
  selector: 'app-stop-translation-modal',
  templateUrl: './stop-translation-modal.component.html',
  styleUrls: ['./stop-translation-modal.component.css']
})
export class StopTranslationModalComponent implements OnInit {

  constructor(public subscriptionService: StateService,
              public globals: Globals) {
  }

  ngOnInit(): void {
  }

  finishStream(): void {
    this.subscriptionService.subscriptions.sendFinishCamStreamEvent();
  }

  pauseStream(): void {
    this.subscriptionService.subscriptions.sendStopCamStreamEvent();
  }

  isConference(): boolean {
    return this.globals.room?.type === RoomType.CONFERENCE;
  }
}
