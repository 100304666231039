import {AfterViewInit, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {VideoPlayerGridComponent} from "../video-player-grid/video-player-grid.component";
import {filter} from "rxjs/operators";
import {VideoStreamCommandTypeEnum} from "../../../../model/enum/video-stream-command-type.enum";
import {WsRtcModel} from "../../../../model/ws-rtc.model";
import {RoomUserMessage} from "../../../../model/message/room-user.message";
import {MediaDevicesService} from "../../../../services/media-devices.service";
import {MessageScopeEnum} from "../../../../model/enum/message-scope.enum";
import {Room} from "../../../../model/room.model";
import {Globals} from "../../../globals";
import {MediaServerService} from "../../../../services/media-server.service";
import {MediaStreamService} from "../../../../services/media-stream-service";
import {VideoStreamService} from "../../../../services/video-stream.service";
import {StateService} from "../../../../services/state.service";
import {UserService} from "../../../../services/user.service";
import {VideoStreamMessage} from "../../../../model/message/video-stream.message";
import {StreamTypeEnum} from "../../../../model/enum/stream-type-enum";
import {InputAStreamContainerAbstract, InputStreamContainer} from "../../../../model/stream-container";
import {Subscription} from "rxjs";
import {UserRoomParamMessage} from "../../../../model/message/user-room-param.message";
import {UserRoomParamService} from "../../../../services/user-room-param.service";
import {InputIdInfo} from "../../../../model/input-id-info.model";
import {StringUtils} from "../../../../utils/string-utils";
import {NotificationsService} from "../../../../services/notifications.service";
import {NGXLogger} from "ngx-logger";
import {RoomType} from "../../../../model/enum/room-type";
import {Member} from "../../../../model/member.model";

export class VideoPlayerBlockComponent implements AfterViewInit {
  @Input() room: Room;

  @Input() newMessagesCount: number;
  @Input() newQuestionsCount: number;
  @Input() newMembersCount: number;
  @Input() newAttachmentsCount: number;

  @Output() sideControlClick = new EventEmitter<string>();

  @ViewChild('videoPlayerGrid', { static: false })
  public videoPlayerGrid: VideoPlayerGridComponent;
  @ViewChild('speakerGrid', { static: false })
  public speakerGrid: VideoPlayerGridComponent;

  speakerModel: WsRtcModel;
  screenModel: WsRtcModel;

  adminAvatarBackground: string;

  public _onlineMembersCount: number;
  public isSoundEnabled: boolean;

  protected readonly _supportedVideoStreamMessageType: VideoStreamCommandTypeEnum[] = [
    VideoStreamCommandTypeEnum.PUBLISH_STREAM,
    VideoStreamCommandTypeEnum.STOP_STREAM,
    VideoStreamCommandTypeEnum.INVITE_USER_TO_STREAM,
    VideoStreamCommandTypeEnum.USER_MUTE_STREAM,
    VideoStreamCommandTypeEnum.USER_CAMERA_DISABLE,
    VideoStreamCommandTypeEnum.UPDATE_STREAM,
    VideoStreamCommandTypeEnum.FINISH_STREAM
  ];

  private _subscriptions: Subscription[];

  constructor(public globals: Globals,
              public mediaServerService: MediaServerService,
              public mediaStreamService: MediaStreamService,
              public videoStreamService: VideoStreamService,
              public stateService: StateService,
              public userService: UserService,
              public mediaDevicesService: MediaDevicesService,
              public userRoomParamService: UserRoomParamService,
              private notificationsService: NotificationsService,
              public logger: NGXLogger,
              public mode: string
  ) {
    this._subscriptions = [];

    this.initOnOutputStatusChangeSubscription();
    this.initOnInputStatusChangeSubscription();

    this.initMuteSubscription();
    this.initDisableCamSubscription();

    this.initOnVideoStreamMessage();
    this.initOnUserConnectedToStreamRequest();
    this.initUserReadyForCallRequest();
    this.initOnServerErrorEvent();
    this.initOnMembersUpdateSubscription();

    this.isSoundEnabled = globals.userRoomRole.isAdmin;
    this.mediaServerService.onSoundEnableSwitch
      .subscribe((soundEnabled) => {
        this.isSoundEnabled = soundEnabled;
      })

  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.initOnChangeRoleSubscription();
    });
  }

  protected async videoStreamMessageHandler(videoStreamMessage: VideoStreamMessage): Promise<void> {
    switch (videoStreamMessage.command) {
      case VideoStreamCommandTypeEnum.PUBLISH_STREAM: {
        this.logger.info("Receive command: {}", videoStreamMessage);
        let iStreamContainer: InputStreamContainer;
        if (videoStreamMessage.streamType === StreamTypeEnum.SCREEN_INPUT) {
          try {
            iStreamContainer = await this.stateService.issContainer;
          } catch (err) {
            this.logger.error("Error when trying createInputScreenContainer. {}", err.message);
            throw new Error("Error when trying createInputScreenContainer. " + err.message);
          }
        } else {
          try {
            iStreamContainer = await this.createInputStreamContainer();
          } catch (err) {
            this.stateService.subscriptions
              .sendConnectedToCreatedStreamEvent(videoStreamMessage.toUserId, videoStreamMessage.streamId, false);
            this.logger.error("Error when trying createInputStreamContainer. {}", err.message);
            throw new Error("Error when trying createInputStreamContainer. " + err.message);
          }
        }
        this.logger.debug("Created stream container: {}", iStreamContainer.toString());
        this.publishStream(iStreamContainer, videoStreamMessage);
        break;
      }
      case VideoStreamCommandTypeEnum.UPDATE_STREAM: {
        let iStreamContainer: InputAStreamContainerAbstract;
        try {
          iStreamContainer = await this.createInputStreamContainer();
        } catch (err) {
          this.logger.error("Error when trying createInputStreamContainer. {}", err.message);
          this.stateService.subscriptions
            .sendConnectedToCreatedStreamEvent(videoStreamMessage.toUserId, videoStreamMessage.streamId, false);
          throw new Error("Error when trying createInputStreamContainer. " + err.message);
        }
        this.updateStream(iStreamContainer, videoStreamMessage);

        this.logger.debug("VideoStreamCommandTypeEnum.UPDATE_STREAM, isMuted: {}, isCameraDisabled: {}", this.mediaStreamService.isMuted, this.mediaStreamService.isCameraDisabled);
        this.stateService.requests.sendMuteUserRequest(this.globals.user.id, videoStreamMessage.streamId, this.mediaStreamService.isMuted);
        this.stateService.requests.sendDisableCamRequest(this.globals.user.id, videoStreamMessage.streamId, this.mediaStreamService.isCameraDisabled);

        this.userRoomParamService.send(new UserRoomParamMessage(
          this.globals.user.id, videoStreamMessage.roomId, this.mediaStreamService.isMuted, this.mediaStreamService.isCameraDisabled));

        break;
      }
      case VideoStreamCommandTypeEnum.FINISH_STREAM : {
        this.logger.debug("videoStreamMessageHandler FINISH_STREAM " + videoStreamMessage);
        if (this.room.type === RoomType.GROUP_CALL) {
          this.mediaServerService.stopInputStreams();
        }
        this.stopTranslation(videoStreamMessage.streamId);
        this.stateService.userIsInvitedToStream = false;
        this.stateService.subscriptions.sendTranslationFinishedEvent();
        break;
      }

      case VideoStreamCommandTypeEnum.STOP_STREAM: {
        this.stopTranslation(videoStreamMessage.streamId);
        this.stateService.userIsInvitedToStream = false;
        break;
      }
      case VideoStreamCommandTypeEnum.INVITE_USER_TO_STREAM: {
        // show call message for user
        this.stateService.userIsInvitedToStream = true;
        this.notificationsService.sendYouAreCalled(this.room?.name);
        break;
      }
    }
  }

  private async createInputStreamContainer(): Promise<InputAStreamContainerAbstract> {
    try {
      return this.mediaStreamService.createInputModifiedStreamContainer();
    } catch (modifiedStreamErr) {
      this.logger.error("Error when creating modified container. Trying to get simple container", modifiedStreamErr);
      let errMsg = "Error when creating modified container. Trying to get simple container. Error - "
        + modifiedStreamErr.message;
      // console.error(errMsg);
      throw new Error(errMsg);
      // return this.mediaStreamService.createInputSimpledStreamContainer();
    }
  }

  protected initUserReadyForCallRequest(): void {
    this._subscriptions.push(this.stateService.requests
      .onUserReadyForCallRequest
      .subscribe((userId) => {
        let existsUserStream = this.mediaServerService.existsConnectedOutputModelByStreamUserId(userId);
        if (existsUserStream) {
          this.stateService.subscriptions.sendReadyForCallEvent(userId, false);
        } else {
          let connectToSpeakerStream = this.mediaServerService
            .existsConnectedToStreamUserIdOutputModelsByOutputStreamUserId(this.globals.adminUserId, userId);
          this.stateService.subscriptions.sendReadyForCallEvent(userId, connectToSpeakerStream);
        }
      }));
  }

  private initOnMembersUpdateSubscription(): void {
    this._subscriptions.push(this.userService.onMembersUpdated
      .subscribe(() => {
        this._onlineMembersCount = this.userService.members.length;
      }));

  }

  private initOnUserConnectedToStreamRequest(): void {
    this._subscriptions.push(this.stateService.requests
      .onUserConnectedToStreamRequest
      .subscribe((createdStreamInfo) => {
        let wsRtcModel = this.mediaServerService.findOutputUserStream(createdStreamInfo.userId);
        let streamId = wsRtcModel ? wsRtcModel.streamId : null;
        this.stateService
          .subscriptions.sendUserConnectedToStreamEvent(createdStreamInfo.userId, streamId, !!wsRtcModel);
      }));

  }

  protected initDisableCamSubscription(): void {
    this._subscriptions.push(this.stateService.requests
      .onDisableCamRequest
      .subscribe((camDisabledEvent) => {
        if (!this.mediaStreamService.isCameraNotAllowed) {
          let callback = (userId: number, streamId: number, disabled: boolean) => {
            this.stateService.subscriptions.sendDisabledCamEvent(userId, streamId, disabled);
          };

          this.mediaServerService
            .disableVideoStreamByUserId(camDisabledEvent.disabled, camDisabledEvent.userId, callback);
        }
      })
    );
  }

  protected initMuteSubscription(): void {
    this._subscriptions.push(this.stateService.requests
      .onMuteUserRequest
      .subscribe((muteEvent) => {
        let callback = (userId: number, streamId: number, muted: boolean) => {
          this.stateService.subscriptions.sendMutedUserEvent(userId, streamId, muted);
        };
        this.mediaServerService
          .muteStreamByUserId(muteEvent.mute, muteEvent.userId, callback);
      }));
  }

  private initOnChangeRoleSubscription(): void {
    this._subscriptions.push(this.userService.onRoomRoleUpdate
      .subscribe((changeRoleEvent) => {
        this.logger.info("VideoPlayerBlockComponent changeRoleEvent: {}", changeRoleEvent);
        if (changeRoleEvent.oldRole.isAdmin && !changeRoleEvent.newRole.isAdmin) {
          this.changeRoleReDistributeModels();
        }
      }));
  }

  private initOnServerErrorEvent(): void {
    this._subscriptions.push(this.mediaServerService.onServerError
      .subscribe((error) => {
        this.videoStreamService
          .sendErrorStreamMessage(error);
      }));
  }

  private initOnVideoStreamMessage(): void {
    this._subscriptions.push(this.videoStreamService.onMessage
      .pipe(filter(videoStreamMessage => {
        return this._supportedVideoStreamMessageType.indexOf(videoStreamMessage.command) > -1
          &&
          (videoStreamMessage.messageScope === MessageScopeEnum.USER && videoStreamMessage.toUserId === this.globals.user.id)
          || (videoStreamMessage.messageScope === MessageScopeEnum.ROOM && videoStreamMessage.roomId === this.room.id)
      }))
      .subscribe((message: VideoStreamMessage) => {
        this.videoStreamMessageHandler(message)
          .catch(err => {
            console.log(err.stack);
            let errMsg = "Error when handle message: " + err.toString();
            this.logger.error('Error when handle message', err);
            this.videoStreamService.sendErrorStreamMessage(errMsg, message);
          });
      }));
  }

  private initOnInputStatusChangeSubscription(): void {
    this._subscriptions.push(this.mediaServerService.wsOnInputStatusChange
      .subscribe((inputIdInfo) => {
        this.logger.info("this.mediaServerService.wsOnInputStatusChange: {}", inputIdInfo);
        this.onInputStatusChange(inputIdInfo);
      }));
  }

  protected onInputStatusChange(inputIdInfo: InputIdInfo): void {
  }

  private initOnOutputStatusChangeSubscription(): void {
    this._subscriptions.push(this.mediaServerService.wsOnOutputStatusChange
      .subscribe((wsRtcModel) => {
        this.onOutputStatusChange(wsRtcModel, wsRtcModel.isConnected);
      }));
  }

  protected onOutputStatusChange(wsRtcModel: WsRtcModel, online: boolean): void {
    this.logger.debug("onOutputStatusChange({}, {})", wsRtcModel.toString(), online);
    this.stateService.subscriptions
      .sendConnectedToCreatedStreamEvent(wsRtcModel.streamUserId, wsRtcModel.streamId, online, wsRtcModel.isScreenShare);

    this.stateService.isConnectedToStream = this.mediaServerService
      .existsStreamByUserId(this.globals.adminUserId);
    this.stateService.userIsConnectedToStream = this.mediaServerService
      .existsConnectedToStreamUserIdOutputModelsByOutputStreamUserId(this.globals.user.id, this.globals.user.id);
    this.videoStreamService
      .sendConnectedToStreamMessage(wsRtcModel, online);

    this.logger.debug("this.subscriptionService.isConnectedToStream: {}", this.stateService.isConnectedToStream);
    this.logger.debug("this.subscriptionService.userIsConnectedToStream: {}", this.stateService.userIsConnectedToStream);
    this.logger.debug("this.subscriptionService.isConnectedToStream: {}", this.stateService.isConnectedToStream);

    if (online) {
      if (!wsRtcModel.isScreenShare && wsRtcModel.streamUserId === this.globals.adminUserId &&
        this.globals.adminUserId !== this.globals.user.id && this.room.type === RoomType.CONFERENCE &&
        !this.stateService.isConnectedToStream) {
        this.notificationsService.sendTranslationStarted(this.room?.name);
      }

      this.logger.debug("roomType:{}, isAdminModel:{}, isScreenShare:{}",
        this.room.type, this.globals.adminUserId === wsRtcModel.streamUserId, wsRtcModel.isScreenShare);

      if (this.room.type === RoomType.CONFERENCE && this.globals.adminUserId === wsRtcModel.streamUserId) {
        if (wsRtcModel.isScreenShare) {
          if (this.speakerModel) {
            this.speakerGrid.removeMediaStream(this.speakerModel);
            this.logger.info("online this.speakerGrid.removeMediaStream(this.speakerModel);");
          }
          this.speakerGrid.addMediaStream(wsRtcModel);
          this.logger.info("online this.speakerGrid.addMediaStream(speakerScreenModel)");
          this.screenModel = wsRtcModel;
        } else {
          this.speakerModel = wsRtcModel;

          if (!this.screenModel) {
            this.speakerGrid.addMediaStream(wsRtcModel);
            this.logger.info("online this.speakerGrid.addMediaStream(speakerModel)");
          }
        }
      } else {
        if (wsRtcModel.isScreenShare && this.room.type === RoomType.GROUP_CALL) {
          this.screenModel = wsRtcModel;
          this.speakerGrid.addMediaStream(this.screenModel);
          this.logger.debug("this.speakerGrid.addMediaStream(screenModel)");
        } else {
          this.videoPlayerGrid.addMediaStream(wsRtcModel);
          this.logger.info("online this.videoPlayerGrid.addMediaStream(wsRtcModel)");
        }
      }
    } else {
      if (this.room.type === RoomType.CONFERENCE && this.globals.adminUserId === wsRtcModel.streamUserId) {
        if (!wsRtcModel.isScreenShare) {
          this.speakerGrid.removeMediaStream(this.speakerModel);
          this.speakerGrid.removeMediaStream(this.screenModel);

          this.logger.info("offline this.speakerGrid.removeMediaStream(this.speakerModel);");
          this.logger.info("offline this.speakerGrid.removeMediaStream(this.screenModel);");
          this.logger.info("offline this.videoPlayerGrid.clearAllMediaStreams();");

          this.speakerModel = null;
          this.screenModel = null;
          this.videoPlayerGrid.clearAllMediaStreams();

          this.stateService.isConnectedToStream = false;
          this.stateService.userIsConnectedToStream = false;
        } else {
          this.speakerGrid.removeMediaStream(this.screenModel);
          this.screenModel = null;
          this.speakerGrid.addMediaStream(this.speakerModel);
          this.logger.info("this.speakerGrid.removeMediaStream(this.screenModel);");
          this.logger.info("this.speakerGrid.addMediaStream(this.speakerModel);");
        }
      } else {
        if (wsRtcModel.isScreenShare || this.screenModel && wsRtcModel.streamUserId === this.screenModel.streamUserId) {
          this.speakerGrid.removeMediaStream(this.screenModel);
          this.logger.debug("this.speakerGrid.removeMediaStream(this.screenModel);");
          this.screenModel = null;
        }
        this.videoPlayerGrid.removeMediaStream(wsRtcModel);
        this.logger.info("offline this.videoPlayerGrid.removeMediaStream(wsRtcModel)");
      }
    }
    setTimeout(() => {
      this.speakerGrid.resizeGrid();
      this.videoPlayerGrid.resizeGrid();
    }, 500);

    let member = this.userService.members.find((member) => member.userId === wsRtcModel.streamUserId);

    if (online) {
      if (!member) {
        this.logger.debug("Cannot find member with id {}, creating new.");
        member = new Member(null, wsRtcModel.streamUserId, "UNKNOWN");
        this.userService.members.push(member);
      }
      this.logger.debug('VideoPlayerBlockComponent: Setting streamId (' + member.streamId + ') for user ' + member.userId);
      member.streamId = wsRtcModel.streamId;
    } else if (member) {
      this.logger.debug('VideoPlayerBlockComponent: Setting streamId to null for user with id ' + member.userId);
      member.streamId = null;
    }
    this.userService.sendMembersUpdated();
  }

  protected stopTranslation(streamId: number) {
    this.mediaServerService.stopStreamsByStreamId(streamId);
  }

  protected publishStream(inputStreamContainer: InputStreamContainer, message: VideoStreamMessage): void {
    this.mediaServerService.publishStream(message.inputId, message.contentId, inputStreamContainer);
  }

  protected updateStream(iStreamContainer: InputAStreamContainerAbstract, message: VideoStreamMessage) {
    this.mediaServerService.updateStream(message.streamId, iStreamContainer);
  }

  protected sendVideoStreamMessage(videoStreamMessage: VideoStreamMessage): void {
    this.videoStreamService.send(videoStreamMessage);
  }

  protected sendRoomUserMessage(roomUserMessage: RoomUserMessage): void {
    this.userService.send(roomUserMessage);
  }

  private changeRoleReDistributeModels(): void {
    this.logger.info("this.mediaServerService.changeRoleReDistributeModels();");
    if (this.videoPlayerGrid?.streams.length > 0) {
      this.videoPlayerGrid.clearAllMediaStreams();
    }

    if (this.speakerGrid?.streams.length > 0) {
      this.speakerGrid.clearAllMediaStreams();
      this.speakerModel = null;
    }

    this.mediaServerService.reDistributeModels();
  }

  ngOnDestroy(): void {
    this.logger.debug("Unsubscribe for all subscription");
    this._subscriptions.forEach((subscription) => subscription.unsubscribe());
    this._subscriptions.length = 0;
    this.userService.members.forEach(m => m.streamId = null);
    this.videoPlayerGrid.clearAllMediaStreams();
    this.speakerGrid.clearAllMediaStreams();
    this.speakerModel = null;
  }

  getAdminAvatarSymbols(): string {
    const adminName = this.userService.members.filter(m => m.userId === this.globals.adminUserId)[0].userName;

    this.adminAvatarBackground = StringUtils.getBackgroundColor(adminName);

    return StringUtils.getFirstSymbols(adminName);
  }

  getAdminAvatar(): string {
    return this.userService.members.filter(m => m.userId === this.globals.adminUserId)[0].userPictureUrl;
  }

  isAdminCameraActive(): boolean {
    return this.userRoomParamService.getUserParams(this.globals.adminUserId) &&
      !this.userRoomParamService.getUserParams(this.globals.adminUserId).cameraDisabled &&
      !this.userRoomParamService.getUserParams(this.globals.adminUserId).cameraNotAllowed;
  }
}
