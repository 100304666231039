import {Role} from "./role.model";

export class User {
  id: number;
  dateAdded: string;
  login: string;
  password: string;
  name: string;
  email: string;
  pictureUrl: string;
  isMuted: boolean = false;
  isCameraDisabled: boolean = false;
  globalRole: Role;
}
