import {AfterViewInit, Component, ElementRef, Input, ViewChild} from '@angular/core';
import {Content} from "../../../../model/content.model";
import {Room} from "../../../../model/room.model";
import {VideoPreviewBlockDirective} from "../../../../directives/video-preview-block.directive";
import {MediaStreamService} from "../../../../services/media-stream-service";
import {Globals} from "../../../globals";
import {VideoStreamService} from "../../../../services/video-stream.service";
import {StateService} from "../../../../services/state.service";
import {
  FromMainPlayerType,
  RecordPlayerSubscriptionsService
} from "../../../../services/subscriptions/record-player-subscriptions.service";

@Component({
  selector: '[app-video-player-record-block]',
  templateUrl: './video-player-record-block.component.html',
  styleUrls: ['./video-player-record-block.component.css']
})
export class VideoPlayerRecordBlockComponent implements AfterViewInit {

  @Input('contents') contents: Content[];
  @Input('room') room: Room;
  @ViewChild('savedVideo', { static: false }) savedVideo: ElementRef;
  @ViewChild('div_filling', { static: false }) div_filling: ElementRef;
  @ViewChild('div_circle', { static: false }) div_circle: ElementRef;
  @ViewChild('div_full', { static: false }) div_full: ElementRef;

  public currentTime: string = "00:00:00";
  public videoPreview: VideoPreviewBlockDirective;
  public played: boolean = false;

  constructor(
    public mediaStreamService: MediaStreamService,
    public globals: Globals,
    public videoStreamService: VideoStreamService,
    public subscriptionService: StateService,
    public plSubs: RecordPlayerSubscriptionsService
  ) {
  }

  ngOnInit(): void {

    this.plSubs.fromMainPlayerObs.subscribe((message) => {

      console.log("toControls message");

      switch (message.type) {
        case FromMainPlayerType.PROGRESS_UPDATED:
          let htmlVideoElement = message.value as HTMLVideoElement;

            let progressPercent = htmlVideoElement.currentTime / htmlVideoElement.duration;
            this.updateSlider(progressPercent);
            this.currentTime = this.timeStringFromSeconds(htmlVideoElement.currentTime);
            this.currentTime += " / ";
            this.currentTime += this.timeStringFromSeconds(htmlVideoElement.duration);
            // this.subscriptionService.subscriptions.sendAdminRecordTimeUpdateEvent(htmlVideoElement.currentTime)
          break;
        case FromMainPlayerType.PLAYED:
          this.played = true;
          break;
        case FromMainPlayerType.STOPPED:
          this.played = false;
          break;
      }

    });
  }

  ngAfterViewInit(): void {
    // this.plState.firstContentVideo.ontimeupdate(null);
  }

  private updateSlider(percent) {
    let strPercent = (percent * 100).toFixed(2);

    let div_filling = this.div_filling.nativeElement as HTMLDivElement;
    let div_circle = this.div_circle.nativeElement as HTMLDivElement;

    div_filling.style.width = "calc(" + strPercent + "% - 3px/2)";
    div_circle.style.left = "calc(" + strPercent + "% - 9px/2)";
  }

  private timeStringFromSeconds(seconds: number) {

    if (isNaN(seconds)) {
      return "00:00:00";
    }

    let hours = Math.floor(seconds / 3600);
    let minutes = Math.floor((seconds - (hours * 3600)) / 60);
    let curSeconds = seconds - (hours * 3600) - (minutes * 60);

    let hoursString = hours.toString();
    let minutesString = minutes.toString();
    let secondsString = curSeconds.toFixed(0).toString();
    if (hours < 10) {
      hoursString = "0" + hours.toString();
    }
    if (minutes < 10) {
      minutesString = "0" + minutes;
    }
    if (curSeconds < 10) {
      secondsString = "0" + secondsString;
    }

    return hoursString + ':' + minutesString + ':' + secondsString;
  }

  forwardVideo() {
    this.plSubs.sendForwardVideoFromControls(10);
  }

  backVideo() {
    this.plSubs.sendBackVideoFromControls(10);
  }

  startVideo() {
    this.plSubs.sendPlayFromControls()
  }

  stopVideo() {
    this.plSubs.sendStopFromControls()
  }

  onMouseClick($event: MouseEvent) {
    console.log("onMouseClick");
    let percent = $event.clientX / this.div_full.nativeElement.clientWidth;
    this.updateSlider(percent);
    this.plSubs.sendUpdateProgressFromControls(percent);
  }
}
