import {Header} from "./header-param";
import {WsEventEnum} from "../../enum/ws-event.enum";
import {BaseWsEvent} from "./base-ws.event";

export class IceCandidateEvent implements BaseWsEvent {
  readonly event: WsEventEnum = WsEventEnum.ICE_CANDIDATE;
  readonly header: Header;
  readonly body: IceCandidateBody;

  constructor(inputOrOutputId: string, ice: string) {
    this.header = new Header(inputOrOutputId);
    this.body = new IceCandidateBody(ice);
  }
}

export class IceCandidateBody {
  readonly ice: string;

  constructor(ice: string) {
    this.ice = ice;
  }
}
