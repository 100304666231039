import {Role} from "./role.model";
import {RoomUserMessage} from "./message/room-user.message";
import {asyncScheduler, Observable, scheduled, Subject} from "rxjs";

export class Member {
  private readonly _userId: number;
  private readonly _userName: string;
  private readonly _userPictureUrl: string;
  private _userRole: Role;
  private _online: boolean;
  private _readyForCall: boolean;
  private _invited: boolean;
  private _streamId: number;
  private _invitedSubject: Subject<boolean>;
  private _readyForCallSubject: Subject<boolean>;

  constructor(roomUserMessage?: RoomUserMessage, userId?: number, userName?: string) {
    this._userId = roomUserMessage ? roomUserMessage.userId : userId;
    this._userName = roomUserMessage ? roomUserMessage.userName : userName;
    this._userRole = roomUserMessage?.roomRole;
    this._userPictureUrl = roomUserMessage?.userPictureUrl;
    this._online = false;
    this._readyForCall = false;
    this._invited = false;

    this._invitedSubject = new Subject<boolean>();
    this._readyForCallSubject = new Subject<boolean>();
  }

  get onInvited(): Observable<boolean> {
    return scheduled(this._invitedSubject.asObservable(), asyncScheduler);
  }

  get onReadyForCall(): Observable<boolean> {
    return scheduled(this._readyForCallSubject.asObservable(), asyncScheduler);
  }

  get isInvited(): boolean {
    return this._invited;
  }

  get isOnline(): boolean {
    return this._online;
  }

  get isReadyForCall(): boolean {
    return this.isOnline && this._readyForCall;
  }

  set invited(invited: boolean) {
    this._invited = invited;
    this._invitedSubject.next(invited);
  }

  set online(online: boolean) {
    this._online = online;
  }

  set readyForCall(readyForCall: boolean) {
    this._readyForCall = readyForCall;
    this._readyForCallSubject.next(readyForCall);
  }

  get userId(): number {
    return this._userId;
  }

  get userName(): string {
    return this._userName;
  }

  get userRole(): Role {
    return this._userRole;
  }

  set userRole(userRole: Role) {
    this._userRole = userRole;
  }

  get userPictureUrl(): string {
    return this._userPictureUrl;
  }

  get streamId(): number {
    return this._streamId;
  }

  set streamId(value: number) {
    this._streamId = value;
  }

  toString(): string {
    return "{ _userId: " + this._userId +
      ", _userName: " + this._userName +
      ", _userName: " + this._userName +
      ", _userRole: " + this._userRole.role +
      ", _online: " + this._online +
      ", _readyForCall: " + this._readyForCall +
      ", _invited: " + this._invited + " } ";
  }
}
