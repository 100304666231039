import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {MainPageComponent} from './pages/main-page/main-page.component';
import {LoginPageComponent} from './pages/login-page/login-page.component';
import {CheckDevicesPageComponent} from './pages/check-devices-page/check-devices-page.component';
import {RoomPageComponent} from '../pages/room-page/room-page.component';
import {RoomLinkPageComponent} from './pages/room-link-page/room-link-page.component';
import {RoomAccessDeniedPageComponent} from './pages/room-access-denied-page/room-access-denied-page.component';
import {HallPageComponent} from '../pages/hall-page/hall-page.component';
import {CreateHallRoomPageComponent} from '../pages/hall-page/create-room-page/create-hall-room-page.component';
import {ErrorsPageComponent} from './pages/errors-page/errors-page.component';
import {RequestPasswordPageComponent} from './pages/request-password-page/request-password-page.component';
import {SetNewPasswordPageComponent} from './pages/set-new-password-page/set-new-password-page.component';
import {CreateNewRoomPageComponent} from './pages/create-new-room-hall-page/create-new-room-page.component';
import {CreateNewHallPageComponent} from './pages/create-new-room-hall-page/create-new-hall-page.component';
import {GuestJoinToRoomPageComponent} from './pages/guest-join-to-room-page/guest-join-to-room-page.component';
import {UserRole} from "../model/enum/user-role";
import {AuthGuard} from "./guards/auth.guard";
import {RoomPasswordPageComponent} from "./pages/room-password-page/room-password-page.component";

const routes: Routes = [
  {
    path: '',
    component: MainPageComponent,
    pathMatch: 'full',
    // canActivate:         permit all
    // data: {roles: []}    permit all
  },
  {
    path: 'error/:errorId',
    component: ErrorsPageComponent,
    pathMatch: 'full'
    // canActivate:         permit all
    // data: {roles: []}    permit all
  },
  {
    path: 'login',
    component: LoginPageComponent,
    pathMatch: 'full'
    // canActivate:         permit all
    // data: {roles: []}    permit all
  },
  {
    path: 'login/restore',
    component: RequestPasswordPageComponent,
    pathMatch: 'full'
    // canActivate:         permit all
    // data: {roles: []}    permit all
  },
  {
    path: 'login/restore/:token',
    component: SetNewPasswordPageComponent,
    pathMatch: 'full'
    // canActivate:         permit all
    // data: {roles: []}    permit all
  },
  {
    path: 'room/new',
    component: CreateNewRoomPageComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
    data: { roles: [UserRole.REGISTERED_USER] }
  },
  {
    path: 'hall/new',
    component: CreateNewHallPageComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
    data: { roles: [UserRole.REGISTERED_USER] }
  },
  {
    path: 'room/guest',
    component: GuestJoinToRoomPageComponent,
    pathMatch: 'full'
    // canActivate:         permit all
    // data: {roles: []}    permit all
  },
  {
    path: 'room/guest/:id',
    component: GuestJoinToRoomPageComponent,
    pathMatch: 'full'
    // canActivate:         permit all
    // data: {roles: []}    permit all
  },
  {
    path: 'room/id/:id/password',
    component: RoomPasswordPageComponent,
    pathMatch: 'full'
    // canActivate:         permit all
    // data: {roles: []}    permit all
  },
  {
    path: 'room/id/:id',
    component: RoomPageComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
    data: { roles: [UserRole.REGISTERED_USER, UserRole.GUEST_USER] }
  },
  {
    path: 'hall/id/:id',
    component: HallPageComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
    data: { roles: [UserRole.REGISTERED_USER] }
  },
  {
    path: 'hall/id/:id/new/room',
    component: CreateHallRoomPageComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
    data: { roles: [UserRole.REGISTERED_USER] }
  },
  {
    path: 'room/id/:id/check-devices',
    component: CheckDevicesPageComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
    data: { roles: [UserRole.REGISTERED_USER] }
  },
  {
    path: 'room/id/:id/link',
    component: RoomLinkPageComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
    data: { roles: [UserRole.REGISTERED_USER] }
  },
  {
    path: 'room/id/:id/edit',
    component: CreateNewRoomPageComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
    data: { roles: [UserRole.REGISTERED_USER] }
  },
  {
    path: 'room/id/:id/access-denied',
    component: RoomAccessDeniedPageComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
    data: { roles: [UserRole.REGISTERED_USER, UserRole.GUEST_USER] }
  },
  {
    path: '**',
    redirectTo: '/error/404',
    pathMatch: 'full'
    // canActivate:         permit all
    // data: {roles: []}    permit all
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthGuard]
})
export class AppRoutingModule {
}
