import {AfterViewInit, Component, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpErrorResponse} from '@angular/common/http';
import {RoomService} from '../../services/room.service';
import {Room} from '../../model/room.model';
import {Globals} from '../../app/globals';
import {UserService} from '../../services/user.service';
import {UtilsService} from '../../services/utils.service';
import {ChatService} from '../../services/chat.service';
import {QuestionService} from '../../services/question.service';
import {VideoStreamService} from '../../services/video-stream.service';
import {Subject, Subscription} from "rxjs";
import {StateService} from "../../services/state.service";
import {RoomParamMessage} from "../../model/message/room-param.message";
import {RoomParamService} from "../../services/room.param.service";
import {AttachmentService} from "../../services/attachment.service";
import {Content} from "../../model/content.model";
import {ChatAttachment} from "../../model/chatAttachment";
import {ChatMessage} from "../../model/message/chat.message";
import {ChatMessageTypeEnum} from "../../model/enum/chat-message-type.enum";
import {MediaServerService} from "../../services/media-server.service";
import {UserRoomParamService} from "../../services/user-room-param.service";
import {ChangeRoleEvent} from "../../model/event/change-role-event.model";
import {filter} from "rxjs/operators";
import {RoomUserCommandTypeEnum} from "../../model/enum/room-user-command-type.enum";
import {QuestionMessageStatusTypeEnum} from "../../model/enum/question-message-status-type.enum";
import {RoomType} from "../../model/enum/room-type";
import {NGXLogger} from "ngx-logger";
import {RoleTypeEnum} from "../../model/enum/role-type.enum";
import {Role} from "../../model/role.model";
import {StreamTypeEnum} from "../../model/enum/stream-type-enum";
import {AudioContextHolder} from "../../services/audio-context.holder";
import {RoomQuestionsTabComponent} from "../../app/components/room-components/room-questions-tab/room-questions-tab.component";

@Component({
  selector: 'room-page-component',
  templateUrl: './room-page.component.html',
  styleUrls: ['./room-page.component.css'],
  providers: [VideoStreamService, ChatService, QuestionService,
    AttachmentService, UserService, RoomService,
    RoomParamService, UserRoomParamService]
})
export class RoomPageComponent implements AfterViewInit, OnInit, OnDestroy {
  @ViewChild("roomQuestionsTab", {static: false}) roomQuestionsTab: RoomQuestionsTabComponent;
  public selectedTab: string;

  public markReadQuestionSubject: Subject<boolean>;
  public camWebStreamIdSubject: Subject<number>;

  roomId: string;
  room: Room;
  contents: Content[];
  attachments: ChatAttachment[];

  newMessagesCount: number = 0;
  newQuestionsCount: number = 0;
  newMembersCount: number = 0;
  newAttachmentsCount: number = 0;
  moderatorOnline: boolean;
  showMembersHint: boolean;

  private _subscriptions: Subscription[];

  constructor(public globals: Globals,
              public videoStreamService: VideoStreamService,
              public chatService: ChatService,
              public questionService: QuestionService,
              public attachmentService: AttachmentService,
              public userService: UserService,
              public roomService: RoomService,
              public subscriptionService: StateService,
              public roomParamService: RoomParamService,
              private route: ActivatedRoute,
              private router: Router,
              private mediaServerService: MediaServerService,
              private userRoomParamService: UserRoomParamService,
              public stateService: StateService,
              private logger: NGXLogger) {
    this.room = new Room();
    this.moderatorOnline = false;
    this.showMembersHint = false;
    this.stateService.isRoomSettingsOpened = false;

    this._subscriptions = [];
    this.initOnChangeRoleRequestSubscription();
    this.initOnInputStatusChangeSubscription();

    this._subscriptions.push(this.userService.onCurrentUserRoomRoleUpdate
      .subscribe((changeRoleEvent) => {
        if (changeRoleEvent.newRole.isDeleted) {
          this.router.navigate([`/room/id/${this.roomId}/access-denied`]);
        } else {
          setTimeout(() => {
            UtilsService.updateContextTriggerOnClick();
          }, 100);
        }
      }));

    this.changeTab();

    this.camWebStreamIdSubject = new Subject<number>();
    this.markReadQuestionSubject = new Subject<boolean>();

    this._subscriptions.push(this.roomParamService.onMessage
      .subscribe((roomParamMessage: RoomParamMessage) => {
        this.room.name = roomParamMessage.name;
        this.room.dateStart = roomParamMessage.dateStart;
        this.room.timeStart = roomParamMessage.timeStart;
      }));

    this._subscriptions.push(this.chatService.onMessage
      .pipe(filter((chatMessage) => chatMessage.messageType === ChatMessageTypeEnum.USER &&
        this.globals.user.id !== chatMessage.userId))
      .subscribe((chatMessage: ChatMessage) => {
        if (this.selectedTab !== 'chat' || !this.globals.showSidebar) {
          this.newMessagesCount++;
        }
        if (this.selectedTab !== 'attachments' || !this.globals.showSidebar && chatMessage.attachments?.length > 0) {
          this.newAttachmentsCount += chatMessage.attachments.length;
        }
      }));

    this._subscriptions.push(this.userService.onMessage.subscribe((roomUserMessage) => {
      if (roomUserMessage.commandType === RoomUserCommandTypeEnum.JOIN &&
        roomUserMessage.userId !== this.globals.user.id &&
        (this.selectedTab !== 'members' || !this.globals.showSidebar)) {
        this.newMembersCount++
      }
      const moderatorWasOnline = this.moderatorOnline;
      const questionsNumber = this.roomQuestionsTab.allQuestions
        .filter(q => (!this.moderatorOnline && q.statusType === QuestionMessageStatusTypeEnum.PENDING_APPROVAL)).length;

      this.moderatorOnline = this.userService.members.some(m => m.userRole.isModerator && m.isOnline);

      if (!moderatorWasOnline && this.moderatorOnline) {
        this.roomQuestionsTab.moderatorOnline = true;
        const newQuestionsNumber = this.roomQuestionsTab.allQuestions
          .filter(q => (!this.moderatorOnline && q.statusType === QuestionMessageStatusTypeEnum.PENDING_APPROVAL)).length;

        if (this.globals.userRoomRole.isAdmin) {
          this.newQuestionsCount -= questionsNumber - newQuestionsNumber;
        } else if (this.globals.userRoomRole.isModerator) {
          this.newQuestionsCount += questionsNumber - newQuestionsNumber;
        }

        this.newQuestionsCount = this.newQuestionsCount > 0 ? this.newQuestionsCount : 0;
      }
    }));

    this.contents = [];
  }

  public changeTab(tab?: string): void {
    if (!tab || ['chat', 'questions', 'members', 'attachments'].indexOf(tab) < 0) {
      tab = 'chat';
    }

    if (tab === 'questions' && this.room.type === RoomType.GROUP_CALL) {
      this.selectedTab = 'chat';
    }

    this.selectedTab = tab;

    this.updateCounts();
  }

  private updateCounts(): void {
    if (this.selectedTab === 'chat') {
      this.newMessagesCount = 0;
    } else if (this.selectedTab === 'questions') {
      this.newQuestionsCount = 0;
      this.markReadQuestionSubject.next(true);
    } else if (this.selectedTab === 'members') {
      this.newMembersCount = 0;
    } else if (this.selectedTab === 'attachments') {
      this.newAttachmentsCount = 0;
    }
  }

  public openTab(tab: string): void {
    this.globals.showSidebar = true;

    if (tab !== this.selectedTab) {
      this.router.navigate([window.location.pathname], {queryParams: {tab: tab}});
    } else {
      this.updateCounts();
    }
  }

  ngOnInit(): void {
    this._subscriptions.push(this.route.params.subscribe(params => {
      this.roomId = params['id'];
      if (!this.roomId) {
        this.router.navigate(['/']);
      } else {
        this.roomService.getByLink(this.roomId).subscribe((room: Room) => {
          this.room = room;
          this.globals.room = room;

          if (this.room.type == RoomType.CONFERENCE) {
            this._subscriptions.push(this.questionService.onMessage
              .subscribe((questionMessage) => {
                if (this.selectedTab !== 'questions' || !this.globals.showSidebar) {
                  if (((this.globals.userRoomRole.isModerator || (!this.moderatorOnline && this.globals.userRoomRole.isAdmin)) &&
                    questionMessage.statusType === QuestionMessageStatusTypeEnum.PENDING_APPROVAL) ||
                    (this.globals.userRoomRole.isAdmin &&
                      questionMessage.statusType === QuestionMessageStatusTypeEnum.APPROVED) ||
                    (this.globals.userRoomRole.isUser && questionMessage.userId === this.globals.user.id &&
                      questionMessage.statusType === QuestionMessageStatusTypeEnum.APPROVED)) {
                    this.newQuestionsCount++
                  }
                }
              }));
          }

          this.initOrderedStompConnections(this.room.id);
          this.videoStreamService.getContents(this.room.id)
            .subscribe((contents => {
              this.processNewContents(contents);
            }));

          let subs = this.videoStreamService.isTranslationStarted(this.room.id).subscribe(isTranslationStarted => {
            this.globals.isTranslationStarted = isTranslationStarted;
            subs.unsubscribe();
            subs = null;
          });
        }, (error: HttpErrorResponse) => {
          if (error.status === 401) {
            this.router.navigate([`/room/id/${this.roomId}/password`]);
          } else if (error.status === 403) {
            this.router.navigate([`/room/id/${this.roomId}/access-denied`]);
          } else {
            this.logger.error("Fetching room error", error);
            console.error(error);
          }
        });
      }
    }));

    this._subscriptions.push(this.route.queryParams.subscribe(params => {
      this.changeTab(params['tab']);
    }));
  }

  private initOnChangeRoleRequestSubscription(): void {
    this.subscriptionService.requests.onChangeRoleRequest
      .subscribe((changeRoleEvent) => {
        this.changeRole(changeRoleEvent);
      })
  }

  private initOnChangeAdminRoleModalMessage(): void {
    UtilsService.showFancyBox('#change-admin-role');

    let oldAdminUserId = this.globals.adminUserId;

    this.logger.debug("initOnChangeAdminRole currentAdminUserId: {}", oldAdminUserId)
    let onChangeAdminRole = this.userService.onRoomRoleUpdate
      .subscribe((changeRoleEvent) => {
        if (changeRoleEvent.userId === oldAdminUserId && changeRoleEvent.oldRole.isAdmin && !changeRoleEvent.newRole.isAdmin) {
          UtilsService.closeFancyBox();
          if (this.subscriptionService.isConnectedToStream && this.subscriptionService.userIsConnectedToStream) {
            this.subscriptionService.userIsInvitedToStream = true;
          }
          this.userService.sendReadyForCallMessage(oldAdminUserId, null, false);
          onChangeAdminRole.unsubscribe();
        }
      });
  }

  private createAutoChangeRoleToModerator(changeRoleEvent: ChangeRoleEvent): void {
    //remember old admin user id
    let oldAdminUserId = this.globals.adminUserId;

    //subscribe change role for new admin
    let setRoleToNewAdminSub = this.userService.onRoomRoleUpdate
      .pipe(filter((adminChangeRoleEvent) => adminChangeRoleEvent.userId === changeRoleEvent.userId))
      .subscribe((changeRoleEvent) => {
        if (changeRoleEvent.newRole.isAdmin) {
          this.userService.changeUserRole(oldAdminUserId, new Role(RoleTypeEnum.ROLE_MODERATOR));
          setRoleToNewAdminSub.unsubscribe();
        }
      });
  }

  private isAllowChangeRole(userId: number, callback: Subject<boolean>): void {
    //check if new admin user is connected to stream
    if (this.subscriptionService.isConnectedToStream && !this.mediaServerService.existsConnectedOutputModelByStreamUserId(userId)) {
      this.videoStreamService.sendInviteUserToStreamMessage(this.globals.currentStreamId, userId);
      let connected = this.subscriptionService.subscriptions
        .onConnectedToCreatedStream
        .pipe(filter((createdStream) => createdStream.userId === userId))
        .subscribe((createdStream) => {
          callback.next(createdStream.status);
          connected.unsubscribe();
        });
    } else {
      callback.next(true);
    }
  }

  private changeRole(changeRoleEvent: ChangeRoleEvent): void {
    if (changeRoleEvent.newRole.isAdmin) {
      this.initOnChangeAdminRoleModalMessage();
      this.createAutoChangeRoleToModerator(changeRoleEvent);

      let screenModels = this.mediaServerService.findOutputModelsByStreamType(StreamTypeEnum.SCREEN_INPUT);
      for (let screenModel of screenModels) {
        if (screenModel.streamUserId === changeRoleEvent.userId || screenModel.streamUserId === this.globals.adminUserId) {
          this.videoStreamService.sendStopScreenStreamMessage(screenModel.streamId, screenModel.streamUserId, "CHANGE ROLE");
          this.logger.debug("this.videoStreamService.sendStopScreenStreamMessage");
        }
      }

      let isAllowSubject = new Subject<boolean>();
      isAllowSubject
        .asObservable()
        .subscribe((isAllow) => {
          if (isAllow) {
            this.userService.changeUserRole(changeRoleEvent.userId, changeRoleEvent.newRole);
          } else {
            UtilsService.closeFancyBox();
          }
          isAllowSubject.complete();
        });

      this.isAllowChangeRole(changeRoleEvent.userId, isAllowSubject);

    } else {
      this.userService.changeUserRole(changeRoleEvent.userId, changeRoleEvent.newRole);
    }
  }

  private initOrderedStompConnections(roomId: number): void {
    //At first create all param subscription
    //1. roomParamService
    let roomParamServiceOnConnected = this.roomParamService.onConnected
      .subscribe(() => {
        this.userRoomParamService.createWsConnection(roomId);
        this.chatService.createWsConnection(roomId);
        roomParamServiceOnConnected.unsubscribe();
      })

    //2. userRoomParamService
    let userRoomParamServiceOnConnected = this.userRoomParamService.onConnected
      .subscribe(() => {
        this.userService.createWsConnection(roomId);
        userRoomParamServiceOnConnected.unsubscribe();
      })

    //3. userService
    let userServiceOnConnected = this.userService.onConnected
      .subscribe(() => {
        this.videoStreamService.createWsConnection(roomId);
        this.questionService.createWsConnection(roomId);
        this.attachmentService.getAttachmentsByRoomId(this.room.id).subscribe((attachments) => {
          attachments.forEach(value => {
            value.complete = true;
            value.progress = 100;
          });
          this.attachments = attachments;
        });
        userServiceOnConnected.unsubscribe();
      })

    //4. videoStreamService
    let videoStreamServiceOnConnected = this.videoStreamService.onConnected
      .subscribe(() => {
        this.mediaServerService.createConnectionToMediaServer(this.room.link);
        videoStreamServiceOnConnected.unsubscribe();
      });

    //5. chatService
    this.chatService.onMessage
      .subscribe((chatMessage: ChatMessage) => {
        if (chatMessage.messageType === ChatMessageTypeEnum.DELETE_ATTACHMENT) {
          this.attachments = this.attachments.filter(value => {
            return chatMessage.attachments.filter(value1 => value1.id === value.id).length === 0;
          });
        } else if (chatMessage.attachments) {
          chatMessage.attachments.forEach(value => {
            value.complete = true;
            value.progress = 100;
            this.attachments.push(value);
          });
        }
      });

    this.roomParamService.createWsConnection(roomId);
  }

  private initOnInputStatusChangeSubscription(): void {
    this._subscriptions.push(this.mediaServerService
      .wsOnInputStatusChange
      .subscribe((inputIdInfo) => {
        this.videoStreamService.sendStreamOnlineMessage(inputIdInfo, inputIdInfo.online);
        if (!inputIdInfo.online) {
          let model = this.mediaServerService.findInputModelsByInputId(inputIdInfo.inputId);
          if (model && model.streamContainer) {
            console.log("STOP STREAM");
            model.streamContainer.stopStreams();
          }
        }
      }));
  }

  ngAfterViewInit(): void {
    this._subscriptions.push(this.subscriptionService.subscriptions
      .onTranslationFinished
      .subscribe(() => {
        this.videoStreamService.getContents(this.room.id).subscribe((contents => {
          this.processNewContents(contents);
        }));
      }));
  }

  private processNewContents(contnets: Content[]) {
    this.contents = contnets;
    this.subscriptionService.translationIsFinished = contnets.length > 0;
  }

  @HostListener('window:beforeunload')
  beforeUnloadHandler() {
    this.logger.debug("Beforeunload RoomPageComponent");
    this.ngOnDestroy();
    AudioContextHolder.closeAudioContext();
  }

  @HostListener('document:click')
  hideMembersHint() {
    if (this.showMembersHint) {
      this.showMembersHint = false;
    }
  }

  ngOnDestroy() {
    // It must be called first because of io close command
    this.mediaServerService.beforeOnload()
      .forEach((wsRtcModel) => {
        if (wsRtcModel.isOutput) {
          this.videoStreamService.sendConnectedToStreamMessage(wsRtcModel, false);
        } else {
          this.videoStreamService.sendStreamOnlineMessage(wsRtcModel.inputIdInfo, false);
          wsRtcModel.streamContainer.stopStreams();
        }
      });

    this.videoStreamService.ngOnDestroy();
    this.chatService.ngOnDestroy();
    this.questionService.ngOnDestroy();
    this.userService.ngOnDestroy();
    this.roomParamService.ngOnDestroy();
    this.userRoomParamService.ngOnDestroy();
    this._subscriptions.forEach(s => s.unsubscribe());
  }

  openSettings(): void {
    this.stateService.isRoomSettingsOpened = true;
    this.stateService.isSettingsOpenedFromHeader = true;
  }

  get getLink(): string {
    if (this.room) {
      return this.roomService.getLink(this.room);
    }
  }
}
