import {Pipe, PipeTransform} from "@angular/core";
import {Room} from "../model/room.model";

@Pipe({
  name: 'roomNamePipe',
  pure: false
})
export class RoomNamePipe implements PipeTransform {
  transform(rooms: Room[], filter: string): Room[] {
    if (!rooms || !filter) {
      return rooms;
    }
    return rooms.filter(room => room.name.indexOf(filter) > -1)
  }

}
