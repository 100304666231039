import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-function-not-implemented-modal',
  templateUrl: './function-not-implemented-modal.component.html',
  styleUrls: ['./function-not-implemented-modal.component.css']
})
export class FunctionNotImplementedModalComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
