import {Injectable} from '@angular/core';
import {QuestionMessage} from '../model/message/question.message';
import {QuestionMessageStatusTypeEnum} from '../model/enum/question-message-status-type.enum';
import {BaseStompSupportedService} from "./base-stomp-supported.service";
import {NGXLogger} from "ngx-logger";

@Injectable({ providedIn: 'root' })
export class QuestionService extends BaseStompSupportedService<QuestionMessage> {
  protected readonly WS_PATH: string = "question";

  constructor(logger: NGXLogger) {
    super(logger, QuestionMessage);
  }

  changeStatus(question: QuestionMessage, statusType: QuestionMessageStatusTypeEnum): void {
    question.statusType = statusType;
    this.send(question);
  }
}
