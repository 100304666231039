import {StreamTypeEnum} from "./enum/stream-type-enum";
import * as uuid from 'uuid';

export class InputIdInfo {
  private readonly _streamType: StreamTypeEnum;
  private readonly _roomId: number;
  private readonly _streamUserId: number;
  private readonly _streamId: number;
  private _contentId: string;
  private readonly _inputId: string;
  private readonly _online: boolean;

  constructor(inputId: string, online?: boolean) {
    this._inputId = inputId;
    this._online = !!online;

    let splitted = inputId.split(":");
    let roomId = splitted[2];
    this._roomId = roomId ? parseInt(roomId) : null;

    let streamUserId = splitted[3];
    this._streamUserId = streamUserId ? parseInt(streamUserId) :null;

    let streamId = splitted[4];
    this._streamId = streamId ? parseInt(streamId) : null;

    let streamType = splitted[0];
    if (streamType === StreamTypeEnum.CAM_INPUT) {
      this._streamType = StreamTypeEnum.CAM_INPUT
    } else if (streamType === StreamTypeEnum.CAM_OUTPUT) {
      this._streamType = StreamTypeEnum.CAM_OUTPUT
    } else if (streamType === StreamTypeEnum.SCREEN_INPUT) {
      this._streamType = StreamTypeEnum.SCREEN_INPUT
    } else if (streamType === StreamTypeEnum.SCREEN_OUTPUT) {
      this._streamType = StreamTypeEnum.SCREEN_OUTPUT
    }
  }

  get inputId(): string {
    return this._inputId;
  }

  get online(): boolean {
    return this._online;
  }

  get streamType(): StreamTypeEnum {
    return this._streamType;
  }

  get roomId(): number {
    return this._roomId;
  }

  get streamUserId(): number {
    return this._streamUserId;
  }

  get streamId(): number {
    return this._streamId;
  }

  public isInputScreen(): boolean {
    return this._streamType === StreamTypeEnum.SCREEN_INPUT;
  }

  public isInputCam(): boolean {
    return this._streamType === StreamTypeEnum.CAM_INPUT;
  }

  get contentId(): string {
    return this._contentId;
  }

  set contentId(value: string) {
    this._contentId = value;
  }

  public generateOutputId(outputUserId: number): string {
    let outputStreamType = StreamTypeEnum.CAM_OUTPUT;
    if (this.streamType === StreamTypeEnum.SCREEN_INPUT) {
      outputStreamType = StreamTypeEnum.SCREEN_OUTPUT;
    }
    return outputStreamType + ":" + uuid.v4() + ":" + this._roomId + ":" + this._streamUserId + ":" + this._streamId + ":" + outputUserId;
  }
}
