import {StompWrapper} from "../../../wrappers/stomp.wrapper";
import {RoomUserMessage} from "../../../model/message/room-user.message";
import {Subject} from "rxjs";
import {Component, HostListener, Input, OnChanges, OnDestroy, SimpleChanges} from "@angular/core";
import {Hall} from "../../../model/hall.model";
import {UserService} from "../../../services/user.service";
import {Router} from "@angular/router";
import {Room} from "../../../model/room.model";

@Component({
  selector: '[hall-sidebar-block-component]',
  templateUrl: './hall-sidebar-block.component.html'
})
export class HallSidebarBlockComponent implements OnChanges, OnDestroy {
  private userStompClient: StompWrapper<RoomUserMessage>;
  public _isMemberSelected: boolean;
  public markReadQuestionSubject: Subject<boolean>;

  @Input('hallSubject')
  public hallSubject: Subject<Hall>;
  public hall: Hall;

  public room: Room = new Room();

  public hasNewQuestions: boolean;


  constructor(private router: Router,
              public userService: UserService) {

    this._isMemberSelected = true;
    this.markReadQuestionSubject = new Subject<boolean>();
    this.hasNewQuestions = false;

    this.userService.onCurrentUserRoomRoleUpdate
      .subscribe((changeRoleEvent) => {
        if (changeRoleEvent.newRole.isDeleted) {
          this.router.navigate([`/hall/id/${this.hall.id}/access-denied`]);
        }
      });
  }

  public changeNotificationForQuestions(status: boolean): void {
    this.hasNewQuestions = status;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hallSubject) {
      this.hallSubject = changes.hallSubject.currentValue;

      this.hallSubject
        .asObservable()
        .subscribe((hall) => {
          this.hall = hall;
          this.initStompClients(hall);
        });
    }
  }

  private initStompClients(hall: Hall): void {
    this.userService.createWsConnection(hall.id)
  }

  @HostListener('window:beforeunload', ['$event'])
  public beforeUnloadHander() {
    // this.videoStreamStompClient.ngOnDestroy();
    // this.chatStompClient.ngOnDestroy();
    // this.questionStompClient.ngOnDestroy();
    this.userService.ngOnDestroy();
    this.userStompClient.ngOnDestroy();
  }

  public ngOnDestroy() {
    // this.videoStreamStompClient.ngOnDestroy();
    // this.chatStompClient.ngOnDestroy();
    // this.questionStompClient.ngOnDestroy();
    this.userService.ngOnDestroy();
    this.userStompClient.ngOnDestroy();
  }
}
