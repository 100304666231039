import {Role} from "../role.model";

export class ChangeRoleEvent {
  private readonly _userId: number;
  private readonly _newRole: Role;
  private readonly _oldRole: Role;


  constructor(userId: number, newRole: Role, oldRole: Role) {
    this._userId = userId;
    this._newRole = newRole;
    this._oldRole = oldRole;
  }

  get userId(): number {
    return this._userId;
  }


  get newRole(): Role {
    return this._newRole;
  }

  get oldRole(): Role {
    return this._oldRole;
  }
}
