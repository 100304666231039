import {Injectable, OnDestroy} from '@angular/core';
import {Observable, ReplaySubject, Subject} from 'rxjs';
import {RoomUserMessage} from '../model/message/room-user.message';
import {Globals} from '../app/globals';
import {RoomUserCommandTypeEnum} from "../model/enum/room-user-command-type.enum";
import {filter} from "rxjs/operators";
import {Role} from "../model/role.model";
import {RoomUserMessageCreationUtils} from "../utils/room-user-message-creation.utils";
import {RoleTypeEnum} from "../model/enum/role-type.enum";
import {ChangeRoleEvent} from "../model/event/change-role-event.model";
import {BaseStompSupportedService} from "./base-stomp-supported.service";
import {Member} from "../model/member.model";
import {ReadyForCallEvent} from "../model/event/ready-for-call.event";
import {UserOnlineEvent} from "../model/event/user-online.event";
import {DeviceDetectorService} from "ngx-device-detector";
import {NGXLogger} from "ngx-logger";


@Injectable({ providedIn: 'root' })
export class UserService extends BaseStompSupportedService<RoomUserMessage> implements OnDestroy {
  protected readonly WS_PATH: string = "room-user";

  private _members: Member[];
  private readonly _roomRoleUpdateSubject: ReplaySubject<ChangeRoleEvent>
  private readonly _userReadyForCallSubject: Subject<ReadyForCallEvent>
  private readonly _userOnlineSubject: Subject<UserOnlineEvent>
  private readonly _membersUpdatedSubject: Subject<void>;
  private readonly _roomUserMessageCreationUtils: RoomUserMessageCreationUtils;

  private _isSentBrowserInfo: boolean;

  constructor(private globals: Globals,
              private deviceDetectorService: DeviceDetectorService,
              logger: NGXLogger) {
    super(logger, RoomUserMessage);

    this._members = [];
    this._roomRoleUpdateSubject = new ReplaySubject<ChangeRoleEvent>(2);
    this._membersUpdatedSubject = new ReplaySubject<void>(1);
    this._userReadyForCallSubject = new Subject<ReadyForCallEvent>();
    this._userOnlineSubject = new Subject<UserOnlineEvent>();

    this._isSentBrowserInfo = false;

    this._roomUserMessageCreationUtils = new RoomUserMessageCreationUtils(globals);

    this.initOnUserReadyForCallSubscription();
    this.initOnUserOnlineSubscription();
    this.initOnMembersSubscription();
    this.initOnJoinMessageSubscription();
    this.initOnChangeRoleMessageSubscription();
    this.initOnConnectedSubscription();
    this.logSubjects("UserService");
  }

  get members(): Member[] {
    return this._members;
  }

  get onUserReadyForCall(): Observable<ReadyForCallEvent> {
    return this._userReadyForCallSubject.asObservable();
  }

  get onUserOnline(): Observable<UserOnlineEvent> {
    return this._userOnlineSubject.asObservable();
  }

  get onCurrentUserRoomRoleUpdate(): Observable<ChangeRoleEvent> {
    return this._roomRoleUpdateSubject
      .pipe(filter((roomUserMessage) => this.globals.user.id === roomUserMessage.userId));
  }

  get onRoomRoleUpdate(): Observable<ChangeRoleEvent> {
    return this._roomRoleUpdateSubject.asObservable();
  }

  get onMembersUpdated(): Observable<void> {
    return this._membersUpdatedSubject.asObservable();
  }

  sendMembersUpdated(): void {
    this._membersUpdatedSubject.next();
  }

  public userReadyForCall(userId: number) {
    return this.members.filter(m => m.userId === userId)[0]?.isReadyForCall;
  }

  sendDisconnect(): void {
    this.sendLeaveMessage("UserModel service sendDisconnect");
  }

  sendPrivateMessageControlIsVisible(userId: number): boolean {
    if (userId === this.globals.user.id) {
      return false;
    }
    return this.globals.userRoomRole.isUser;
  }

  public sendJoinMessage(reason: string): void {
    let message = this._roomUserMessageCreationUtils.createJoinMessage(reason);
    this.send(message);
  }

  private sendBrowserInfoMessage(deviceInfo: string): void {
    let message = this._roomUserMessageCreationUtils.createBrowserInfoMessage(deviceInfo);
    this.send(message);
  }

  private sendLeaveMessage(reason: string): void {
    let message = this._roomUserMessageCreationUtils.createLeaveMessage(reason);
    this.send(message);
  }

  private sendOnlineMessage(status: boolean): void {
    let message = this._roomUserMessageCreationUtils.createOnlineMessage(status);
    this.send(message);
  }

  public sendChangeRoleMessage(userId: number, newRole: RoleTypeEnum, reason: string): void {
    let message = this._roomUserMessageCreationUtils.createChangeRoleMessage(userId, newRole, reason);
    this.send(message);
  }

  public sendReadyForCallMessage(userId: number, streamId: number, status: boolean): void {
    let message = this._roomUserMessageCreationUtils.createReadyForCallMessage(userId, streamId, status);
    this.send(message);
  }

  public changeUserRole(userId: number, newRole: Role): void {
    this.sendChangeRoleMessage(userId, newRole.role, "Change user role manually by speaker to " + userId);
  }

  private initOnConnectedSubscription(): void {
    this.onConnected.subscribe(() => {
      this.sendJoinMessage("userStompClient.isConnected, sent JOIN");
    });
  }

  private initOnChangeRoleMessageSubscription(): void {
    this.onMessage
      .pipe(filter((roomUserMessage) => roomUserMessage.commandType === RoomUserCommandTypeEnum.CHANGE_ROLE))
      .subscribe((roomUserMessage) => {
        this.logger.debug("onMessage CHANGE_ROLE roomUserMessage: {}", roomUserMessage);
        for (let member of this._members) {
          if (member.userId === roomUserMessage.userId) {
            member.userRole = roomUserMessage.roomRole;
          }
        }
        if (roomUserMessage.userId === this.globals.user.id) {
          this.globals.userRoomRole = roomUserMessage.roomRole;
        }

        if (roomUserMessage.roomRole.isAdmin) {
          this.globals.adminUserId = roomUserMessage.userId
        }

        if (roomUserMessage.roomRole.isModerator) {
          this.globals.hasModeratorInRoom = true;
        } else {
          let hasModerator = false;
          for (let member of this._members) {
            if (member.userRole.isModerator) {
              hasModerator = true;
              break;
            }
          }

          this.globals.hasModeratorInRoom = hasModerator;
        }

        let changeRoleEvent = new ChangeRoleEvent(roomUserMessage.userId, roomUserMessage.roomRole, new Role(roomUserMessage.oldRole));
        this._roomRoleUpdateSubject
          .next(changeRoleEvent);
        this.logger.debug("this._roomRoleUpdateSubject.next({})", changeRoleEvent);
        this._membersUpdatedSubject.next();
      })
  }

  private initOnMembersSubscription(): void {
    this.onMessages.subscribe((roomUserMessages) => {
      this._members = roomUserMessages.map((roomUserMessage) => {

        if (roomUserMessage.roomRole.isAdmin) {
          this.globals.adminUserId = roomUserMessage.userId;
          this.logger.debug("SET ROOM SPEAKER: {}", this.globals.adminUserId);
        }

        if (roomUserMessage.roomRole.isModerator) {
          this.globals.hasModeratorInRoom = true;
          this.logger.debug("SET ROOM HAS MODERATOR: {}", this.globals.hasModeratorInRoom);
        }

        const newMember = new Member(roomUserMessage);

        if (this._members && this._members.length > 0) {
          const member = this._members.filter(m => m.userId === roomUserMessage.userId);

          if (member.length > 0) {
            this.logger.debug('UserService: Setting streamId (' + member[0].streamId + ') for user ' + newMember.userId);
            newMember.streamId = member[0].streamId;
          }
        }

        return newMember;
      });

      this._membersUpdatedSubject.next();
      this.logger.debug("initOnMembersSubscription: {}", this._members.toString());
    });

    this._membersUpdatedSubject.next();
  }

  private initOnUserOnlineSubscription(): void {
    this.onMessage.pipe(filter((roomUserMessage) =>
        roomUserMessage.commandType === RoomUserCommandTypeEnum.ONLINE || roomUserMessage.commandType === RoomUserCommandTypeEnum.LEAVE))
      .subscribe((roomUserMessage) => {
        let online = roomUserMessage.commandType === RoomUserCommandTypeEnum.ONLINE ? roomUserMessage.statusParam : !roomUserMessage.statusParam;
        this._userOnlineSubject.next(new UserOnlineEvent(roomUserMessage.userId, online))
        for (let member of this._members) {
          if (member.userId === roomUserMessage.userId) {
            member.online = !!roomUserMessage.statusParam;
          }
        }
      });
  }

  private initOnUserReadyForCallSubscription(): void {
    this.onMessage
      .pipe(filter((roomUserMessage) => roomUserMessage.commandType === RoomUserCommandTypeEnum.READY_FOR_CALL))
      .subscribe((roomUserMessage) => {
        this.logger.debug("onMessage READY_FOR_CALL roomUserMessage : {}", roomUserMessage);
        for (let member of this._members) {
          if (member.userId === roomUserMessage.userId) {
            member.readyForCall = roomUserMessage.statusParam;
            if (!member.isReadyForCall) {
              member.invited = false;
            }
          }
        }
      });
  }

  private initOnJoinMessageSubscription(): void {
    this.onMessage
      .pipe(filter((roomUserMessage) => roomUserMessage.commandType === RoomUserCommandTypeEnum.JOIN))
      .subscribe((roomUserMessage) => {
        if (roomUserMessage.userId === this.globals.user.id) {
          this.globals.userRoomRole = roomUserMessage.roomRole;
          this._membersUpdatedSubject.next();
        }

        this.sendOnlineMessage(true);

        if (!this._isSentBrowserInfo) {
          let deviceInfo = this.deviceDetectorService.getDeviceInfo();
          this.globals.browser = deviceInfo.browser;
          this.logger.debug("BROWSER: {}", this.globals.browser);
          this.sendBrowserInfoMessage(JSON.stringify(deviceInfo));
          this._isSentBrowserInfo = true;
        }

        let found = false;
        for (let i = 0; i < this._members.length; i++) {
          if (this._members[i].userId === roomUserMessage.userId) {
            found = true;
            break;
          }
        }
        if (!found) {
          this.members.push(new Member(roomUserMessage));
          this._membersUpdatedSubject.next();
          this.logger.debug("initOnJoinMessageSubscription: {}", this._members.toString());
        }

      });
  }

  ngOnDestroy(): void {
    this.logger.debug("User service NG ON DESTROY");
    this.sendLeaveMessage("User service ngOnDestroy");

    super.ngOnDestroy();
    this._roomRoleUpdateSubject.complete();
    this._membersUpdatedSubject.complete();
  }

  logSubjects(serviceName: string): void {
    super.logSubjects(serviceName);
    this._membersUpdatedSubject.asObservable().subscribe(value => this.logger.debug("_membersUpdatedSubject: {}", value));
    this._roomRoleUpdateSubject.asObservable().subscribe(value => this.logger.debug("_onUserRoomRoleUpdateSubject: {}", value));
  }


}
