export class Language {
  code: string;
  title: string;
  locale: any;

  constructor(code: string, title: string, locale: any) {
    this.code = code;
    this.title = title;
    this.locale = locale;
  }
}
