import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {WsRtcModel} from "../../../../model/ws-rtc.model";
import {UserService} from "../../../../services/user.service";
import {Subscription} from "rxjs";
import {Room} from "../../../../model/room.model";
import {UserRoomParamService} from "../../../../services/user-room-param.service";

declare const $: any;

@Component({
  selector: '[app-video-player-grid]',
  templateUrl: './video-player-grid.component.html',
  styleUrls: ['./video-player-grid.component.css']
})
export class VideoPlayerGridComponent implements OnInit, OnDestroy {
  @ViewChild('gridContainer', { static: false }) gridContainer: ElementRef;

  @Input() room: Room;
  @Input() highlightSpeaking: boolean;
  @Output() changed = new EventEmitter<void>();

  streams: WsRtcModel[] = [];
  subscriptions: Subscription[] = [];
  speaking: boolean[] = [];

  recalculateGridTimeout: any;

  constructor(public userRoomParamService: UserRoomParamService,
              public userService: UserService) {
  }

  ngOnInit(): void {
    this.changed.emit();
  }

  ngOnDestroy(): void {
    this.clearAllMediaStreams();
    this.subscriptions.forEach(value => value.unsubscribe());
    this.changed.emit();
  }

  streamTrackBy(i: number, stream: WsRtcModel) {
    return stream?.id;
  }

  addMediaStream(stream: WsRtcModel): void {
    if (this.streams.filter(s => stream.id === s.id).length === 0) {
      this.streams.push(stream);
    }
  }

  setMediaStreams(streams: WsRtcModel[]): void {
    this.streams = streams;
  }

  removeMediaStream(stream: WsRtcModel): void {
    this.streams = this.streams.filter(s => stream.id !== s.id);
  }

  countMediaStreams(): number {
    return this.streams.length;
  }

  clearAllMediaStreams(): void {
    this.streams = [];
  }

  getName(userId: number): string {
    let member = this.userService.members?.find((member) => member.userId === userId);
    return member ? member.userName : "UNKNOWN";
  }

  getPicture(userId: number): string {
    let member = this.userService.members?.find((member) => member.userId === userId);
    return member?.userPictureUrl;
  }

  isMuted(userId): boolean {
    return this.userRoomParamService.getUserParams(userId)?.muted;
  }

  isCameraDisabled(userId): boolean {
    return this.userRoomParamService.getUserParams(userId)?.cameraDisabled
      || this.userRoomParamService.getUserParams(userId)?.cameraNotAllowed;
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.resizeGrid();
  }

  recalculate(): void {
    this.resizeGrid();
    this.changed.emit();
  }

  resizeGrid(): void {
    if (this.recalculateGridTimeout) {
      clearTimeout(this.recalculateGridTimeout);
      this.recalculateGridTimeout = null;
    }

    this.recalculateGridTimeout = setTimeout(() => {
      if (this.gridContainer?.nativeElement) {
        this.recalculateGrid($(this.gridContainer.nativeElement));
      }
    }, 50);
  }

  private recalculateGrid(gridContainer): void {
    // Andrey's grid script:
    const calcBlocksSize = (gridContainer) => {
      const { vpWidth, vpHeight, vpArea } = getViewportSize(gridContainer);
      const aspectRatio = 16 / 9;
      const { blocksQuantity } = getBlocksQuantity(gridContainer);
      const isWide = vpWidth / vpHeight > 3;
      const blockGap = isWide ? 6 : 14;

      // console.log('vpWidth', vpWidth, '||| vpHeight', vpHeight, '||| vpArea', vpArea);

      const maxBlockSizeHeight = Math.sqrt(vpArea / blocksQuantity / aspectRatio);
      const maxBlockSizeWidth = maxBlockSizeHeight * aspectRatio;

      // console.log('maxBlockSizeWidth', maxBlockSizeWidth, '||| maxBlockSizeHeight', maxBlockSizeHeight);

      const maxHorizontal = vpWidth / maxBlockSizeWidth;
      const cols = Math.round(maxHorizontal) || 1;
      const rows = Math.ceil(blocksQuantity / cols) || 1;

      // console.log('blocksQuantity', blocksQuantity, '||| maxHorizontal', maxHorizontal);
      // console.log('rows', rows, '||| cols', cols);

      let blockWidth;
      let blockHeight;
      let checkIndex = 0;

      blockWidth = vpWidth / cols - blockGap;
      blockHeight = blockWidth / aspectRatio;

      while(!((rows * (blockHeight + blockGap)) < vpHeight)) {
        checkIndex++;
        blockWidth = blockWidth - checkIndex;
        blockHeight = blockWidth / aspectRatio;
      }

      // console.log('blockWidth', blockWidth, '||| blockHeight', blockHeight);

      return { blockWidth, blockHeight, blockGap };
    };

    const setBlockSize = (gridContainer) => {
      const { blockWidth, blockHeight, blockGap } = calcBlocksSize(gridContainer);

      gridContainer.find('.grid__item').each(function() {
        $(this).css({
          'width': blockWidth,
          'height': blockHeight,
          'margin': blockGap / 2
        });
      });
    };

    const getViewportSize = (gridContainer) => {
      const vpWidth = gridContainer.width();
      const vpHeight = gridContainer.height();
      const vpArea = vpWidth * vpHeight;

      return { vpWidth, vpHeight, vpArea};
    };

    const getBlocksQuantity = (gridContainer) => {
      const blocksQuantity = gridContainer.children().length;

      return { blocksQuantity };
    };
    // End of Andrey's grid script:

    setBlockSize(gridContainer);
  }
}
