import {Component, Input} from "@angular/core";
import {Room} from "../../../model/room.model";
import {HallRoomListEmptyBlockComponent} from "./hall-room-list-empty-block.component";
import {Router} from "@angular/router";
import {HallService} from "../../../services/hall.service";

@Component({
  selector: '[hall-room-list-block-component]',
  templateUrl: './hall-room-list-block.component.html'
})
export class HallRoomListBlockComponent extends HallRoomListEmptyBlockComponent {
  @Input("rooms")
  public rooms: Room[];
  public filter: string;
  public isOpenedMenu: boolean;


  constructor(router: Router,
              private hallService: HallService) {
    super(router);
    this.isOpenedMenu = false;
  }

  public editRoom(room: Room): void {
    this.hallService.removeHallRoom(room)
      .subscribe((hall) => {
        this.rooms = hall.rooms;
      })
  }

  public removeRoom($event, room: Room): void {
    this.hallService.removeHallRoom(room)
      .subscribe((hall) => {
        this.rooms = hall.rooms;
      });
    $event.preventDefault();
  }


  public showMenu(): void {
    this.isOpenedMenu = true;
  }

}
