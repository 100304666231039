import {Room} from "./room.model";

export class Hall {
  id: number;
  name: string;
  dateCreated: string;
  link: string;
  shortLink: string;
  rooms: Room[];

  static clone(source: Hall): Hall {
    const hall = new Hall();
    hall.id = source.id;
    hall.name = source.name;
    hall.link = source.link;
    hall.shortLink = source.shortLink;
    return hall;
  }
}
