import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {RoomService} from '../../../services/room.service';
import {UtilsService} from "../../../services/utils.service";
import {Subscription} from "rxjs";
import {Room} from "../../../model/room.model";
import {Globals} from "../../globals";

@Component({
  selector: 'room-link-page-component',
  templateUrl: './room-link-page.component.html'
})
export class RoomLinkPageComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('nextBtn', { static: false }) nextBtn: ElementRef;

  linkCopied: boolean;
  roomId: string;
  room: Room;

  private paramSubscription: Subscription;

  constructor(private roomService: RoomService,
              private route: ActivatedRoute,
              private router: Router,
              private globals: Globals) {
  }

  ngOnInit(): void {
    this.paramSubscription = this.route.params.subscribe(params => {
      this.roomId = params['id'];
      if (this.roomId) {
        this.roomService.getByLink(this.roomId).subscribe(room => {
            this.room = room;
            this.globals.room = room;
          },
          () => this.room = new Room(this.roomId));
      } else {
        this.router.navigate(['/']);
      }
    });
  }

  ngAfterViewInit(): void {
    this.nextBtn.nativeElement.focus();
  }

  ngOnDestroy(): void {
    this.paramSubscription.unsubscribe();
  }

  get getLink(): string {
    if (this.room) {
      return this.roomService.getLink(this.room);
    }
  }

  goToNextStep(): void {
    this.router.navigate([`/room/id/${this.roomId}`]);
  }

  goToCheckDevicesPage(): void {
    this.router.navigate([`/room/id/${this.roomId}/check-devices`]);
  }

  copyLink(): void {
    if (this.room.password) {
      UtilsService.copyToClipboard('ссылка: ' + this.getLink + '\n' + 'пароль: ' + this.room.password);
    } else {
      UtilsService.copyToClipboard(this.getLink);
    }
    this.linkCopied = true;
  }
}
