import {StompWrapper} from "../wrappers/stomp.wrapper";
import {StompConfigurationBuilder} from "../utils/stomp-configuration.builder";
import {Observable, Subject} from "rxjs";
import {BaseMessage} from "../model/message/base.message";
import {OnDestroy} from "@angular/core";
import {NGXLogger} from "ngx-logger";

export class BaseStompSupportedService<T extends BaseMessage> implements OnDestroy {
  protected readonly WS_PATH: string;
  private readonly _messagesSubject: Subject<T[]>;
  private readonly _messageSubject: Subject<T>;
  private readonly _connectedSubject: Subject<void>;
  private readonly _senderSubject: Subject<T>;

  private readonly _tClass: new () => T;
  private _stompClient: StompWrapper<T>;

  constructor(public logger: NGXLogger,
              tClass: { new(): T }) {
    this._tClass = tClass;

    this._messageSubject = new Subject<T>();
    this._senderSubject = new Subject<T>();
    this._messagesSubject = new Subject<T[]>();
    this._connectedSubject = new Subject<void>();
  }

  public createWsConnection(roomId: number): Observable<void> {
    if (this._stompClient) {
      if (!this._stompClient.isConnected) {
        this._stompClient.reconnect();
      }
    } else {
      let userStompConfig = new StompConfigurationBuilder<T>()
        .roomId(roomId)
        .tClass(this._tClass)
        .wsPath(this.WS_PATH)
        .messageSubject(this._messageSubject)
        .messagesSubject(this._messagesSubject)
        .senderSubject(this._senderSubject)
        .connectedSubject(this._connectedSubject)

      this._stompClient = new StompWrapper<T>(userStompConfig.build(), this.logger);
    }

    return this.onConnected;
  }


  get onConnected(): Observable<void> {
    return this._connectedSubject;
  }

  get onMessages(): Observable<T[]> {
    return this._messagesSubject;
  }

  get onMessage(): Observable<T> {
    return this._messageSubject;
  }

  public send(message: T): void {
    this._senderSubject.next(message);
  }

  public reconnect(): void {
    if (this._stompClient) {
      this._stompClient.reconnect();
    }
  }

  ngOnDestroy(): void {
    this.logger.debug("BaseStompSupportedService ngOnDestroy!!!!");
    if (this._stompClient) {
      this._stompClient.ngOnDestroy();
    }
    this._messageSubject.complete();
    this._messagesSubject.complete();
    this._senderSubject.complete();
  }

  logSubjects(serviceName: string): void {
    this._messageSubject.asObservable().subscribe(value => this.logger.debug(serviceName + " _messageSubject: {}", value));
    this._senderSubject.asObservable().subscribe(value => this.logger.debug(serviceName + " _senderSubject: {}", value));
    this._messagesSubject.asObservable().subscribe(value => this.logger.debug(serviceName + " _messagesSubject: {}", value));
    this._connectedSubject.asObservable().subscribe(value => this.logger.debug(serviceName + " _connectedSubject: {}", value));
  }


}
