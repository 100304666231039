import {QuestionMessageStatusTypeEnum} from '../enum/question-message-status-type.enum';
import {BaseMessage} from "./base.message";

export class QuestionMessage extends BaseMessage {
  id: number;
  dateAdded: string;
  question: string;
  userName: string;
  userPicture: string;
  userId: number;
  roomId: number;
  statusType: QuestionMessageStatusTypeEnum;
  isNewQuestion: boolean;
}
