import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ChatMessage} from "../../../../model/message/chat.message";
import {Globals} from "../../../globals";
import {ChatService} from "../../../../services/chat.service";
import {Room} from "../../../../model/room.model";
import {ChatMessageTypeEnum} from "../../../../model/enum/chat-message-type.enum";
import {UtilsService} from "../../../../services/utils.service";
import {ChatAttachment} from "../../../../model/chatAttachment";
import {NotificationsService} from "../../../../services/notifications.service";
import {RoomType} from "../../../../model/enum/room-type";
import {RoomChatControlsComponent} from "../room-chat-controls/room-chat-controls.component";

@Component({
  selector: '[app-room-chat-tab]',
  templateUrl: './room-chat-tab.component.html',
  styleUrls: ['./room-chat-tab.component.css']
})
export class RoomChatTabComponent implements OnInit {
  @ViewChild("chatControls", {static: true}) chatControls: RoomChatControlsComponent;

  @Input() room: Room;
  @Input() attachments: ChatAttachment[];

  messages: ChatMessage[];
  newMessagesCount: number;

  highlight: boolean;

  constructor(public globals: Globals,
              public chatService: ChatService,
              public notificationsService: NotificationsService) {
    this.messages = [];
    this.newMessagesCount = 0;

    this.chatService.onMessage
      .subscribe((chatMessage: ChatMessage) => {
        if (!this.messages) {
          this.messages = [];
        }

        if (chatMessage.messageType === ChatMessageTypeEnum.DELETE_ATTACHMENT) {
          let deletedAttachmentsIds = chatMessage.attachments.map(a => a.id);
          this.messages.forEach(m =>
            m.attachments.filter(a =>
              deletedAttachmentsIds.indexOf(a.id) >= 0).forEach(a => a.deleted = true));
        } else {
          // @ts-ignore
          this.messages.push(chatMessage);
          if (this.globals.user.id !== chatMessage.userId) {
            this.newMessagesCount++;
          }
          if ((globals.userRoomRole.isAdmin || globals.userRoomRole.isModerator || this.room.type === RoomType.GROUP_CALL) &&
            this.newMessagesCount !== 0 && this.newMessagesCount % 5 === 0 && this.globals.user.id !== chatMessage.userId) {
            notificationsService.sendNew5Messages(this.room?.name);
          }
        }
        this.scrollChatToBottom();
        setTimeout(() => {
          UtilsService.updateContextTriggerOnClick();
        }, 100);
      });
    this.chatService.onMessages
      .subscribe((next: ChatMessage[]) => {
        // @ts-ignore
        this.messages = this.messages.concat(next);
        this.messages.sort((a, b) =>
          a.dateAdded > b.dateAdded ? 1 : a.dateAdded === b.dateAdded ? 0 : -1);
        this.scrollChatToBottom();
        setTimeout(() => {
          UtilsService.updateContextTriggerOnClick();
        }, 100);
      });
  }

  ngOnInit(): void {
  }

  scrollChatToBottom(): void {
    setTimeout(() => {
      const chatList = document.querySelector('.chat__list');
      if (chatList) {
        chatList.scrollTop = chatList.scrollHeight;
      }
    }, 100);
  }


  onDragEnter(event: DragEvent): void {
    event.stopPropagation();
    event.preventDefault();
    this.highlight = true;
  }

  onDragOver(event: DragEvent): void {
    event.stopPropagation();
    event.preventDefault();
    this.highlight = true;
  }

  onDragLeave(event: DragEvent): void {
    event.stopPropagation();
    event.preventDefault();
    this.highlight = false;
  }

  onDrop(event: DragEvent): void {
    event.stopPropagation();
    event.preventDefault();
    this.highlight = false;

    this.chatControls.uploadFiles(event.dataTransfer.files);
  }
}
