import {Component} from "@angular/core";
import {VideoPlayerBlockComponent} from "./video-player-block.component";
import {Globals} from "../../../globals";
import {UserService} from "../../../../services/user.service";
import {MediaServerService} from "../../../../services/media-server.service";
import {VideoStreamService} from '../../../../services/video-stream.service';
import {VideoStreamCommandTypeEnum} from '../../../../model/enum/video-stream-command-type.enum';
import {MediaStreamService} from "../../../../services/media-stream-service";
import {StateService} from "../../../../services/state.service";
import {MediaDevicesService} from "../../../../services/media-devices.service";
import {VideoStreamMessage} from "../../../../model/message/video-stream.message";
import {StreamTypeEnum} from "../../../../model/enum/stream-type-enum";
import {UserRoomParamService} from "../../../../services/user-room-param.service";
import {NotificationsService} from "../../../../services/notifications.service";
import {NGXLogger} from "ngx-logger";

@Component({
  selector: '[app-video-player-admin-block]',
  templateUrl: './video-player-block.component.html',
  styleUrls: ['./video-player-block.component.css']
})
export class VideoPlayerAdminBlockComponent extends VideoPlayerBlockComponent {

  constructor(globals: Globals,
              userService: UserService,
              mediaServerService: MediaServerService,
              mediaStreamService: MediaStreamService,
              videoStreamService: VideoStreamService,
              subscriptionService: StateService,
              mediaDevicesService: MediaDevicesService,
              userRoomParamService: UserRoomParamService,
              notificationsService: NotificationsService,
              logger: NGXLogger) {

    super(globals, mediaServerService, mediaStreamService, videoStreamService, subscriptionService, userService,
      mediaDevicesService, userRoomParamService, notificationsService, logger, 'admin');
  }

  protected async videoStreamMessageHandler(videoStreamMessage: VideoStreamMessage): Promise<void> {
    // console.log("RoomPlayerAdminBlockComponent videoStreamMessageHandler videoStreamMessage.command " + videoStreamMessage.command);
    await super.videoStreamMessageHandler(videoStreamMessage);

    switch (videoStreamMessage.command) {
      case VideoStreamCommandTypeEnum.FINISH_STREAM :
      case VideoStreamCommandTypeEnum.STOP_STREAM : {
        if (videoStreamMessage.streamType === StreamTypeEnum.CAM_INPUT) {
          this.mediaServerService.findInputModelsByStreamType(StreamTypeEnum.SCREEN_INPUT)
            .forEach((wsRtcModel) => this.stopTranslation(wsRtcModel.streamId));
        }
        break;
      }
      case VideoStreamCommandTypeEnum.USER_MUTE_STREAM: {
        // show call message for user
        if (videoStreamMessage.fromUserId !== this.globals.user.id) {
          this.stateService.subscriptions
            .sendMutedUserEvent(videoStreamMessage.fromUserId, videoStreamMessage.streamId, videoStreamMessage.statusParam);
        }
        break;
      }
      case VideoStreamCommandTypeEnum.USER_CAMERA_DISABLE: {
        // show call message for user
        if (videoStreamMessage.fromUserId !== this.globals.user.id) {
          this.stateService.subscriptions
            .sendDisabledCamEvent(videoStreamMessage.fromUserId, videoStreamMessage.streamId, videoStreamMessage.statusParam);
        }
        break;
      }
    }
  }
}
