export class ChatAttachment {
  id: number;
  fileName: string;
  fileUrl: string;
  fileSize: number;
  attachmentUserId: number;
  roomId: number;
  mappedName: string;
  dateAdded: Date;
  deleted: boolean;
  progress: number;
  complete: boolean;
  error: boolean;
}
