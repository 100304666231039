import {RoleTypeEnum} from "./enum/role-type.enum";

export class Role {
  role: RoleTypeEnum;

  constructor(role: RoleTypeEnum) {
    this.role = role;
  }

  get isAdmin(): boolean {
    return this.role === RoleTypeEnum.ROLE_ADMIN;
  }

  get uiRoleName(): string {
    if (this.isAdmin) {
      return 'room.members-admin';
    } else if (this.isModerator) {
      return 'room.members-moderator';
    } else if (this.isBlocked) {
      return 'room.members-blocked';
    } else if (this.isDeleted) {
      return 'room.members-deleted';
    } else {
      return 'room.members-user';
    }
  }

  get isUser(): boolean {
    return this.role === RoleTypeEnum.ROLE_USER;
  }

  get isModerator(): boolean {
    return this.role === RoleTypeEnum.ROLE_MODERATOR;
  }

  get isPlayerAllowed(): boolean {
    return this.isBlocked || this.isUser || this.isModerator;
  }

  get isInviteAllowed(): boolean {
    return this.isUser || this.isModerator;
  }

  get isChangeRoleToAdminAllowed(): boolean {
    return !(this.isDeleted || this.isBlocked);
  }

  get isBlocked(): boolean {
    return this.role === RoleTypeEnum.ROLE_BLOCKED;
  }

  get isDeleted(): boolean {
    return this.role === RoleTypeEnum.ROLE_DELETED;
  }

  get isUnknown(): boolean {
    return this.role === RoleTypeEnum.UNKNOWN;
  }

  equals(role: Role): boolean {
    return this.role === role.role;
  }
}
