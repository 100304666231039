import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {BehaviorSubject, Subject} from "rxjs";

declare const $;

@Component({
  selector: 'app-room-player-record-block',
  templateUrl: './room-player-record-block.component.html',
  styleUrls: ['./room-player-record-block.component.css']
})
export class RoomPlayerRecordBlockComponent implements OnInit, AfterViewInit {

  private _viewChildLoaded: BehaviorSubject<HTMLVideoElement> = new BehaviorSubject<HTMLVideoElement>(null);

  @ViewChild('videoElement', { static: false }) videoElement: ElementRef;

  constructor() {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    console.log("RoomPlayerRecordBlockComponent ngAfterViewInit htmlVideoElement " + this.videoElement.nativeElement);
    this._viewChildLoaded.next(this.videoElement.nativeElement);
    // $('.player__preview-item').draggable();
  }

  get viewChildLoaded(): Subject<HTMLVideoElement> {
    return this._viewChildLoaded;
  }
}
