import {Component, Input, OnInit} from '@angular/core';
import {ChatAttachment} from "../../../../model/chatAttachment";
import {Room} from "../../../../model/room.model";
import {Globals} from "../../../globals";
import {AttachmentService} from "../../../../services/attachment.service";
import {StringUtils} from "../../../../utils/string-utils";

@Component({
  selector: '[app-room-attachment]',
  templateUrl: './room-attachment.component.html',
  styleUrls: ['./room-attachment.component.css']
})
export class RoomAttachmentComponent implements OnInit {
  @Input() attachment: ChatAttachment;
  @Input() room: Room;

  constructor(public globals: Globals,
              private attachmentService: AttachmentService) {
  }

  ngOnInit(): void {
  }

  get isAllowRemove() {
    return this.globals.user.id === this.attachment.attachmentUserId
      || (this.room && this.globals.adminUserId === this.globals.user.id)
      || (this.globals.userRoomRole.isModerator);
  }

  deleteAttachment(): void {
    if (this.isAllowRemove) {
      this.attachmentService.deleteAttach(this.attachment);
    }
  }

  downloadAttachment(): void {
    if (!this.attachment.deleted) {
      const link = document.createElement('a');
      link.setAttribute('target', '_blank');
      link.setAttribute('href', this.attachment.fileUrl);
      link.setAttribute('download', this.attachment.fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  }

  public getFileSize(): string {
    return StringUtils.convertToFileSize(this.attachment.fileSize);
  }

  isOversize(attachment: ChatAttachment): boolean {
    //100 MB
    return attachment.fileSize > 100 * 1024 * 1024;
  }

  cancelUploading() {
    this.attachmentService.cancelUploading(this.attachment);
  }
}
