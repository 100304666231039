import {AfterViewInit, Component, ElementRef, HostListener, OnDestroy, ViewChild} from '@angular/core';
import {Globals} from '../../globals';
import {ActivatedRoute, Router} from '@angular/router';
import {MediaDevicesService} from "../../../services/media-devices.service";
import {Subscription} from "rxjs";
import {MediaStreamService} from "../../../services/media-stream-service";
import {StringUtils} from "../../../utils/string-utils";
import {AudioContextHolder} from "../../../services/audio-context.holder";

@Component({
  selector: 'check-devices-page-component',
  templateUrl: './check-devices-page.component.html'
})
export class CheckDevicesPageComponent implements AfterViewInit, OnDestroy {
  @ViewChild('video', { static: false }) video: ElementRef;
  @ViewChild('audio', { static: false }) audio: ElementRef;
  @ViewChild('volumeLevelControlPosition', { static: false }) volumeLevelControlPosition: ElementRef;
  @ViewChild('volumeLevel', { static: false }) volumeLevel: ElementRef;
  @ViewChild('modal_devices_permissions_not_allowed', { static: false }) modal_devices_permissions_not_allowed: ElementRef;
  @ViewChild('nextBtn', { static: false }) nextBtn: ElementRef;

  roomId: string;

  avatarBackground: string;

  private paramSubscription: Subscription;

  constructor(public globals: Globals,
              private route: ActivatedRoute,
              private router: Router,
              public mediaDevicesService: MediaDevicesService,
              public mediaStreamService: MediaStreamService) {
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.paramSubscription = this.route.params.subscribe(params => {
        this.roomId = params['id'];
        if (!this.roomId) {
          this.router.navigate(['/']);
        } else {
          this.mediaDevicesService.initService(
            this.video,
            this.audio,
            this.volumeLevel,
            this.volumeLevelControlPosition
          );
        }
      });
    });

    this.nextBtn.nativeElement.focus();
  }

  goToNextStep(): void {
    this.router.navigate([`/room/id/${this.roomId}/link`]);
  }

  goToCreateNewRoomPage(): void {
    this.router.navigate([`/room/id/${this.roomId}/edit`]);
  }

  ngOnDestroy(): void {
    this.mediaDevicesService.saveSelectedDevices();
    this.mediaDevicesService.onDestroy();
    this.paramSubscription.unsubscribe();
  }

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(event) {
    this.mediaDevicesService.processVolumeEvent(event);
  }

  @HostListener('document:mousedown', ['$event'])
  onMouseDown() {
    this.mediaDevicesService.volumeControlIsPickUp = true;
  }

  @HostListener('document:mouseup', ['$event'])
  onMouseUp() {
    this.mediaDevicesService.volumeControlIsPickUp = false;
  }

  getAvatarSymbols(): string {
    this.avatarBackground = StringUtils.getBackgroundColor(this.globals.user.name);

    return StringUtils.getFirstSymbols(this.globals.user.name);
  }
}
