import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.css']
})
export class DropdownComponent implements OnInit {
  @Input() simple: boolean;
  @Input() translateValues: boolean;
  @Output() valueChanged = new EventEmitter<any>();

  private _value: any;
  private _items: DropdownItem[];

  filterOpened: boolean;
  selectedItem: DropdownItem;

  constructor() {
    this.filterOpened = false;
    this._items = [];
  }

  ngOnInit(): void {
  }

  get value(): any {
    return this._value;
  }

  @Input()
  set value(value: any) {
    if (value && this._value !== value) {
      this._value = value;
      this.updateSelected();
    }
  }

  get items(): DropdownItem[] {
    return this._items;
  }

  @Input()
  set items(items: DropdownItem[]) {
    if (items && this._items !== items) {
      this._items = items;
      this.updateSelected();
    }
  }

  changeSelected(item: DropdownItem): void {
    this.selectedItem = item;
    this.filterOpened = false;
    this.valueChanged.emit(item.value);
  }

  updateSelected(): void {
    if (this._items && this._value) {
      this._items.forEach(val => {
        if (val.value === this._value) {
          this.selectedItem = val;
        }
      })
    }
  }
}
export interface DropdownItem {
  name: string;
  value: any;
}
