import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-devices-permissions-not-allowed-modal',
  templateUrl: './devices-permissions-not-allowed-modal.component.html',
  styleUrls: ['./devices-permissions-not-allowed-modal.component.css']
})
export class DevicesPermissionsNotAllowedModalComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
