import {Injectable} from '@angular/core';
import * as momentLib from 'moment';

declare const $: any;
declare const window: any;
declare var jQuery: any;

@Injectable({ providedIn: 'root' })
export class UtilsService {
  static moment = momentLib;
  static jquery = $;

  static readonly dateTimeFormatPattern = 'YYYY-MM-DDTHH:mm:ss.SSS';
  static readonly emptyTimeValue = '00:00:00';

  static formatNumberValueToTime(value: number): string {
    value = parseInt(value.toString());
    let hours: any = Math.floor(value / 3600);
    let minutes: any = Math.floor((value - (hours * 3600)) / 60);
    let seconds: any = value - (hours * 3600) - (minutes * 60);
    if (hours < 10) {
      hours = '0' + hours;
    }
    if (minutes < 10) {
      minutes = '0' + minutes;
    }
    if (seconds < 10) {
      seconds = '0' + seconds;
    }
    return hours + ':' + minutes + ':' + seconds;
  }

  static isBeforeNow(date: string, pattern: string): boolean {
    let m = this.moment(date, pattern);
    let now = this.moment().seconds(0);
    console.log("m", m);
    console.log("now", now);
    return m.isBefore(now);
  }

  static formatDate(date: string, pattern: string): string {
    return UtilsService.moment.utc(date).format(pattern)
  }

  static correctTimeZone(date: string): string {
    const utcOffset = UtilsService.moment().utcOffset();
    return UtilsService.moment.utc(date).utcOffset(utcOffset)
      .format(UtilsService.dateTimeFormatPattern);
  }

  static dateDurationBetweenNowDate(date: string): string {
    const dateFrom = UtilsService.moment(date, UtilsService.dateTimeFormatPattern);
    const dateTo = UtilsService.moment(new Date());
    return UtilsService.moment.utc(dateTo.diff(dateFrom)).format('HH:mm:ss')
  }

  static getParameterFromUrlByName(name: string, url?: string): string {
    name = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
    if (!url) {
      url = window.location.href;
    }
    const results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  static getGUIDWithSeparators(): string {
    return UtilsService.getGUID(true);
  }

  static getGUIDWithoutSeparators(): string {
    return UtilsService.getGUID(false);
  }

  static getGUID(withSeparators: boolean): string {
    const s4 = (): string => {
      return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
    };
    if (withSeparators) {
      return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    } else {
      return s4() + s4() + s4() + s4() + s4() + s4() + s4() + s4();
    }
  }

  static showFancyBox(id: string): void {
    let modal = $(id);
    $.fancybox.open(modal, {
      touch: false,
      buttons: [],
      clickOutside: "close"
    });
  }

  static closeFancyBox() {
    $.fancybox.close(true);
  }

  static isIOS(): boolean {
    return /iPad|iPhone|iPod/.test(navigator.userAgent) && !$(window).MSStream;
  }

  static isSafari(): boolean {
    let ua = navigator.userAgent.toLowerCase();
    return ua.indexOf("safari") > -1 && ua.indexOf('chrome') === -1;
  }

  static isAndroid(): boolean {
    return /android/.test(navigator.userAgent);
  }

  static quant(val: number): string {
    let text = '';
    let count: number = val;
    if (count > 100) count %= 100;
    if (count > 20) count %= 10;
    switch (count) {
      case 1:
        text = 'case2';
        break;
      case 2:
      case 3:
      case 4:
        text = 'case3';
        break;
      default:
        text = 'case1';
        break;
    }
    return text;
  }

  static updateContextTriggerOnClick(): void {
    const contextTrigger = $('.context__trigger');
    contextTrigger.off('click');
    contextTrigger.on('click', function (e) {
      e.preventDefault();
      e.stopPropagation();

      const $this = $(this);
      const context = $this.closest('.context');
      const menu = context.find('.context__menu');
      const containerWidth = ($this.closest(".message__content").outerWidth() ||
                              $this.closest(".members__content").outerWidth()) + 48; // 32px avatar width + 16px margin

      const menuWidth = menu.outerWidth();

      if (containerWidth < menuWidth) {
        menu.addClass('right-position');
      }

      $('.select__dropdown').hide();
      $('.context__menu').hide();

      $('.select').removeClass('opened');
      $('.message__member').removeClass('selected');
      $('.message__file').removeClass('selected');
      $('.message').removeClass('selected');
      $('.chat__context').removeClass('selected');
      $('.header__user').removeClass('opened');

      $this.closest('.message__member').addClass('selected');
      $this.closest('.message__file').addClass('selected');
      $this.closest('.message').addClass('selected');
      $this.closest('.chat__context').addClass('selected');
      menu.show();
    });
  }

  static questionTrigger(target): void {
    const $this = $(target);
    const isOpened = $this.hasClass('opened');
    const messages = $this.closest('.question').find('.question__messages');
    if (isOpened) {
      $this.removeClass('opened');
      messages.slideUp(200);
    } else {
      $this.addClass('opened');
      messages.slideDown(200);
    }
  }

  static generalScripts(): void {
    const body = $('body');

    // Fancybox
    body.off('click', '[data-fancybox]');
    body.on('click', '[data-fancybox]', (e) => {
      e.preventDefault();
      this.showFancyBox($(e.target).attr('data-src'));
    });
    body.off('click', '[data-fancybox-close]');
    body.on('click', '[data-fancybox-close]', (e) => {
      e.preventDefault();
      this.closeFancyBox()
    }); // END Fancybox

    // Context menu and dropdown
    UtilsService.updateContextTriggerOnClick();

    $('html, body').on('click', function () {
      $('.context__menu').hide();
      $('.message').removeClass('selected');
      $('.message__member').removeClass('selected');
      $('.message__file').removeClass('selected');
      $('.header__user').removeClass('opened');
      $('.chat__context').removeClass('selected');
    }); // END Context menu
    // header settings

    $('.header__settings').off('click');
    $('.header__settings').on('click', function () {
      $('.settings').show();
    });
    $('.settings__close, .settings__exit').off('click');
    $('.settings__close, .settings__exit').on('click', function () {
      $('.settings').hide();
    }); // END header settings

    body.off('click', '.header__avatar');
    body.on('click', '.header__avatar', function (e) {
      e.stopPropagation();
      e.preventDefault();
      $(this).closest('.header__user').toggleClass('opened');
    });
    $('.textarea').on('keydown', function () {
      const element = this;
      setTimeout(function () {
        element.style.cssText = 'height:auto; padding:0';
        element.style.cssText = 'height:' + element.scrollHeight + 'px';
      });
    });
  }

  public static copyToClipboard(text: string): void {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = text;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }
}
