import {ElementRef, Injectable} from "@angular/core";
import {VideoStreamService} from "./video-stream.service";
import {MediaStreamService} from "./media-stream-service";
import {StateService} from "./state.service";
import {UserService} from "./user.service";
import {AudioContextHolder} from "./audio-context.holder";
import {NGXLogger} from "ngx-logger";
import {DropdownItem} from "../app/components/dropdown/dropdown.component";

@Injectable({ providedIn: 'any' })
export class MediaDevicesService {
  videoStream: MediaStream;

  videoDevices: MediaDeviceInfo[];
  audioInputDevices: MediaDeviceInfo[];
  audioOutputDevices: MediaDeviceInfo[];
  selectedVideoDevice: MediaDeviceInfo;
  selectedAudioInputDevice: MediaDeviceInfo;
  selectedAudioOutputDevice: MediaDeviceInfo;
  selectedMicrophoneVolume: number;
  audioContext: AudioContext;
  mediaStreamSource: MediaStreamAudioSourceNode;
  mediaStreamDestination: MediaStreamAudioDestinationNode;
  scriptProcessor: ScriptProcessorNode;
  gainNode: GainNode;
  volumeControlIsPickUp: boolean;
  updateDeviceListInProgress: boolean;
  deviceChangeCount: number;
  deviceIsChanged: boolean;
  private isDestroyed: boolean;
  isCameraDisabled: boolean = false;
  isMuted: boolean = false;

  videoSetting: ElementRef;
  audioSetting: ElementRef;
  volumeLevel: ElementRef;
  volumeLevelControlPosition: ElementRef;

  ddVideoDevices: DropdownItem[];
  ddAudioInputDevices: DropdownItem[];

  constructor(private videoStreamService: VideoStreamService,
              private mediaStreamService: MediaStreamService,
              public subscriptionService: StateService,
              public userService: UserService,
              private logger: NGXLogger
  ) {
    this.videoStream = null;
    this.videoDevices = [];
    this.audioInputDevices = [];
    this.audioOutputDevices = [];
    this.volumeControlIsPickUp = false;
    this.updateDeviceListInProgress = false;
    this.deviceChangeCount = 0;
    this.deviceIsChanged = false;
    this.ddVideoDevices = [];
    this.ddAudioInputDevices = [];
    this.isDestroyed = false;
  }

  public toggleDisableVideo() {
    this.isCameraDisabled = !this.isCameraDisabled;
    if (this.videoStream) {
      this.videoStream?.getTracks().forEach((track) => {
        track.enabled = !this.isCameraDisabled;
      });
    }
  }

  public toggleMute() {
    this.isMuted = !this.isMuted;
    this.mediaStreamDestination?.stream?.getAudioTracks().forEach((track) => {
      track.enabled = !this.isMuted;
    })
  }

  public deviceLabel(device: MediaDeviceInfo) {
    if (device) {
      return device.label ? device.label : device.deviceId
    }
    return ' ';
  }

  fillDeviceList(updateDeviceList: boolean): void {
    if (!this.isDestroyed) {
      this.logger.info('MediaDevicesService.fillDeviceList');

      if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
        this.logger.error("enumerateDevices() not supported.");
        return;
      }
      // console.log("MediaDevicesService fillDeviceList");

      navigator.mediaDevices.enumerateDevices().then((devices: MediaDeviceInfo[]) => {
        if (!this.isDestroyed) {
          this.logger.debug('MediaDevicesService.fillDeviceList: navigator.mediaDevices.enumerateDevices()');
          this.videoDevices = !this.mediaStreamService.isCameraNotAllowed ? devices.filter((device: MediaDeviceInfo) => device.kind === 'videoinput') : [];
          this.updateVideoDevices();
          this.audioInputDevices = devices.filter((device: MediaDeviceInfo) =>
            device.kind === 'audioinput'
            && device.deviceId != 'default'
            && device.deviceId != 'communications'
          );
          this.updateAudioInputDevices();
          this.audioOutputDevices = devices.filter((device: MediaDeviceInfo) =>
            device.kind === 'audiooutput'
            && device.deviceId != 'default'
            && device.deviceId != 'communications'
          );

          let prevSelectedVideoDevice = this.selectedVideoDevice;
          this.selectedVideoDevice = this.mediaStreamService.isCameraNotAllowed ? null : this.getCurrentDevice(
            this.videoDevices,
            updateDeviceList === true ? this.selectedVideoDevice?.deviceId : this.mediaStreamService.videoDeviceId
          );
          this.selectedAudioInputDevice = this.getCurrentDevice(
            this.audioInputDevices,
            updateDeviceList === true ? this.selectedAudioInputDevice?.deviceId : this.mediaStreamService.audioInDeviceId
          );

          // console.log("MediaDevicesService selectedAudioInputDevice " + this.selectedAudioInputDevice);

          this.selectedAudioOutputDevice = this.getCurrentDevice(
            this.audioOutputDevices,
            updateDeviceList === true ? this.selectedAudioOutputDevice?.deviceId : this.mediaStreamService.audioOutDeviceId
          );

          if (updateDeviceList === true) {
            this.updateAudioStream();
            if (prevSelectedVideoDevice !== this.selectedVideoDevice) {
              this.updateVideoStream();
            }
          } else {
            setTimeout(() => {
              this.initVideoStream(this.selectedVideoDevice);
              this.initAudioStream(
                this.selectedAudioInputDevice,
                this.selectedAudioOutputDevice
              );
            }, 100)
          }
        }
      });
    }
  }

  initVideoStream(videoDevice: MediaDeviceInfo): void {
    if (!this.isDestroyed) {
      this.logger.info('MediaDevicesService.initVideoStream');

      if (this.mediaStreamService.isCameraNotAllowed || !navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
        this.logger.error('initVideoStream getUserMedia() not supported.');
        return;
      }
      const videoConstraints: MediaTrackConstraints = {
        width: {
          min: 640,
          ideal: 1920,
          max: 2560,
        },
        height: {
          min: 480,
          ideal: 1080,
          max: 1440
        }
      };

      if (videoDevice) {
        videoConstraints.deviceId = videoDevice.deviceId;
      }

      navigator.mediaDevices.getUserMedia({video: videoConstraints, audio: false}).then((stream: MediaStream) => {
        if (!this.isDestroyed) {
          this.logger.debug('MediaDevicesService.initVideoStream: navigator.mediaDevices.getUserMedia');
          // const video = document.getElementById('vi');
          // this.videoStream
          this.disconnectVideoDevices();
          this.videoStream = stream;
          this.videoStream.getTracks().forEach((track) => {
            track.enabled = !this.isCameraDisabled;
          });

          const video: HTMLVideoElement = document.getElementById(this.videoSetting.nativeElement.id) as HTMLVideoElement;
          if (video) {
            video.srcObject = stream;
            video.muted = true;
          }
        } else {
          stream.getTracks().forEach(track => track.stop());
        }
      }).catch(reason => {
          this.logger.error("navigator.mediaDevices.getUserMedia({ video: " + JSON.stringify(videoConstraints) + ", audio: false })", reason);
        }
      );
    } else {
      this.disconnectVideoDevices();
    }
  }

  updateAudioStream(): void {
    this.disconnectAudioDevices();
    this.initAudioStream(
      this.selectedAudioInputDevice,
      this.selectedAudioOutputDevice
    );
  }

  disconnectAudioDevices(): void {
    this.logger.info("MediaDevicesService.disconnectAudioDevices");

    if (this.mediaStreamSource) {
      if (this.mediaStreamSource.mediaStream) {
        this.mediaStreamSource.mediaStream.getTracks()
          .forEach((track) => {
            track.stop();
            this.mediaStreamSource.mediaStream.removeTrack(track);
          });
      }
      this.logger.info("disconnectAudioDevices this.mediaStreamSource.disconnect()");
      this.mediaStreamSource.disconnect();
      this.mediaStreamSource = null;
    }

    if (this.gainNode) {
      this.logger.info("disconnectAudioDevices this.gainNode.disconnect()");
      this.gainNode.disconnect();
      this.gainNode = null;
    }

    if (this.scriptProcessor) {
      this.logger.info("disconnectAudioDevices this.scriptProcessor.disconnect()");
      this.scriptProcessor.onaudioprocess = null;
      this.scriptProcessor.disconnect();
      this.scriptProcessor = null;
    }

    if (this.mediaStreamDestination) {
      this.logger.info("disconnectAudioDevices this.mediaStreamDestination.disconnect()");
      this.mediaStreamDestination.disconnect();
      this.mediaStreamDestination = null;
    }
  }

  checkDevicePermissions(successCallback, errorCallback) {
    this.logger.info('MediaDevicesService.checkDevicePermissions');
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then((stream: MediaStream) => {
        stream.getTracks()
          .forEach((track) => {
            track.stop();
            stream.removeTrack(track);
          });

        navigator.mediaDevices.getUserMedia({ video: true })
          .then((stream: MediaStream) => {
            stream.getTracks()
              .forEach((track) => {
                track.stop();
                stream.removeTrack(track);
              });
            this.mediaStreamService.isCameraNotAllowed = false;
            successCallback();
          })
          .catch(reason => {
              this.logger.error("navigator.mediaDevices.getUserMedia({ video: true })", reason);
              if (!this.mediaStreamService.isCameraNotAllowed) {
                this.mediaStreamService.processErrorReason(reason);
                this.mediaStreamService.isCameraNotAllowed = true;
              }
              successCallback();
            }
          );
      })
      .catch(reason => {
          this.logger.error("navigator.mediaDevices.getUserMedia({ audio: true })", reason);
          errorCallback(reason);
        }
      );
  }

  initAudioStream(inputDevice: MediaDeviceInfo,
                  outputDevice: MediaDeviceInfo): void {
    this.logger.info('MediaDevicesService.initAudioStream');

    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      this.logger.error("initAudioStream getUserMedia() not supported.");
      return;
    }
    const inputConstraints: MediaTrackConstraints = {};
    if (inputDevice) {
      inputConstraints.deviceId = inputDevice.deviceId;
      inputConstraints.echoCancellation = true;
    }

    if (!this.isDestroyed) {
      navigator.mediaDevices.getUserMedia({video: false, audio: inputConstraints}).then((stream: MediaStream) => {
        this.logger.debug('MediaDevicesService.initAudioStream: navigator.mediaDevices.getUserMedia');
        if (!this.isDestroyed) {
          this.initSoundMeter(stream);
        } else {
          stream.getTracks().forEach(track => track.stop());
        }
      }).catch((reason => {
        this.logger.error("navigator.mediaDevices.getUserMedia({ video: false, audio: " + JSON.stringify(inputConstraints) + " })", reason);
      }));
    }
  }

  initSoundMeter(stream: MediaStream): void {
    this.logger.info('MediaDevicesService.initSoundMeter');

    this.disconnectAudioDevices();

    this.audioContext = AudioContextHolder.audioContext;

    this.mediaStreamSource = this.audioContext.createMediaStreamSource(stream);
    this.mediaStreamDestination = this.audioContext.createMediaStreamDestination();
    this.audioSetting.nativeElement.srcObject = this.mediaStreamDestination.stream;
    this.audioSetting.nativeElement.muted = true;
    this.gainNode = this.audioContext.createGain();
    this.gainNode.gain.value = this.mediaStreamService.audioInVolumeLevel;
    const blockSize = 4096;
    const channelCount = 1;

    this.scriptProcessor = this.audioContext.createScriptProcessor(blockSize, channelCount, channelCount);
    this.mediaStreamSource.connect(this.gainNode);
    this.gainNode.connect(this.mediaStreamDestination);
    this.gainNode.connect(this.scriptProcessor);
    this.scriptProcessor.connect(this.audioContext.destination);

    this.scriptProcessor.onaudioprocess = (event: AudioProcessingEvent): void => {
      const inputBuffer = event.inputBuffer;
      const outputBuffer = event.outputBuffer;
      const len = blockSize * outputBuffer.numberOfChannels;
      let total = 0.0;
      for (let channel = 0; channel < outputBuffer.numberOfChannels; channel++) {
        const inputData = inputBuffer.getChannelData(channel);
        const outputData = outputBuffer.getChannelData(channel);

        for (let sample = 0; sample < inputBuffer.length; sample++) {
          if (this.isMuted) {
            outputData[sample] = 0;
          } else {
            outputData[sample] = inputData[sample];
          }

          total += Math.abs(outputData[sample]);
        }
      }
      const volume = Math.sqrt(total / len) * 100;
      // console.log( "onaudioprocess volume " + volume);
      this.volumeLevel.nativeElement.style.width = Math.round(volume) + '%';
    };
    this.logger.debug('MediaDevicesService.initSoundMeter: initialization done');
  }

  public initService(videoSetting: ElementRef,
                     audioSetting: ElementRef,
                     volumeLevel: ElementRef,
                     volumeLevelControlPosition: ElementRef) {
    this.logger.info('MediaDevicesService.initService');

    this.videoSetting = videoSetting;
    this.audioSetting = audioSetting;
    this.volumeLevel = volumeLevel;
    this.volumeLevelControlPosition = volumeLevelControlPosition;
    this.selectedMicrophoneVolume = this.mediaStreamService.audioInVolumeLevel;
    this.volumeLevelControlPosition.nativeElement.style = 'left: ' + Math.round(this.selectedMicrophoneVolume * 100) + '%';
    this.isCameraDisabled = this.mediaStreamService.isCameraDisabled;
    this.isMuted = this.mediaStreamService.isMuted;
    this.isDestroyed = false;

    this.checkDevicePermissions(() => {
        this.fillDeviceList(false);
      }, (reason) => {
        this.clearSelectedDevices();
        this.mediaStreamService.processErrorReason(reason);
      }
    );

    navigator.mediaDevices.ondevicechange = (event) => {
      this.logger.debug('MediaDevicesService.initService: navigator.mediaDevices.ondevicechange');
      this.deviceIsChanged = true;
      // console.log("RoomSettingsBlockComponent ondevicechange " + serv_this.deviceChangeCount++);
      setTimeout(() => {
        if (this.deviceIsChanged) {
          this.deviceIsChanged = false;
          this.deviceChangeCount = 0;
          // console.log("RoomSettingsBlockComponent fillDeviceList");
          this.fillDeviceList(true);
        }
      }, 200)
    }
  }

  private getCurrentDevice(mediaDeviceArr: MediaDeviceInfo[], deviceId: string): MediaDeviceInfo {
    if (mediaDeviceArr && mediaDeviceArr.length > 0) {
      let selDevice = mediaDeviceArr.find(device => {
        return device.deviceId === deviceId;
      });

      return selDevice != null ? selDevice : mediaDeviceArr[0];
    } else {
      return null;
    }
  }

  public clearSelectedDevices() {
    this.logger.info('clearSelectedDevices');
    this.selectedVideoDevice = null;
    this.selectedAudioInputDevice = null;
    this.selectedAudioOutputDevice = null;
  }

  disconnectVideoDevices(): void {
    this.logger.info('MediaDevicesService disconnectVideoDevices');
    if (this.videoStream) {
      this.videoStream.getTracks()
        .forEach((track) => {
          this.logger.debug('MediaDevicesService disconnectVideoDevices track.stop(): {}', track);
          track.stop();
          this.videoStream.removeTrack(track);
        });
      this.logger.info('MediaDevicesService disconnectVideoDevices this.videoStream.removeTrack');
    }
  }

  updateVideoStream() {
    this.disconnectVideoDevices();
    this.initVideoStream(this.selectedVideoDevice);
  }

  onDestroy() {
    this.logger.debug('MediaDevicesService onDestroy');
    this.isDestroyed = true;
    this.releaseResources();
  }

  private releaseResources() {
    this.logger.info('MediaDevicesService releaseResources');

    navigator.mediaDevices.ondevicechange = null;

    this.disconnectVideoDevices();
    this.disconnectAudioDevices();
  }

  selectVideoDevice(device: MediaDeviceInfo) {
    this.disconnectVideoDevices();
    this.selectedVideoDevice = device;
    this.initVideoStream(this.selectedVideoDevice);
  }

  selectAudioInputDevice(device: MediaDeviceInfo): void {
    this.selectedAudioInputDevice = device;
    this.updateAudioStream();
  }

  selectAudioOutputDevice(device: MediaDeviceInfo): void {
    this.selectedAudioOutputDevice = device;
    this.updateAudioStream();
  }

  saveSelectedDevices() {
    this.mediaStreamService.setDevices(this);
  }

  public processVolumeEvent(event) {
    if (event.target.id === 'volume-level-control' || event.target.id === 'volume-track') {
      if (this.volumeControlIsPickUp && this.audioContext) {
        let volumeValue: number = event.offsetX / event.target.clientWidth;
        volumeValue = Number(volumeValue.toPrecision(2));
        if (volumeValue < 0) volumeValue = 0;
        this.volumeLevelControlPosition.nativeElement.style.left = Math.round(volumeValue * 100) + '%';
        this.gainNode.gain.value = volumeValue;
        this.selectedMicrophoneVolume = volumeValue;
        this.logger.debug("volumeValue {}", volumeValue);
      }
    }
  }

  private updateVideoDevices(): void {
    this.ddVideoDevices = [];
    this.videoDevices?.forEach(device => this.ddVideoDevices.push({name: this.deviceLabel(device), value: device}));
  }

  private updateAudioInputDevices(): void {
    this.ddAudioInputDevices = [];
    this.audioInputDevices?.forEach(device => this.ddAudioInputDevices.push({name: this.deviceLabel(device), value: device}));
  }
}
