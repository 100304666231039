import {Directive, ElementRef, HostListener, Input} from '@angular/core';

declare const $: any;

@Directive({
  selector: '[inputRegExp]'
})
export class InputRegExpDirective {
  constructor(private el: ElementRef) {
  }

  @Input() inputRegExp: string;

  findDelta = (value, prevValue) => {
    let delta = '';
    for (let i = 0; i < value.length; i++) {
      const str = value.substr(0, i) + value.substr(i + value.length - prevValue.length);
      if (str === prevValue) {
        delta = value.substr(i, value.length - prevValue.length);
      }
    }
    return delta;
  };

  isValidChar = (c) => {
    return new RegExp(this.inputRegExp).test(c);
  };

  isValidString = (str) => {
    for (let i = 0; i < str.length; i++) {
      if (!this.isValidChar(str.substr(i, 1))) {
        return false;
      }
    }
    return true;
  };

  @HostListener('input', ['$event']) onKeyDown(event) {
    const e = <KeyboardEvent>event;
    const val = event.target.value;
    const lastVal = $(event.target).data('lastVal');
    // get inserted chars
    const inserted = this.findDelta(val, lastVal);
    // get removed chars
    const removed = this.findDelta(lastVal, val);
    // determine if user pasted content
    const pasted = inserted.length > 1 || (!inserted && !removed);
    if (pasted) {
      if (!this.isValidString(val)) {
        event.target.value = lastVal;
      }
    } else if (!removed) {
      if (!this.isValidChar(inserted)) {
        event.target.value = lastVal;
      }
    }
    // store current value as last value
    $(event.target).data('lastVal', event.target.value);
  }

  @HostListener('focus', ['$event']) onFocus(event) {
    $(event.target).data('lastVal', event.target.value);
  }
}
