import {StompConfiguration} from "../wrappers/stomp.configuration";
import {Subject} from "rxjs";

export class StompConfigurationBuilder<T> {
  private _roomId: number;
  private _wsPath: string;
  private _messageSubject: Subject<T>;
  private _messagesSubject: Subject<T[]>;
  private _connectedSubject: Subject<void>;
  private _senderSubject: Subject<T>;
  private _tClass: new () => T;

  public tClass(tClass: new () => T): StompConfigurationBuilder<T> {
    this._tClass = tClass;
    return this;
  }

  public roomId(roomId: number): StompConfigurationBuilder<T> {
    this._roomId = roomId;
    return this;
  }

  public wsPath(wsPath: string): StompConfigurationBuilder<T> {
    this._wsPath = wsPath;
    return this;
  }

  public messageSubject(onMessageSubject: Subject<T>): StompConfigurationBuilder<T> {
    this._messageSubject = onMessageSubject;
    return this;
  }

  public messagesSubject(onMessagesSubject: Subject<T[]>): StompConfigurationBuilder<T> {
    this._messagesSubject = onMessagesSubject;
    return this;
  }

  public connectedSubject(connectedSubject: Subject<void>): StompConfigurationBuilder<T> {
    this._connectedSubject = connectedSubject;
    return this;
  }

  public senderSubject(senderSubject: Subject<T>): StompConfigurationBuilder<T> {
    this._senderSubject = senderSubject;
    return this;
  }

  public build(): StompConfiguration<T> {
    return new StompConfiguration<T>(this._roomId,
      this._wsPath, this._messageSubject, this._messagesSubject, this._connectedSubject, this._senderSubject, this._tClass);
  }
}
