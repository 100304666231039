import {AfterViewInit, Component} from "@angular/core";
import {Globals} from "../../../globals";
import {MediaServerService} from "../../../../services/media-server.service";
import {UserService} from '../../../../services/user.service';
import {VideoStreamService} from '../../../../services/video-stream.service';
import {VideoStreamCommandTypeEnum} from '../../../../model/enum/video-stream-command-type.enum';
import {WsRtcModel} from '../../../../model/ws-rtc.model';
import {MediaStreamService} from "../../../../services/media-stream-service";
import {StateService} from "../../../../services/state.service";
import {MediaDevicesService} from "../../../../services/media-devices.service";
import {InputIdInfo} from "../../../../model/input-id-info.model";
import {VideoStreamMessage} from "../../../../model/message/video-stream.message";
import {VideoPlayerBlockComponent} from "./video-player-block.component";
import {UserRoomParamService} from "../../../../services/user-room-param.service";
import {NotificationsService} from "../../../../services/notifications.service";
import {NGXLogger} from "ngx-logger";
import {RoomType} from "../../../../model/enum/room-type";

@Component({
  selector: '[app-video-player-user-block]',
  templateUrl: './video-player-block.component.html',
  styleUrls: ['./video-player-block.component.css']
})
export class VideoPlayerUserBlockComponent extends VideoPlayerBlockComponent implements AfterViewInit {

  constructor(globals: Globals,
              mediaServerService: MediaServerService,
              mediaStreamService: MediaStreamService,
              videoStreamService: VideoStreamService,
              subscriptionService: StateService,
              userService: UserService,
              userRoomParamService: UserRoomParamService,
              mediaDevicesService: MediaDevicesService,
              notificationsService: NotificationsService,
              logger: NGXLogger) {

    super(globals, mediaServerService, mediaStreamService, videoStreamService, subscriptionService, userService,
      mediaDevicesService, userRoomParamService, notificationsService, logger, 'user');
  }

  protected onOutputStatusChange(wsRtcModel: WsRtcModel, online: boolean): void {
    super.onOutputStatusChange(wsRtcModel, online);

    if (wsRtcModel.outputUserId === this.globals.user.id) {
      this.sendUserReadyForCall();
    }
  }

  private sendUserReadyForCall(): void {
    let existsUserStream = this.mediaServerService.findConnectedOutputUserStream(this.globals.user.id);
    if (existsUserStream) {
      this.userService.sendReadyForCallMessage(this.globals.user.id, existsUserStream.streamId, false);
    } else {
      let connectToSpeakerStreamId = this.mediaServerService
        .findConnectedToStreamUserIdOutputModelsByOutputStreamUserId(this.globals.adminUserId, this.globals.user.id);

      this.userService.sendReadyForCallMessage(this.globals.user.id, connectToSpeakerStreamId, !!connectToSpeakerStreamId);
    }
  }

  protected onInputStatusChange(inputIdInfo: InputIdInfo): void {
    // super.onInputStatusChange(inputIdInfo);

    // if admin stream is offline - stop all user streams (for conference only)
    if (this.room.type === RoomType.CONFERENCE && !inputIdInfo.online &&
      this.globals.adminUserId === inputIdInfo.streamUserId && inputIdInfo.isInputCam()) {
      //stop all streams
      this.mediaServerService.stopInputStreams();
      this.stateService.userIsInvitedToStream = false;
    }
  }

  protected async videoStreamMessageHandler(videoStreamMessage: VideoStreamMessage): Promise<void> {
    await super.videoStreamMessageHandler(videoStreamMessage);
    switch (videoStreamMessage.command) {
      case VideoStreamCommandTypeEnum.USER_MUTE_STREAM: {
        // show call message for user
        this.stateService
          .requests.sendMuteUserRequest(videoStreamMessage.toUserId, videoStreamMessage.streamId, videoStreamMessage.statusParam);
        break;
      }
      case VideoStreamCommandTypeEnum.USER_CAMERA_DISABLE: {
        // show call message for user
        this.stateService
          .requests.sendDisableCamRequest(videoStreamMessage.toUserId, videoStreamMessage.streamId, videoStreamMessage.statusParam);
        break;
      }
    }
  }

  protected initMuteSubscription(): void {
    this.stateService.requests
      .onMuteUserRequest
      .subscribe((muteEvent) => {

        let callback = (userId: number, streamId: number, muted: boolean) => {
          this.stateService.subscriptions.sendMutedUserEvent(userId, streamId, muted);
          if (this.stateService.userIsConnectedToStream) {
            this.videoStreamService
              .sendUserMuteStreamMessage(muteEvent.streamId, this.globals.adminUserId, muteEvent.mute, "Answer from user to speaker about mute status");
          }
        };

        this.mediaServerService.muteStreamByUserId(muteEvent.mute, muteEvent.userId, callback);
      });
  }

  protected initDisableCamSubscription(): void {
    this.stateService.requests
      .onDisableCamRequest
      .subscribe((camDisabledEvent) => {
        let callback = (userId: number, streamId: number, disabled: boolean) => {
          if (!this.mediaStreamService.isCameraNotAllowed) {
            this.stateService.subscriptions.sendDisabledCamEvent(userId, streamId, disabled);
            if (this.stateService.userIsConnectedToStream) {
              this.videoStreamService
                .sendUserCameraDisabledMessage(streamId, this.globals.adminUserId, disabled, "Answer from user to speaker about disabled camera status");
            }
          }
        };

        this.mediaServerService
          .disableVideoStreamByUserId(camDisabledEvent.disabled, camDisabledEvent.userId, callback);
      });
  }
}
