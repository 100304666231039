import {filter} from "rxjs/operators";
import {Input, OnDestroy} from '@angular/core';
import {Subscription, timer} from "rxjs";
import {Globals} from "../../../globals";
import {StateService} from "../../../../services/state.service";
import {VideoStreamService} from "../../../../services/video-stream.service";
import {MediaDevicesService} from "../../../../services/media-devices.service";
import {UtilsService} from "../../../../services/utils.service";
import {Room} from "../../../../model/room.model";
import {UserRoomParamService} from "../../../../services/user-room-param.service";
import {UserRoomParamMessage} from "../../../../model/message/user-room-param.message";
import {UserService} from "../../../../services/user.service";
import {MediaStreamService} from "../../../../services/media-stream-service";
import {MediaServerService} from "../../../../services/media-server.service";
import {AudioContextHolder} from "../../../../services/audio-context.holder";
import {RoomType} from "../../../../model/enum/room-type";
import {NGXLogger} from "ngx-logger";

export class VideoPlayerControlsComponent implements OnDestroy {
  @Input() room: Room;
  @Input() _onlineMembersCount: number;
  @Input() screenShared: boolean

  public isCameraDisabled: boolean;
  public isMuted: boolean;
  public isConnectedToStream: boolean;
  public isShareStreamStarted: boolean;
  public tryStartTranslation: boolean;
  public tryStartShareScreen: boolean;
  public _camStreamId: number;
  public _screenStreamId: number;
  public currentTime: string;
  public isSoundEnabled: boolean;
  public isMutedPopupDisplayed: boolean;
  public isScreenShareHintDisplayed: boolean;

  private _timerCamSubscription: Subscription;
  private _timerShareSubscription: Subscription;

  private subscriptions: Subscription[] = [];

  constructor(public globals: Globals,
              public stateService: StateService,
              public videoStreamService: VideoStreamService,
              public mediaDevicesService: MediaDevicesService,
              public mediaStreamService: MediaStreamService,
              public userRoomParamService: UserRoomParamService,
              public mediaServerService: MediaServerService,
              public userService: UserService,
              private logger: NGXLogger,
              public mode: string) {
    this.tryStartTranslation = false;
    this.tryStartShareScreen = false;
    this.currentTime = UtilsService.emptyTimeValue;
    this._onlineMembersCount = 0;
    this.isCameraDisabled = mediaStreamService.isCameraDisabled;
    this.isMuted = mediaStreamService.isMuted;
    this.isMutedPopupDisplayed = null;

    this.initSoundEnabledSubscription();
    this.initConnectedToStreamSubscription();
    this.initConnectedToShareStreamSubscription();
    this.initMuteSubscription();
    this.initDisableCamSubscription();
    this.initUsersOnlineSubscription();
    this.initConnectUserToStreamSubscription();
  }

  public startCamStream(): void {
    this.logger.info("startCamStream, tryStartTranslation: {}", this.tryStartTranslation);
    if (!this.tryStartTranslation) {
      this.tryStartTranslation = true;
      this.mediaDevicesService.checkDevicePermissions(() => {
        this.room.isNew = false;

        this.initCamTimer();

        this.videoStreamService
          .sendPublishCamStreamMessage(this.globals.user.id, "Try to start camera translation");
        this.logger.info("Try to connect user to stream, tryStartTranslation: {}"
          , this.tryStartTranslation)
      }, (reason) => {
        this.mediaStreamService.processErrorReason(reason);
        this.tryStartTranslation = false;
        this.logger.error("Failed to create cam stream", reason);
      });
    }
  }

  public startScreenStream(): void {
    if (this.screenShared) {
      this.isScreenShareHintDisplayed = true;
    } else if (this.isConnectedToStream && !this.tryStartShareScreen) {
      this.tryStartShareScreen = true;
      this.initShareTimer();

      this.stateService.issContainer = this.mediaStreamService.createInputScreenContainer();
      this.videoStreamService
        .sendPublishScreenStreamMessage(this.globals.user.id, "Try to start screen translation");
    }
  }

  public stopCamStream() {
    this.videoStreamService
      .sendStopCamStreamMessage(this._camStreamId, this.globals.user.id, "manually stop");
  }

  public finishCamStream() {
    this.videoStreamService
      .sendFinishStreamMessage(this._camStreamId, this.room.id, "admin finished translation");
  }

  public stopScreenStream() {
    if (this.isConnectedToStream) {
      this.videoStreamService
        .sendStopScreenStreamMessage(this._screenStreamId, this.globals.user.id, "manually stop");
    }
  }

  public mute(): void {
    this.stateService.requests.sendMuteUserRequest(this.globals.user.id, this._camStreamId, !this.isMuted);
    this.sendDeviceStatuses();
  }

  public disableCam(): void {
    if (!this.mediaStreamService.isCameraNotAllowed) {
      this.stateService.requests.sendDisableCamRequest(this.globals.user.id, this._camStreamId, !this.isCameraDisabled);
      this.sendDeviceStatuses();
    }
  }

  private sendDeviceStatuses(): void {
    this.userRoomParamService.send(new UserRoomParamMessage(this.globals.user.id, this.room.id, this.isMuted,
      this.isCameraDisabled, this.mediaStreamService.isCameraNotAllowed));
  }

  protected initMuteSubscription(): void {
    this.subscriptions.push(this.stateService.subscriptions
      .onMutedUser
      .pipe(filter((muteEvent) => muteEvent.userId === this.globals.user.id))
      .subscribe((muteEvent) => {
        this.isMuted = muteEvent.mute;
        this.mediaStreamService.isMuted = muteEvent.mute;
        this.sendDeviceStatuses();
      }));
  }

  protected initDisableCamSubscription(): void {
    this.subscriptions.push(this.stateService.subscriptions
      .onDisabledCam
      .pipe(filter((camDisabledEvent) => camDisabledEvent.userId === this.globals.user.id))
      .subscribe((camDisabledEvent) => {
        if (!this.mediaStreamService.isCameraNotAllowed) {
          this.isCameraDisabled = camDisabledEvent.disabled;
          this.mediaStreamService.isCameraDisabled = camDisabledEvent.disabled;
          this.sendDeviceStatuses();
        }
      }));
  }

  private initConnectedToStreamSubscription(): void {
    this.subscriptions.push(this.stateService.subscriptions.onConnectedToCreatedStream
      .pipe(filter((createdStreamInfo) =>
        createdStreamInfo.userId === this.globals.user.id || this.isMutedPopupDisplayed === null))
      .subscribe((createdStreamInfo) => {
        this.logger.debug("VideoPlayerControlsComponent.initConnectedToStreamSubscription: {}", createdStreamInfo);
        if (createdStreamInfo.userId === this.globals.user.id) {
          this.isConnectedToStream = createdStreamInfo.status;
          this.stateService.camStreamId = createdStreamInfo.streamId;
          this._camStreamId = createdStreamInfo.streamId;
          this.globals.currentStreamId = this._camStreamId;
          this.tryStartTranslation = false;

          if (this.mediaStreamService.isCameraNotAllowed) {
            this.isCameraDisabled = true;
            this.mediaStreamService.isCameraDisabled = true;
            this.sendDeviceStatuses();
          }
        } else if (this.isMutedPopupDisplayed === null) {
          this.isMutedPopupDisplayed = createdStreamInfo.status && !this.isSoundEnabled;
        }
      }));
  }

  private initConnectedToShareStreamSubscription(): void {
    this.subscriptions.push(this.stateService.subscriptions
      .onConnectedToCreatedShareStream
      .pipe(filter((createdStreamInfo) => createdStreamInfo.userId === this.globals.user.id))
      .subscribe((createdStreamInfo) => {
        //console.log("connectedToCreatedShareStream createdStreamInfo " + createdStreamInfo);
        this.isShareStreamStarted = createdStreamInfo.status;
        this._screenStreamId = createdStreamInfo.streamId;
        this.tryStartShareScreen = false;
      }));
  }

  private initSoundEnabledSubscription(): void {
    this.isSoundEnabled = this.globals.userRoomRole.isAdmin;
    this.mediaServerService.onSoundEnableSwitch
      .subscribe((soundEnabled) => {
        this.isSoundEnabled = soundEnabled;
      })
  }

  private initUsersOnlineSubscription(): void {
    this.subscriptions.push(this.userService.onUserOnline
      .subscribe(() => {
        this.sendDeviceStatuses();
      }));
  }

  private initConnectUserToStreamSubscription(): void {
    this.subscriptions.push(this.stateService.subscriptions.connectUserToStream.subscribe(() => {
      if (this.room.type == RoomType.GROUP_CALL) {
        this.startCamStream();
      }
    }));
  }

  protected initCamTimer(): void {
    if (!this.mediaStreamService.isCameraNotAllowed) {
      this.logger.info("TIME CAM INIT");
      if (this._timerCamSubscription) {
        this.logger.info("TIME CAM UNSUBSCRIBE");
        this._timerCamSubscription.unsubscribe();
        this._timerCamSubscription = null;
      }

      this._timerCamSubscription = timer(10000)
        .subscribe(() => {
          this.tryStartTranslation = false;
          this._timerCamSubscription.unsubscribe();
          this._timerCamSubscription = null;
          this.logger.info("TIME CAM UNSUBSCRIBE")
        });
    }
  }

  protected initShareTimer(): void {
    this.logger.info("TIME SHARE INIT");
    if (this._timerShareSubscription) {
      this.logger.info("TIME SHARE UNSUBSCRIBE");
      this._timerShareSubscription.unsubscribe();
      this._timerShareSubscription = null;
    }

    this._timerShareSubscription = timer(10000)
      .subscribe(() => {
        this.tryStartShareScreen = false;
        this._timerShareSubscription.unsubscribe();
        this._timerShareSubscription = null;
        this.logger.info("TIME SHARE UNSUBSCRIBE")
      });
  }

  public switchSound(): void {
    AudioContextHolder.audioContext;
    this.mediaServerService.switchSound(!this.isSoundEnabled);
    this.isMutedPopupDisplayed = false
  }

  public requestStopCamStream(): void {
  }

  ngOnDestroy(): void {
    if (this._timerCamSubscription) {
      this._timerCamSubscription.unsubscribe();
    }
    if (this._timerShareSubscription) {
      this._timerShareSubscription.unsubscribe();
    }
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
