export class AudioContextHolder {
  private static _audioContext: AudioContext;

  static get audioContext(): AudioContext {
    if (!this._audioContext || this._audioContext.state === "closed") {
      this._audioContext = new (window['AudioContext'] || window['webkitAudioContext'])();
    } else if (this._audioContext.state === "suspended") {
      this._audioContext.resume().then();
    }
    return this._audioContext;
  }

  static closeAudioContext(): void {
    if (this._audioContext && this._audioContext.state !== "closed") {
      this._audioContext.close().then();
    }
  }
}
