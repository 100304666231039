import {Injectable, OnDestroy} from "@angular/core";
import {Observable, Subject} from "rxjs";

@Injectable({ providedIn: 'root' })
export class RecordPlayerSubscriptionsService implements OnDestroy {

  private readonly _fromMainPlayer: Subject<FromMainPlayerMessage> = new Subject<FromMainPlayerMessage>();
  private readonly _fromControls: Subject<FromControlsMessage> = new Subject<FromControlsMessage>();

  sendPlayedMainPlayer() {
    this.fromMainPlayer.next(FromMainPlayerMessage.getPlayedMessage());
  }

  sendStoppedFromMainPlayer() {
    this.fromMainPlayer.next(FromMainPlayerMessage.getStoppedMessage());
  }

  sendProgressUpdatedFromMainPlayer(htmlVideoElement: HTMLVideoElement) {
    this.fromMainPlayer.next(FromMainPlayerMessage.getProgressUpdatedMessage(htmlVideoElement));
  }

  sendUpdateProgressFromControls(value: number) {
    this.fromControls.next(FromControlsMessage.getUpdateProgressMessage(value))
  }

  sendPlayFromControls() {
    this.fromControls.next(FromControlsMessage.getPlayMessage());
  }

  sendStopFromControls() {
    this.fromControls.next(FromControlsMessage.getStopMessage());
  }

  sendForwardVideoFromControls(seconds: number) {
    this.fromControls.next(FromControlsMessage.getForwardVideoMessage(seconds))
  }

  sendBackVideoFromControls(seconds: number) {
    this.fromControls.next(FromControlsMessage.getBackVideoMessage(seconds))
  }


  get fromMainPlayer(): Subject<FromMainPlayerMessage> {
    return this._fromMainPlayer;
  }

  get fromControls(): Subject<FromControlsMessage> {
    return this._fromControls;
  }

  get fromMainPlayerObs(): Observable<FromMainPlayerMessage> {
    return this._fromMainPlayer.asObservable();
  }

  get fromControlsObs(): Observable<FromControlsMessage> {
    return this._fromControls.asObservable();
  }

  ngOnDestroy(): void {
  }

}

export enum FromControlsType {
  PLAY,
  STOP,
  BACK_VIDEO,
  FORWARD_VIDEO,
  UPDATE_PROGRESS
}

export enum FromMainPlayerType {
  STOPPED,
  PLAYED,
  PROGRESS_UPDATED
}

export class FromMainPlayerMessage {
  static getPlayedMessage(): FromMainPlayerMessage {
    let fromMainPlayerMessage = new FromMainPlayerMessage();
    fromMainPlayerMessage.type = FromMainPlayerType.PLAYED;
    return fromMainPlayerMessage;
  }

  static getStoppedMessage(): FromMainPlayerMessage {
    let fromMainPlayerMessage = new FromMainPlayerMessage();
    fromMainPlayerMessage.type = FromMainPlayerType.STOPPED;
    return fromMainPlayerMessage;
  }

  static getProgressUpdatedMessage(htmlVideoElement: HTMLVideoElement): FromMainPlayerMessage {
    let fromMainPlayerMessage = new FromMainPlayerMessage();
    fromMainPlayerMessage.type = FromMainPlayerType.PROGRESS_UPDATED;
    fromMainPlayerMessage.value = htmlVideoElement;
    return fromMainPlayerMessage;
  }

  type: FromMainPlayerType;
  value: any;
}

export class FromControlsMessage {

  static getForwardVideoMessage(seconds: number): FromControlsMessage {
    let fromControlsMessage = new FromControlsMessage();
    fromControlsMessage.type = FromControlsType.FORWARD_VIDEO;
    fromControlsMessage.value = seconds;
    return fromControlsMessage;
  }
  static getBackVideoMessage(seconds: number): FromControlsMessage {
    let fromControlsMessage = new FromControlsMessage();
    fromControlsMessage.type = FromControlsType.BACK_VIDEO;
    fromControlsMessage.value = seconds;
    return fromControlsMessage;
  }

  static getUpdateProgressMessage(percent: number): FromControlsMessage {
    let fromControlsMessage = new FromControlsMessage();
    fromControlsMessage.type = FromControlsType.UPDATE_PROGRESS;
    fromControlsMessage.value = percent;
    return fromControlsMessage;
  }

  static getPlayMessage(): FromControlsMessage {
    let fromControlsMessage = new FromControlsMessage();
    fromControlsMessage.type = FromControlsType.PLAY;
    return fromControlsMessage;
  }

  static getStopMessage(): FromControlsMessage {
    let fromControlsMessage = new FromControlsMessage();
    fromControlsMessage.type = FromControlsType.STOP;
    return fromControlsMessage;
  }

  type: FromControlsType;
  value: any;
}
