import {Component, OnInit} from '@angular/core';
import {Globals} from "../../globals";

@Component({
  selector: 'app-change-admin-role-modal',
  templateUrl: './change-admin-role-modal.component.html',
  styleUrls: ['./change-admin-role-modal.component.css']
})
export class ChangeAdminRoleModalComponent implements OnInit {

  constructor(public globals: Globals) {
  }

  ngOnInit(): void {
  }
}
