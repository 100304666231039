import {Observable, Subject} from "rxjs";
import {environment} from "../environments/environment";
import ReconnectingWebSocket from 'reconnectingwebsocketflc';
import {WsInputTypeEnum} from "../model/enum/ws-input-type.enum";
import {WsMessageEvent} from "../model/event/ws-data-event.model";
import * as uuid from 'uuid';
import {WsRtcModel} from "../model/ws-rtc.model";
import {InputsNewEvent} from "../model/event/media-server/inputs-new.event";
import {BaseWsEvent} from "../model/event/media-server/base-ws.event";
import {OutputsNewEvent} from "../model/event/media-server/outputs-new.event";
import {IoCloseEvent} from "../model/event/media-server/io-close.event";
import {IceCandidateEvent} from "../model/event/media-server/ice-candidate.event";
import {NGXLogger} from "ngx-logger";

export class WsWrapper {
  private readonly mediaServerUrl = environment.mediaServerUrl;
  private readonly token = '1E166EA5771543C31352842465A8C493A655620148B81DDDAB57FF70A544DDEB90E3AA3F7E2F6AE4652184C8B72A75DA';
  private readonly wsRoomUrl = this.mediaServerUrl + 'ws/v1/room/';

  private readonly _id: string;
  private ws: ReconnectingWebSocket;
  private readonly _messageSubject: Subject<WsMessageEvent>;
  private readonly _openSubject: Subject<Event>;
  private readonly _closeSubject: Subject<CloseEvent>;
  private readonly _errorSubject: Subject<Event>;

  private manuallyClosed = false;

  constructor(private logger: NGXLogger,
              roomLink: string) {
    this._id = uuid.v4();
    this._messageSubject = new Subject<WsMessageEvent>();
    this._openSubject = new Subject<Event>();
    this._errorSubject = new Subject<Event>();
    this._closeSubject = new Subject<CloseEvent>();
    this.createWsInternal(roomLink);
  }

  get id(): string {
    return this._id;
  }

  get isActive(): boolean {
    return this.ws?.readyState === WebSocket.CONNECTING || this.ws?.readyState === WebSocket.OPEN;
  }

  public state(): number {
    return this.ws.readyState;
  }

  public onOpen(): Observable<Event> {
    return this._openSubject.asObservable();
  }

  public onMessage(): Observable<WsMessageEvent> {
    return this._messageSubject.asObservable();
  }

  public onClose(): Observable<CloseEvent> {
    return this._closeSubject.asObservable();
  }

  public onError(): Observable<Event> {
    return this._errorSubject.asObservable();
  }

  public close(): void {
    this.manuallyClosed = true;
    this.ws.close();
    this._messageSubject.complete();
    this._closeSubject.complete();
    this._errorSubject.complete();
    this._openSubject.complete();
  }

  public open(): void {
    this.manuallyClosed = false;
    this.ws.open();
  }

  /**
   *
   * @param inputId: input or output id to close
   */

  public sendIoCloseCommand(inputId: string): void {
    this.logger.info("Send IO_CLOSE event for: {}", inputId);
    this.send(new IoCloseEvent(inputId));
  }

  /**
   *
   * @param inputId: input stream id to connect
   * @param outputId: output stream id for stream identification
   * @param sdp: sdp offer description
   */

  public sendOutputNewCommand(inputId: string, outputId: string, sdp: string): void {
    this.send(new OutputsNewEvent(outputId, inputId, sdp));
  }

  /**
   *
   * @param streamId: input or output stream id
   * @param event: ice candidate event
   */

  public sendIceCandidateCommand(streamId: string, event: RTCPeerConnectionIceEvent): void {
    if (event.candidate && event.candidate.candidate && event.candidate.candidate.length > 0) {
      this.send(new IceCandidateEvent(streamId, event.candidate.candidate));
    }
  }

  public sendInputNewCommand(model: WsRtcModel, userEmail: string, browser: string): void {
    const inputsNewEvent = new InputsNewEvent(model.inputId, model.pc.localDescription.sdp, WsInputTypeEnum.WebRTC, model.contentId);
    inputsNewEvent.addMeta(new Date(), userEmail, browser);

    let audio = model.streamContainer.stream.getAudioTracks().length > 0;
    let video = model.streamContainer.stream.getVideoTracks().length > 0;
    inputsNewEvent.addFlags(audio, video);

    if (video) {
      let mediaTrackSettings = model.streamContainer.stream.getVideoTracks()[0].getSettings();
      inputsNewEvent.addVideo(mediaTrackSettings.width, mediaTrackSettings.height);
    }
    this.logger.info("Send inputs:new event: inputId: {}", inputsNewEvent.header.id);
    this.send(inputsNewEvent);
  }

  private send(event: BaseWsEvent): void {
    if (this.ws.readyState === 1) {
      // console.log(event);
      this.ws.send(JSON.stringify(event));
    } else {
      throw 'INVALID_STATE_ERR : WebSocket is already closed. failed message: ' + JSON.stringify(event);
    }
  }

  private createWsInternal(roomLink: string): void {
    let serverRoomLink = `${this.wsRoomUrl + roomLink}?token=${this.token}`;

    this.logger.info('Try to connect {}', serverRoomLink);
    this.ws = new ReconnectingWebSocket(serverRoomLink, null, {
      automaticOpen: false,
      timeoutInterval: 10000
    });

    this.ws.addEventListener('message', (message) => {
      // console.log("message: ", message);
      let parsedMessage = new WsMessageEvent(message.data);
      this._messageSubject.next(parsedMessage)
    });

    this.ws.addEventListener('close', (event) => {
      this.logger.info('Ws connection closed: {}. Event: {}. Manually Closed: {}',
        this.wsRoomUrl + roomLink, event, this.manuallyClosed);
      if (this.manuallyClosed) {
        this._closeSubject.next(event);
      }
    });

    this.ws.addEventListener('open', (event) => {
      this.logger.info("Connection open successfully: {}", this.wsRoomUrl + roomLink);
      this._openSubject.next(event)
    });

    this.ws.addEventListener('error', (event) => {
      this.logger.error("Receive error event", event);
      this.logger.info("Ws state: {}", this.ws.readyState);

      // if the error is occurred we shouldn't stop translation, web socked will be re-connected
      // this._errorSubject.next(event);
    });
  }
}
