export class Body {
  //sdp:answer
  readonly sdp: string;

  //inputs:list, inputs:offline, inputs:online
  readonly inputs: Input[];

  //server:error
  readonly error: Error;
  readonly errorText?: string;


  constructor(jsonBody: object) {
    if (jsonBody) {
      if (jsonBody['sdp']) {
        this.sdp = jsonBody['sdp'];
      }

      if (jsonBody['error_text']) {
        this.errorText = jsonBody['error_text'];
      }

      if (jsonBody['inputs']) {
        this.inputs = jsonBody['inputs'].map((input) => new Input(input.id))
      }

      if (jsonBody['error']) {
        this.error = new Error(jsonBody['error']);
      }
    }
  }
}

export class Input {
  readonly id: string;

  constructor(id: string) {
    this.id = id;
  }
}

export class Error {
  errCode: string;
  ctx: Ctx;
  errorText: string;

  constructor(jsonError: object) {
    if (jsonError['e']) {
      this.errCode = jsonError['e']['err'];
      this.errorText = jsonError['e']['text'];
    }

    if (jsonError['ctx']) {
      this.ctx = new Ctx(jsonError['ctx'])
    }
  }
}

export class Ctx {
  ioId: string;
  roomId: string;
  roomName: string;

  constructor(jsonCtx: object) {
    this.ioId = jsonCtx['io-id'];
    this.roomId = jsonCtx['room-id'];
    this.roomName = jsonCtx['room-name'];
  }
}
