import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-logout-modal',
  templateUrl: './logout-modal.component.html',
  styleUrls: ['./logout-modal.component.css']
})
export class LogoutModalComponent implements OnInit {
  constructor() {
  }

  ngOnInit(): void {
  }

  logout(): void {

  }
}
