import {AfterViewInit, Component, EventEmitter, Input, OnInit} from '@angular/core';
import {RoleTypeEnum} from "../../../../model/enum/role-type.enum";
import {UtilsService} from "../../../../services/utils.service";
import {Globals} from "../../../globals";
import {Role} from "../../../../model/role.model";
import {StateService} from "../../../../services/state.service";
import {Room} from "../../../../model/room.model";
import {UserService} from "../../../../services/user.service";
import {filter} from "rxjs/operators";

@Component({
  selector: '[app-room-context-menu]',
  templateUrl: './room-context-menu.component.html',
  styleUrls: ['./room-context-menu.component.css']
})
export class RoomContextMenuComponent implements AfterViewInit {
  @Input() userId: number;
  @Input() userName: string;
  @Input() userRole: Role;
  @Input() room: Room;

  private _isMemberOnline: boolean;

  constructor(public globals: Globals,
              public userService: UserService,
              public subscriptionService: StateService) {
  }

  ngAfterViewInit(): void {
    UtilsService.updateContextTriggerOnClick();

    let member = this.userService.members.find((member) => member.userId === this.userId);
    this._isMemberOnline = member?.isOnline;
    this.userService.onUserOnline
      .pipe(filter((userOnlineEvent) => userOnlineEvent.userId === this.userId))
      .subscribe((userOnlineEvent) => {
        this._isMemberOnline = userOnlineEvent.online;
      });
  }

  connectionInfo(): void {
    UtilsService.showFancyBox('#modal-function-not-implemented');
  }

  privateMessage(): void {
    UtilsService.showFancyBox('#modal-function-not-implemented');
  }

  isAllowChangeRoleToSpeaker(): boolean {
    return this.userRole.isChangeRoleToAdminAllowed
      && (!this.subscriptionService.isConnectedToStream || this._isMemberOnline);
  }

  changeRoleToUser(): void {
    this.subscriptionService.requests
      .sendChangeRoleRequest(this.userId, RoleTypeEnum.ROLE_USER, this.globals.userRoomRole.role);
  }

  changeRoleToAdmin(): void {
    this.subscriptionService.requests
      .sendChangeRoleRequest(this.userId, RoleTypeEnum.ROLE_ADMIN, this.globals.userRoomRole.role);
  }

  changeRoleToModerator(): void {
    this.subscriptionService.requests
      .sendChangeRoleRequest(this.userId, RoleTypeEnum.ROLE_MODERATOR, this.globals.userRoomRole.role);
  }

  hasAccessToChangeRole(): boolean {
    if (this.userId === this.globals.user.id) {
      return this.globals.userRoomRole.isModerator;
    }
    if (this.userRole?.isAdmin) {
      return false;
    }
    return this.globals.userRoomRole.isAdmin || this.globals.userRoomRole.isModerator;
  }

  changeRoleToBlocked(): void {
    const blockEvent = new EventEmitter<void>();
    blockEvent.subscribe(() => {
      this.subscriptionService.requests
        .sendChangeRoleRequest(this.userId, RoleTypeEnum.ROLE_BLOCKED, this.globals.userRoomRole.role);
    });
    this.globals.userBlockCallback = blockEvent;
    this.globals.userNameForBlock = this.userName;
    UtilsService.showFancyBox('#user-block-chat')
  }

  changeRoleToDeleted(): void {
    const blockEvent = new EventEmitter<void>();
    blockEvent.subscribe(() => {
      this.subscriptionService.requests
        .sendChangeRoleRequest(this.userId, RoleTypeEnum.ROLE_DELETED, this.globals.userRoomRole.role);
    });
    this.globals.userBlockCallback = blockEvent;
    this.globals.userNameForBlock = this.userName;
    UtilsService.showFancyBox('#user-block')
  }
}
