export class ReadyForCallEvent {
  private readonly _userId: number;
  private readonly _isReady: boolean;


  constructor(userId: number, isReady: boolean) {
    this._userId = userId;
    this._isReady = isReady;
  }


  get userId(): number {
    return this._userId;
  }

  get isReady(): boolean {
    return this._isReady;
  }
}
