import {WsRtcModel} from './ws-rtc.model';
import {StreamTypeEnum} from "./enum/stream-type-enum";
import {WsRtcModelStateEnum} from "./enum/ws-rtc-model-state.enum";

export class WsRtcModelList {
  private _list: WsRtcModel[];

  constructor() {
    this._list = [];
  }


  get list(): WsRtcModel[] {
    return this._list;
  }

  clear(): WsRtcModel[] {
    let clone = [...this._list]
    this._list = [];
    return clone;
  }

  getAllInputIds(): string[] {
    return this._list
      .map((wsRtcModel) => wsRtcModel.inputId);
  }

  findActualOutputInputIds(): string[] {
    let actualOutputInputIds = this._list
      .filter((wsRtcModel, i) => wsRtcModel.isOutput && wsRtcModel.isActual)
      .map((wsRtcModel) => wsRtcModel.inputId);
    return actualOutputInputIds
      .filter((inputId, i) => actualOutputInputIds.indexOf(inputId) === i);
  }

  push(wsRtcModel: WsRtcModel): number {
    return this._list.push(wsRtcModel);
  }

  setInputModelStateByInputId(inputId: string, wsRtcModelState: WsRtcModelStateEnum): void {
    for (let i = 0; i < this._list.length; i++) {
      let item = this._list[i];
      if (item.inputId === inputId && item.isInput) {
        item.state = wsRtcModelState;
      }
    }
  }
  removeByInputIdIfExists(inputId: string): WsRtcModel[] {
    let removedModels = [];
    for (let i = 0; i < this._list.length; i++) {
      if (this._list[i].inputId === inputId) {
        removedModels.push(this._list[i]);
        this._list.splice(i, 1);
        i--;
      }
    }

    return removedModels;
  }

  removeModelByIdIfExists(id: string) {
    this._list = this._list.filter(m => m.id !== id);
  }


  findAllOutputModels(): WsRtcModel[] {
    let found = [];
    for (let i = 0; i < this._list.length; i++) {
      let item = this._list[i];
      if (item.isOutput) {
        found.push(item);
      }
    }

    return found;
  }

  findInputModelsByStreamUserIdAndNotScreen(streamUserId: number): WsRtcModel[] {
    let found = [];
    for (let i = 0; i < this._list.length; i++) {
      let item = this._list[i];
      if (item.streamUserId === streamUserId && item.isInput && !item.isScreenShare) {
        found.push(item);
      }
    }

    return found;
  }

  findModelsByStreamId(streamId: number): WsRtcModel[] {
    let found = [];
    for (let i = 0; i < this._list.length; i++) {
      let item = this._list[i];
      if (item.streamId === streamId) {
        found.push(item);
      }
    }

    return found;
  }

  findInputModelsByStreamId(streamId: number): WsRtcModel[] {
    let found = [];
    for (let i = 0; i < this._list.length; i++) {
      let item = this._list[i];
      if (item.streamId === streamId && item.isInput) {
        found.push(item);
      }
    }

    return found;
  }

  findInputModelsByStreamUserId(streamUserId: number): WsRtcModel[] {
    let found = [];
    for (let i = 0; i < this._list.length; i++) {
      let item = this._list[i];
      if (item.streamUserId === streamUserId && item.isInput) {
        found.push(item);
      }
    }

    return found;
  }

  findInputModelsByStreamType(streamTypeEnum: StreamTypeEnum): WsRtcModel[] {
    let found = [];
    for (let i = 0; i < this._list.length; i++) {
      let item = this._list[i];
      if (item.isInput && item.inputId.startsWith(streamTypeEnum)) {
        found.push(item);
      }
    }

    return found;
  }

  findOutputModelsByStreamType(streamTypeEnum: StreamTypeEnum): WsRtcModel[] {
    let found = [];
    for (let i = 0; i < this._list.length; i++) {
      let item = this._list[i];
      if (item.isOutput && item.streamType === streamTypeEnum) {
        found.push(item);
      }
    }

    return found;
  }

  findOutputModelByStreamUserId(streamUserId: number): WsRtcModel {
    for (let i = 0; i < this._list.length; i++) {
      let item = this._list[i];
      if (item.streamUserId === streamUserId && item.isOutput) {
        return item;
      }
    }

    return null;
  }

  findConnectedOutputModelsByStreamUserId(streamUserId: number): WsRtcModel {
    for (let i = 0; i < this._list.length; i++) {
      let item = this._list[i];
      if (item.isOutput && streamUserId === item.streamUserId && item.state === WsRtcModelStateEnum.CONNECTED) {
        return item;
      }
    }

    return null;
  }

  findInputModelByInputId(inputId: string): WsRtcModel {
    for (let i = 0; i < this._list.length; i++) {
      let item = this._list[i];
      if (item.inputId === inputId && item.isInput) {
        return item;
      }
    }

    return null;
  }

  findModelByInputIdOrOutputId(streamId: string): WsRtcModel {
    for (let i = 0; i < this._list.length; i++) {
      let item = this._list[i];
      if (item.inputId === streamId || item.outputId === streamId) {
        return item;
      }
    }

    return null;
  }

  existsByStreamUserIds(streamUserIds: number[]): boolean {
    for (let i = 0; i < this._list.length; i++) {
      let item = this._list[i];
      if (streamUserIds.indexOf(item.streamUserId) > -1) {
        return true;
      }
    }

    return false;
  }

  existsConnectedOutputModelByStreamUserId(streamUserId: number): boolean {
    for (let i = 0; i < this._list.length; i++) {
      let item = this._list[i];
      if (item.streamUserId === streamUserId && item.isOutput && item.state === WsRtcModelStateEnum.CONNECTED) {
        return true;
      }
    }

    return false;
  }

  findConnectedToStreamUserIdOutputModelsByOutputStreamUserId(streamUserId: number, outputStreamUserId: number): number {
    for (let i = 0; i < this._list.length; i++) {
      let item = this._list[i];
      if (item.streamUserId === streamUserId && item.outputUserId === outputStreamUserId && item.state === WsRtcModelStateEnum.CONNECTED) {
        return item.streamId;
      }
    }

    return null;
  }

  existsConnectedToStreamUserIdOutputModelsByOutputStreamUserId(streamUserId: number, outputStreamUserId: number): boolean {
    for (let i = 0; i < this._list.length; i++) {
      let item = this._list[i];
      if (item.streamUserId === streamUserId && item.outputUserId === outputStreamUserId && item.state !== WsRtcModelStateEnum.TO_REMOVE) {
        return true;
      }
    }

    return false;
  }
}
