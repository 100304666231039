import {Component, OnDestroy, OnInit} from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {AuthService} from "../../../services/auth.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Globals} from "../../globals";
import {StringUtils} from "../../../utils/string-utils";
import {Subscription} from "rxjs";
import {NGXLogger} from "ngx-logger";

@Component({
  selector: 'app-guest-join-to-room-page',
  templateUrl: './guest-join-to-room-page.component.html',
  styleUrls: ['./guest-join-to-room-page.component.css']
})
export class GuestJoinToRoomPageComponent implements OnInit, OnDestroy {
  private readonly apiUrl = environment.apiUrl;
  private paramsSubscription: Subscription;
  private roomId: string;

  name: string;
  nameError: boolean;
  errorText: string;
  password: string;
  passwordError: boolean;
  passwordRequired: boolean;

  constructor(public globals: Globals,
              public authService: AuthService,
              private router: Router,
              public route: ActivatedRoute,
              private http: HttpClient,
              private logger: NGXLogger) {
  }

  ngOnInit(): void {
    this.paramsSubscription = this.route.params.subscribe((params) => {
      this.roomId = params['id'];
      this.passwordRequired = !!this.roomId;
    });
  }

  ngOnDestroy(): void {
    this.paramsSubscription.unsubscribe();
  }

  clearErrors(): void {
    this.nameError = false;
    this.passwordError = false;
    this.errorText = null;
  }

  login(): void {
    this.clearErrors();
    if (StringUtils.isEmpty(this.name)) {
      this.nameError = true;
      this.errorText = 'fields-errors.enter-guest-name';
    } else if (this.passwordRequired && StringUtils.isEmpty(this.password)) {
      this.passwordError = true;
      this.errorText = 'fields-errors.enter-room-password';
    } else {
      let formData: any = new FormData();
      formData.append("name", this.name);
      if (this.passwordRequired) {
        formData.append("roomId", this.roomId);
        formData.append("password", this.password);
      }

      this.http.post(this.apiUrl + 'login/guest', formData, { responseType: 'text' }).subscribe(
        (response) => {
          this.auth(response);
        },
        (error: HttpErrorResponse) => {
          if (error.status === 403) {
            this.errorText = 'fields-errors.password-incorrect';
          } else if (error.status === 429) {
            this.errorText = 'fields-errors.5-min-lock';
          } else if (error.status === 423) {
            this.errorText = 'fields-errors.1-hour-lock';
          } else {
            this.errorText = 'fields-errors.server-error';
            this.logger.error("Login failed: ", error);
          }
        }
      )
    }
  }

  private auth(response: string): void {
    this.authService.authenticate(() => {
      if (response) {
        this.router.navigateByUrl(response);
      } else {
        this.router.navigateByUrl("/");
      }
    });
  }
}
