import {Component, Input, OnInit} from '@angular/core';
import {ChatMessage} from "../../../model/message/chat.message";
import {Globals} from "../../globals";
import {UserService} from "../../../services/user.service";
import {StringUtils} from "../../../utils/string-utils";
import {ChatAttachment} from "../../../model/chatAttachment";
import {Room} from "../../../model/room.model";

@Component({
  selector: '[app-chat-message]',
  templateUrl: './chat-message.component.html',
  styleUrls: ['./chat-message.component.css']
})
export class ChatMessageComponent implements OnInit {
  @Input() room: Room;

  avatarSymbols: string;
  avatarBackground: string;
  private _message: ChatMessage;

  constructor(public globals: Globals,
              public userService: UserService) {
    this.initRoleChangeSubscription();
  }

  ngOnInit(): void {
  }

  get message(): ChatMessage {
    return this._message;
  }

  @Input()
  set message(value: ChatMessage) {
    this._message = value;
    this.avatarSymbols = StringUtils.getFirstSymbols(value.userName);
    this.avatarBackground = StringUtils.getBackgroundColor(value.userName);
  }

  addLinks(text): string {
    // simple url regex
    // const urlRegex =/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
    // hardcore :)
    const urlRegex = /((?:(http|https|Http|Https|rtsp|Rtsp):\/\/(?:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,64}(?:\:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,25})?\@)?)?((?:(?:[a-zA-Z0-9][a-zA-Z0-9\-]{0,64}\.)+(?:(?:aero|arpa|asia|a[cdefgilmnoqrstuwxz])|(?:biz|b[abdefghijmnorstvwyz])|(?:cat|com|coop|c[acdfghiklmnoruvxyz])|d[ejkmoz]|(?:edu|e[cegrstu])|f[ijkmor]|(?:gov|g[abdefghilmnpqrstuwy])|h[kmnrtu]|(?:info|int|i[delmnoqrst])|(?:jobs|j[emop])|k[eghimnrwyz]|l[abcikrstuvy]|(?:mil|mobi|museum|m[acdghklmnopqrstuvwxyz])|(?:name|net|n[acefgilopruz])|(?:org|om|online)|(?:pro|p[aefghklmnrstwy])|qa|r[eouw]|s[abcdeghijklmnortuvyz]|(?:tel|travel|t[cdfghjklmnoprtvwz])|u[agkmsyz]|v[aceginu]|w[fs]|y[etu]|z[amw]))|(?:(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9])\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[0-9])))(?:\:\d{1,5})?)(\/(?:(?:[a-zA-Z0-9\;\/\?\:\@\&\=\#\~\-\.\+\!\*\'\(\)\,\_])|(?:\%[a-fA-F0-9]{2}))*)?(?:\b|$)/gi;
    return text.replace(urlRegex, url => {
      return '<a target="_blank" href="' + (!url.startsWith('http') ? 'http://' + url : url) + '">' + url + '</a>';
    });
  }

  getFileSize(attachment: ChatAttachment): string {
    return StringUtils.convertToFileSize(attachment.fileSize);
  }

  downloadAttachment(attachment: ChatAttachment): void {
    if (!attachment.deleted) {
      const link = document.createElement('a');
      link.setAttribute('target', '_blank');
      link.setAttribute('href', attachment.fileUrl);
      link.setAttribute('download', attachment.fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  }

  private initRoleChangeSubscription(): void {
    this.userService.onRoomRoleUpdate
      .subscribe((changeRoleEvent) => {
        if (this._message?.userId === changeRoleEvent.userId) {
          this._message.roomRole = changeRoleEvent.newRole;
        }
      })
  }

}
