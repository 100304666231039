export class UserOnlineEvent {
  private readonly _userId: number;
  private readonly _online: boolean;

  constructor(userId: number, online: boolean) {
    this._userId = userId;
    this._online = online;
  }

  get userId(): number {
    return this._userId;
  }

  get online(): boolean {
    return this._online;
  }
}
