import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {environment} from '../environments/environment';
import {Observable} from 'rxjs';
import {share} from 'rxjs/operators';
import {User} from '../model/user.model';
import {Globals} from "../app/globals";
import {NGXLogger} from "ngx-logger";

@Injectable({ providedIn: 'root' })
export class AuthService {
  static readonly WS_PATH = "chat";
  private readonly baseUrl = environment.apiUrl;

  constructor(private globals: Globals,
              private http: HttpClient,
              private logger: NGXLogger) {
  }

  getAuthUser(): Observable<User> {
    return this.http.get<User>(this.baseUrl + 'login/user', {
      withCredentials: true
    }).pipe(share());
  }

  authenticate(callback?): void {
    this.getAuthUser().subscribe((user: User) => {
        if (user) {
          this.globals.user = user;
        }
        this.globals.authenticated = !!user;
        if (callback) {
          callback();
        }
      }, (error: HttpErrorResponse) => {
        this.globals.authenticated = false;
        if (callback) {
          callback();
        }
      }
    );
  }
}
