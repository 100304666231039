export class CreatedStreamInfoEvent {
  private readonly _userId: number;
  private readonly _status: boolean;
  private readonly _streamId: number;
  private readonly _shareScreen: boolean;

  /**
   *
   * @param userId: stream user id
   * @param streamId: stream id
   * @param isShareScreen: OPTIONAL is stream share screen stream. Default: false
   * @param status: OPTIONAL stream status online or offline. Default: true
   */

  constructor(userId: number, streamId: number, status: boolean = true, isShareScreen: boolean = false) {
    this._userId = userId;
    this._streamId = streamId;
    this._status = status;
    this._shareScreen = isShareScreen;
  }


  get isShareScreen(): boolean {
    return this._shareScreen;
  }

  get userId(): number {
    return this._userId;
  }

  get status(): boolean {
    return this._status;
  }


  get streamId(): number {
    return this._streamId;
  }
}
