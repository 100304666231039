import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Globals} from "../../globals";
import {UserService} from "../../../services/user.service";
import {RoomService} from "../../../services/room.service";
import {UtilsService} from "../../../services/utils.service";
import {StringUtils} from "../../../utils/string-utils";

@Component({
  selector: '[app-header]',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  @Input() title: string;
  @Input() inviteLink: string;
  @Input() invitePass: string;
  @Output() openSettings = new EventEmitter<boolean>();

  avatarBackground: string;

  constructor(public globals: Globals,
              public userService: UserService,
              public roomService: RoomService) {
  }

  ngOnInit(): void {
  }

  inviteUser(): void {
    this.globals.inviteLink = this.inviteLink;
    this.globals.invitePass = this.invitePass;
  }

  onOpenSettings(): void {
    this.openSettings.emit(true);
  }

  onLogout(): void {
    UtilsService.showFancyBox('#logout');
  }

  notImplemented(): void {
    UtilsService.showFancyBox('#modal-function-not-implemented');
  }

  getAvatarSymbols(name: string): string {
    this.avatarBackground = StringUtils.getBackgroundColor(name);

    return StringUtils.getFirstSymbols(name);
  }
}
