import {BrowserModule} from '@angular/platform-browser';
import {ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';

import {TranslateLoader, TranslateModule, TranslateParser} from '@ngx-translate/core';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {CookieService} from 'ngx-cookie-service';
import {TranslateICUParser} from 'ngx-translate-parser-plural-select';
import {DatePipe} from '@angular/common';
import {NgxMaskModule} from 'ngx-mask';
import {ClickOutsideModule} from 'ng-click-outside';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {Globals} from './globals';

import {ErrorsPageComponent} from './pages/errors-page/errors-page.component';
import {MainPageComponent} from './pages/main-page/main-page.component';
import {LoginPageComponent} from './pages/login-page/login-page.component';
import {CreateNewRoomPageComponent} from './pages/create-new-room-hall-page/create-new-room-page.component';
import {CreateNewHallPageComponent} from './pages/create-new-room-hall-page/create-new-hall-page.component';
import {CheckDevicesPageComponent} from './pages/check-devices-page/check-devices-page.component';
import {RoomPageComponent} from '../pages/room-page/room-page.component';
import {RoomUserInviteBlockComponent} from './components/room-components/room-user-invite-block/room-user-invite-block.component';
import {RoomLinkPageComponent} from './pages/room-link-page/room-link-page.component';
import {RoomAccessDeniedPageComponent} from './pages/room-access-denied-page/room-access-denied-page.component';

import {OnlyNumberDirective} from '../directives/only-number.directive';
import {InputRegExpDirective} from '../directives/input-regexp.directive';
import {VideoPreviewBlockDirective} from '../directives/video-preview-block.directive';
import {HallPageComponent} from '../pages/hall-page/hall-page.component';
import {HallSidebarBlockComponent} from '../pages/hall-page/hall-sidebar-block/hall-sidebar-block.component';
import {HallRoomListEmptyBlockComponent} from '../pages/hall-page/hall-room-list-block/hall-room-list-empty-block.component';
import {HallRoomListBlockComponent} from '../pages/hall-page/hall-room-list-block/hall-room-list-block.component';
import {CreateHallRoomPageComponent} from '../pages/hall-page/create-room-page/create-hall-room-page.component';
import {RoomNamePipe} from '../pipe/room-name.pipe';
import {RoomPlayerTimeToStartComponentComponent} from './components/video-player-view/video-player-time-to-start/room-player-time-to-start-component.component';
import {RoomSettingsBlockComponent} from './components/room-components/room-settings-block/room-settings-block.component';
import {UnsupportedBrowserComponent} from './pages/unsupported-browser/unsupported-browser.component';
import {DeviceDetectorModule} from 'ngx-device-detector';
import {RequestPasswordPageComponent} from './pages/request-password-page/request-password-page.component';
import {SetNewPasswordPageComponent} from './pages/set-new-password-page/set-new-password-page.component';
import {HeaderComponent} from './components/header/header.component';
import {LogoutModalComponent} from './modals/logout-modal/logout-modal.component';
import {FunctionNotImplementedModalComponent} from './modals/function-not-implemented-modal/function-not-implemented-modal.component';
import {DevicesPermissionsNotAllowedModalComponent} from './modals/devices-permissions-not-allowed-modal/devices-permissions-not-allowed-modal.component';
import {DevicesCameraNotAllowedModalComponent} from './modals/devices-camera-not-allowed-modal/devices-camera-not-allowed-modal.component';
import {DevicesCommonErrorModalComponent} from './modals/devices-common-error-modal/devices-common-error-modal.component';
import {InviteUserModalComponent} from './modals/invite-user-modal/invite-user-modal.component';
import {BlockChatModalComponent} from './modals/block-chat-modal/block-chat-modal.component';
import {BlockAccessModalComponent} from './modals/block-access-modal/block-access-modal.component';
import {GuestJoinToRoomPageComponent} from './pages/guest-join-to-room-page/guest-join-to-room-page.component';
import {DatepickerComponent} from './components/datepicker/datepicker.component';
import {StopTranslationModalComponent} from './modals/stop-translation-modal/stop-translation-modal.component';
import {RoomPlayerRecordBlockComponent} from '../pages/room-page/video-player-block/common/record-block/room-player-record-block.component';
import {VideoPlayerGridComponent} from './components/video-player-view/video-player-grid/video-player-grid.component';
import {VideoPlayerGridItemComponent} from './components/video-player-view/video-player-grid/video-player-grid-item/video-player-grid-item.component';
import {VideoPlayerAdminBlockComponent} from './components/video-player-view/video-player-block/video-player-admin-block.component';
import {VideoPlayerUserBlockComponent} from './components/video-player-view/video-player-block/video-player-user-block.component';
import {VideoPlayerAdminControlsComponent} from './components/video-player-view/video-player-controls/video-player-admin-controls.component';
import {VideoPlayerUserControlsComponent} from './components/video-player-view/video-player-controls/video-player-user-controls.component';
import {ChatMessageComponent} from './components/chat-message/chat-message.component';
import {RoomContextMenuComponent} from './components/room-components/room-context-menu/room-context-menu.component';
import {RoomChatTabComponent} from './components/room-components/room-chat-tab/room-chat-tab.component';
import {RoomChatControlsComponent} from './components/room-components/room-chat-controls/room-chat-controls.component';
import {RoomAttachmentsTabComponent} from './components/room-components/room-attachments-tab/room-attachments-tab.component';
import {RoomAttachmentComponent} from './components/room-components/room-attachment/room-attachment.component';
import {ChangeAdminRoleModalComponent} from "./modals/change-admin-role-modal/change-admin-role-modal.component";
import {AirbrakeErrorHandler} from "./handlers/airbrake-error.handler";
import {VideoPlayerRecordGridComponent} from './components/video-player-view/video-player-record-grid/video-player-record-grid.component';
import {RoomPlayerRecordControlsBlockComponent} from "./components/video-player-view/room-player-record-controls-block/room-player-record-controls-block.component";
import {VideoPlayerRecordBlockComponent} from "./components/video-player-view/video-player-record-block/video-player-record-block.component";
import {VideoPlayerRecordTemplateBlockComponent} from "./components/video-player-view/video-player-record-template-block/video-player-record-template-block.component";
import {RecordGridItemComponent} from './components/video-player-view/video-player-record-grid/record-grid-item/record-grid-item.component';
import {AngularResizedEventModule} from "angular-resize-event";
import { RoomPasswordPageComponent } from './pages/room-password-page/room-password-page.component';
import { VideoPlayerSideControlsComponent } from './components/video-player-view/video-player-side-controls/video-player-side-controls.component';
import { RoomUsersTabComponent } from './components/room-components/room-users-tab/room-users-tab.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { UserBlockComponent } from './components/room-components/room-users-tab/user-block/user-block.component';
import {LoggerModule, NGXLogger, NgxLoggerLevel} from "ngx-logger";
import {environment} from "../environments/environment";
import { RoomQuestionsTabComponent } from './components/room-components/room-questions-tab/room-questions-tab.component';

@NgModule({
  declarations: [
    AppComponent,
    ErrorsPageComponent,
    MainPageComponent,
    LoginPageComponent,
    CreateNewRoomPageComponent,
    CreateNewHallPageComponent,
    CheckDevicesPageComponent,
    RoomPageComponent,
    RoomUserInviteBlockComponent,
    RoomLinkPageComponent,
    RoomAccessDeniedPageComponent,
    HallPageComponent,
    HallRoomListBlockComponent,
    HallRoomListEmptyBlockComponent,
    CreateHallRoomPageComponent,
    RoomPlayerTimeToStartComponentComponent,
    OnlyNumberDirective,
    InputRegExpDirective,
    VideoPreviewBlockDirective,
    HallSidebarBlockComponent,
    HallRoomListEmptyBlockComponent,
    HallRoomListBlockComponent,
    RoomNamePipe,
    RoomNamePipe,
    RoomSettingsBlockComponent,
    RoomPlayerRecordControlsBlockComponent,
    UnsupportedBrowserComponent,
    RequestPasswordPageComponent,
    SetNewPasswordPageComponent,
    HeaderComponent,
    LogoutModalComponent,
    FunctionNotImplementedModalComponent,
    DevicesPermissionsNotAllowedModalComponent,
    DevicesCameraNotAllowedModalComponent,
    DevicesCommonErrorModalComponent,
    InviteUserModalComponent,
    BlockChatModalComponent,
    BlockAccessModalComponent,
    GuestJoinToRoomPageComponent,
    DatepickerComponent,
    StopTranslationModalComponent,
    RoomPlayerRecordBlockComponent,
    VideoPlayerGridComponent,
    VideoPlayerGridItemComponent,
    VideoPlayerAdminBlockComponent,
    VideoPlayerUserBlockComponent,
    VideoPlayerAdminControlsComponent,
    VideoPlayerUserControlsComponent,
    ChatMessageComponent,
    RoomContextMenuComponent,
    RoomChatTabComponent,
    RoomChatControlsComponent,
    RoomAttachmentsTabComponent,
    RoomAttachmentComponent,
    ChangeAdminRoleModalComponent,
    VideoPlayerRecordBlockComponent,
    VideoPlayerRecordTemplateBlockComponent,
    VideoPlayerRecordGridComponent,
    RecordGridItemComponent,
    RoomPasswordPageComponent,
    VideoPlayerSideControlsComponent,
    RoomUsersTabComponent,
    DropdownComponent,
    UserBlockComponent,
    RoomQuestionsTabComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgxMaskModule.forRoot({}),
    ClickOutsideModule,
    LoggerModule.forRoot({
      serverLoggingUrl: environment.apiUrl + "ui/log",
      level: NgxLoggerLevel.DEBUG,
      serverLogLevel: NgxLoggerLevel.WARN,
      disableConsoleLogging: false,
      timestampFormat: 'yyyy-MM-ddTHH:mm:ss.SSS',
      enableSourceMaps: true,
    }),

    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      parser: {
        provide: TranslateParser,
        useClass: TranslateICUParser
      }
    }),
    FormsModule,
    ReactiveFormsModule,
    DeviceDetectorModule.forRoot(),
    AngularResizedEventModule
  ],
  providers: [
    DatePipe,
    { provide: LOCALE_ID, useValue: 'en' },
    CookieService,
    Globals,
    { provide: ErrorHandler, useClass: AirbrakeErrorHandler, deps: [NGXLogger] }

  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
