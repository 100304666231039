import {AfterViewInit, Component} from "@angular/core";
import {Hall} from "../../model/hall.model";
import {HttpErrorResponse} from "@angular/common/http";
import {ActivatedRoute, Router} from "@angular/router";
import {HallService} from "../../services/hall.service";
import {Subject} from "rxjs";
import {Room} from "../../model/room.model";
import {NGXLogger} from "ngx-logger";

@Component({
  selector: 'hall-page-component',
  templateUrl: './hall-page.component.html'
})
export class HallPageComponent implements AfterViewInit {
  public hall: Hall;
  public rooms: Room[];
  public hallFetchSubject: Subject<Hall>;

  public _hallId: string;

  constructor(public route: ActivatedRoute,
              public router: Router,
              public hallService: HallService,
              private logger: NGXLogger) {

    this.hallFetchSubject = new Subject<Hall>();
  }

  public ngAfterViewInit(): void {
    setTimeout(() => {
      this.route.params.subscribe(params => {
        this._hallId = params['id'];
        if (!this._hallId) {
          this.router.navigate(['/']);
        } else {
          this.hallService.getByLink(this._hallId).subscribe((hall: Hall) => {
              this.hall = hall;
              this.rooms = hall.rooms;
              this.hallFetchSubject.next(hall);
            }, (error: HttpErrorResponse) => {
            this.logger.error("Getting hall by link error", error);
              console.error(error);
            }
          );
        }
      });
    });
  }

  get getLink(): string {
    if (this.hall) {
      return this.hallService.getLink(this.hall);
    }
  }

  public isHallRoomsEmpty(): boolean {
    return this.hall && this.hall.rooms && this.hall.rooms.length === 0;
  }
}
