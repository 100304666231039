import {OnInit} from '@angular/core';
import {Room} from '../../../model/room.model';
import {Hall} from '../../../model/hall.model';
import {Globals} from '../../globals';
import {ActivatedRoute, Router} from '@angular/router';
import {RoomService} from '../../../services/room.service';
import {HallService} from '../../../services/hall.service';
import {UtilsService} from '../../../services/utils.service';
import {HttpErrorResponse} from '@angular/common/http';
import {StringUtils} from "../../../utils/string-utils";
import {RoomType} from "../../../model/enum/room-type";
import {NGXLogger} from "ngx-logger";
import {Subscription} from "rxjs";

export class CreateNewRoomHallPageComponent implements OnInit {
  tabId: string;
  room: Room;
  hall: Hall;
  roomErrors = {
    nameError: false,
    dateTimeError: false,
    errorDescription: 'string',
    passwordError: false
  };
  hallErrors = {
    nameError: false,
    errorDescription: 'string'
  };

  setRoomPassword: boolean = false;
  roomPassword: string;

  private paramSubscription: Subscription;

  constructor(public globals: Globals,
              private route: ActivatedRoute,
              private router: Router,
              private roomService: RoomService,
              private hallService: HallService,
              private logger: NGXLogger) {
    this.createEmptyRoom();

    this.hall = new Hall();
    this.hall.dateCreated = UtilsService.moment().format(UtilsService.dateTimeFormatPattern);
  }

  ngOnInit(): void {
    this.paramSubscription = this.route.params.subscribe(params => {
      const roomId = params['id'];
      if (roomId) {
        this.roomService.getByLink(roomId).subscribe(room => {
          this.room = room;
          this.room.dateStart = UtilsService.moment.utc(this.room.dateStart, 'YYYY-MM-DD').format('DD.MM.YYYY');
          this.setRoomPassword = !!this.room.password;
          this.roomPassword = this.room.password;
        }, () => this.router.navigate(['error/404']));
      }
    });
  }

  createEmptyRoom(): void {
    this.room = new Room();
    this.room.type = RoomType.CONFERENCE;
    this.room.dateStart = UtilsService.moment().format('DD.MM.YYYY');
    this.room.timeStart = UtilsService.moment().format('HH:mm');
  }

  switchTab(tabId: string): void {
    this.tabId = tabId;
  }

  clearFormErrors() {
    this.roomErrors.nameError = false;
    this.roomErrors.dateTimeError = false;
    this.roomErrors.passwordError = false;
    this.roomErrors.errorDescription = '';
    this.hallErrors.nameError = false;
    this.hallErrors.errorDescription = '';
  }

  validateForm(): boolean {
    this.clearFormErrors();

    this.roomErrors.nameError = StringUtils.isEmpty(this.room.name);

    if (this.room.type === RoomType.CONFERENCE) {
      this.roomErrors.dateTimeError = StringUtils.isEmpty(this.room.dateStart) || StringUtils.isEmpty(this.room.timeStart);
    }

    this.roomErrors.passwordError = this.setRoomPassword && StringUtils.isEmpty(this.roomPassword);

    if (this.roomErrors.nameError || this.roomErrors.passwordError || this.roomErrors.dateTimeError) {
      this.roomErrors.errorDescription = 'fields-errors.fill-all-required-fields';
    } else if (this.room.type === RoomType.CONFERENCE &&
               !UtilsService.moment.utc(this.room.dateStart + ' ' + this.room.timeStart, 'DD.MM.YYYY HH:mm').isValid()) {
      this.roomErrors.dateTimeError = true;
      this.roomErrors.errorDescription = 'fields-errors.enter-correct-date';
    } else if (this.room.type === RoomType.CONFERENCE &&
               UtilsService.moment.utc(this.room.dateStart + ' ' + this.room.timeStart, 'DD.MM.YYYY HH:mm')
                           .isBefore(UtilsService.moment().startOf("minute").utc(true))) {
      this.roomErrors.dateTimeError = true;
      this.roomErrors.errorDescription = 'fields-errors.old-date-entered';
    }

    return !this.roomErrors.nameError && !this.roomErrors.dateTimeError && !this.roomErrors.passwordError;
  }

  validateHallForm(): boolean {
    this.hallErrors.nameError = StringUtils.isEmpty(this.hall.name);

    if (this.hallErrors.nameError) {
      this.hallErrors.errorDescription = 'fields-errors.enter-correct-date';
    }

    return !this.hallErrors.nameError;
  }

  createRoom(): void {
    if (this.validateForm()) {
      this.room.link = UtilsService.getGUIDWithoutSeparators();
      const room = Room.clone(this.room);
      room.password = this.setRoomPassword ? this.roomPassword : null;
      room.dateStart = UtilsService.moment
        .utc(this.room.dateStart, 'DD.MM.YYYY')
        .format('YYYY-MM-DD');
      this.roomService.createNewRoom(room).subscribe((updatedRoom: Room) => {
          if (updatedRoom && updatedRoom.id) {
            this.goToCheckDevicesPage(updatedRoom.link);
          }
        }, (error: HttpErrorResponse) => {
          this.logger.error("Room creation error", error);
        }
      );
    }
  }

  createHall(): void {
    if (this.validateHallForm()) {
      this.hall.link = UtilsService.getGUIDWithoutSeparators();
      const hall = Hall.clone(this.hall);
      this.hallService.createNewHall(hall).subscribe((updatedHall: Hall) => {
          if (updatedHall && updatedHall.id) {
            this.goToHallPage(updatedHall.link);
          }
        }, (error: HttpErrorResponse) => {
          this.logger.error("Hall creation error", error);
        }
      );
    }
  }

  goToCheckDevicesPage(roomId: string): void {
    this.router.navigate([`room/id/${roomId}/check-devices`]);
  }

  goToHallPage(hallId: string): void {
    this.router.navigate([`hall/id/${hallId}`]);
  }

  goToMainPage(): void {
    this.router.navigate(['/']);
  }

  generatePassword(): void {
    const allowedSymbols = 'qwertyuiopasdfghjklzxcvbnmQWERTYUIOPASDFGHJKLZXCVBNM1234567890';
    const max = allowedSymbols.length;

    let newPass = '';
    for (let i = 0; i < 10; i++) {
      newPass += allowedSymbols[Math.floor(Math.random() * max)];
    }
    this.roomPassword = newPass;
  }
}
