import {Injectable, OnDestroy, OnInit} from '@angular/core';
import {WsEventEnum} from '../model/enum/ws-event.enum';
import {WsRtcModel} from '../model/ws-rtc.model';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {WsMessageEvent} from '../model/event/ws-data-event.model';
import {Globals} from '../app/globals';
import {WsWrapper} from "../wrappers/ws.wrapper";
import {StreamTypeEnum} from "../model/enum/stream-type-enum";
import {WsRtcModelList} from "../model/ws-rtc-model-list.model";
import {PCStateEnum, WsRtcModelStateEnum} from "../model/enum/ws-rtc-model-state.enum";
import {InputIdInfo} from "../model/input-id-info.model";
import {
  InputAStreamContainerAbstract,
  InputAVModifiedStreamContainer,
  InputStreamContainer,
  OutputAudioVideoStreamContainer
} from "../model/stream-container";
import {NGXLogger} from "ngx-logger";

@Injectable({ providedIn: 'root' })
export class MediaServerService implements OnDestroy, OnInit {
  private readonly _wsOnOutputStatusChangeSubject = new Subject<WsRtcModel>();
  private readonly _wsOnInputStatusChangeSubject = new Subject<InputIdInfo>()
  private readonly _serverErrorSubject: Subject<string>;
  private readonly _wsConnectedSubject: Subject<void>;
  private readonly _wsRtcModels: WsRtcModelList;

  private _soundEnable: boolean;
  private readonly _soundEnableSwitchSubject: BehaviorSubject<boolean>;

  private ws: WsWrapper;


  constructor(private globals: Globals,
              private logger: NGXLogger) {
    this._wsOnOutputStatusChangeSubject = new Subject<WsRtcModel>();
    this._wsOnInputStatusChangeSubject = new Subject<InputIdInfo>();

    this._serverErrorSubject = new Subject<string>();
    this._wsConnectedSubject = new Subject<void>();
    this._wsRtcModels = new WsRtcModelList();

    this._soundEnable = true;
    this._soundEnableSwitchSubject = new BehaviorSubject<boolean>(this._soundEnable);

    // console.log("checkConnectionsFailedModels Interval 0");
    setInterval(() => {
      // console.log("checkConnectionsFailedModels Interval 1");
      if (this.ws && this.ws.state() == WebSocket.OPEN) {
        // console.log("checkConnectionsFailedModels Interval 2");
        this.checkConnectionsFailedModels();
      }
    }, 500)
  }

  get onSoundEnableSwitch(): Observable<boolean> {
    return this._soundEnableSwitchSubject.asObservable();
  }

  get onServerError(): Observable<string> {
    return this._serverErrorSubject
      .asObservable();
  }

  get onWsConnected(): Observable<void> {
    return this._wsConnectedSubject
      .asObservable();
  }

  get wsOnOutputStatusChange(): Observable<WsRtcModel> {
    return this._wsOnOutputStatusChangeSubject
      .asObservable();
  }

  get wsOnInputStatusChange(): Observable<InputIdInfo> {
    return this._wsOnInputStatusChangeSubject
      .asObservable();
  }

  get isConnectedToMediaServer(): boolean {
    return this.ws?.isActive;
  }

  public reDistributeModels(): void {
    let outputModels = this._wsRtcModels.findAllOutputModels();
    outputModels
      .forEach((wsRtcModel) => {
        this.logger.info("reDistributeModel: {}", wsRtcModel.toString());
        this._wsOnOutputStatusChangeSubject.next(wsRtcModel);
      });
  }

  public createConnectionToMediaServer(roomLink: string): void {
    if (this.globals.userRoomRole.isUser) {
      this._soundEnable = false;
      this._soundEnableSwitchSubject.next(this._soundEnable);
    }
    this.ws = this.createWsConnection(roomLink);
    this.openWsConnection(this.ws);
  }

  public publishStream(inputId: string, contentId: string, inputStreamContainer: InputStreamContainer): void {
    this.onRTCPeerConnection(inputId, contentId, inputStreamContainer);
  }

  public updateStream(streamId: number, iStreamContainer: InputAStreamContainerAbstract) {
    this.logger.debug("MediaServerService updateStream start ");

    let iVideoContainer: any;
    if (iStreamContainer instanceof InputAVModifiedStreamContainer) {
      iVideoContainer = iStreamContainer;
    }

    let audioTrack = iStreamContainer.getAudioTrackForReplace();

    let videoTrack;
    if (iVideoContainer) {
      videoTrack = iVideoContainer.getVideoTrackForReplace();
      if (!videoTrack) {
        throw "videoTrack of new stream is undefined";
      }
    }


    this._wsRtcModels
      .findInputModelsByStreamId(streamId)
      .forEach((wsRtcModel) => {
        wsRtcModel.streamContainer.stopStreams()
        wsRtcModel.streamContainer = iStreamContainer;

        wsRtcModel.pc.getSenders().forEach((sender: RTCRtpSender) => {
          if (sender.track != null) {
            if (sender.track.kind === "audio") {
              sender.replaceTrack(audioTrack)
                .then(() => {
                  this.logger.debug("RTCRtpSender audio track successfully replaced")
                })
                .catch((error) => {
                  this.logger.error("error while RTCRtpSender audio track replaced", error)
                });
            }
            if (videoTrack) {
              if (sender.track.kind === "video") {
                sender.replaceTrack(videoTrack)
                  .then(() => {
                    this.logger.debug("RTCRtpSender video track successfully replaced")
                  })
                  .catch((error) => {
                    this.logger.error("error while RTCRtpSender video track replaced", error)
                  });
              }
            }
          }
        });

        this.logger.debug("updateStream for _connectedWsRtcModels wsRtcModel: {} ", wsRtcModel.toString());
      });
  }

  public muteStreamByUserId(mute: boolean, userId: number,
                            muteCallback: (userId: number, streamId: number, muted: boolean) => void): void {
    this.logger.debug("muteStreamByUserId({}, {}}", mute, userId);
    this._wsRtcModels
      .findInputModelsByStreamUserId(userId)
      .forEach((wsRtcModel) => {
        if (wsRtcModel.streamContainer && wsRtcModel.streamContainer instanceof InputAStreamContainerAbstract) {
          let iavStreamContainer: InputAStreamContainerAbstract = wsRtcModel.streamContainer;
          iavStreamContainer.mute(mute);
          this.logger.debug("muteStreamByUserId({}, {}}, oavStreamContainer: {}", mute, userId, iavStreamContainer.toString());
          muteCallback(userId, wsRtcModel.streamId, mute);
        }
      });
  }

  public disableVideoStreamByUserId(disable: boolean, userId: number,
                                    disableCallback: (userId: number, streamId: number, disabled: boolean) => void): void {
    this.logger.debug("disableVideoStreamByUserId({}, {}", disable, userId);
    this._wsRtcModels.findInputModelsByStreamUserIdAndNotScreen(userId)
      .forEach((wsRtcModel) => {
        if (wsRtcModel.streamContainer &&
          (wsRtcModel.streamContainer instanceof InputAVModifiedStreamContainer
          )) {
          let iavStreamContainer: any = wsRtcModel.streamContainer;
          iavStreamContainer.disableVideo(disable);
          this.logger.debug("disableVideoStreamByUserId({}, {}}, oavStreamContainer: {}", disable, userId, iavStreamContainer.toString());
          disableCallback(wsRtcModel.streamUserId, wsRtcModel.streamId, disable);
        }
      });
  }

  switchSound(enabled: boolean): void {
    this._wsRtcModels
      .findAllOutputModels()
      .forEach((wsRtcModel) => {
        if (wsRtcModel.streamUserId === this.globals.user.id && wsRtcModel.isOutput) {
          wsRtcModel.oavStreamContainer.mute(true);
          this.logger.debug("switchSound({}), userId: {}, oavStreamContainer: {}", enabled, wsRtcModel.streamUserId, wsRtcModel.oavStreamContainer.toString());
        } else {
          wsRtcModel.oavStreamContainer.mute(!enabled)
          this.logger.debug("switchSound({}), userId: {}, oavStreamContainer: {}", enabled, wsRtcModel.streamUserId, wsRtcModel.oavStreamContainer.toString());
        }
        this.logger.debug("Switch all output audio stream to " + enabled);
      });

    this._soundEnable = enabled;
    this.logger.debug("this._soundEnable: {}", this._soundEnable);
    this._soundEnableSwitchSubject.next(this._soundEnable);
  }

  public existsStreamByUserId(streamUserId: number): boolean {
    return this._wsRtcModels.existsByStreamUserIds([streamUserId]);
  }

  public stopInputStreams(): void {
    this.onCloseModel(this._wsRtcModels.findInputModelsByStreamUserId(this.globals.user.id));
  }

  /**
   *
   * @param streamId: glagol db stream id
   * description: for manually stop streams
   */

  public stopStreamsByStreamId(streamId: number) {
    this.logger.debug("stopStreamsByStreamId({})", streamId);
    let toRemove = this._wsRtcModels.findModelsByStreamId(streamId);
    this.onCloseModel(toRemove);
  }


  public findOutputUserStream(userId: number): WsRtcModel {
    return this._wsRtcModels
      .findOutputModelByStreamUserId(userId);
  }

  public findConnectedOutputUserStream(userId: number): WsRtcModel {
    return this._wsRtcModels
      .findConnectedOutputModelsByStreamUserId(userId);
  }

  public findInputModelsByInputId(inputId: string): WsRtcModel {
    return this._wsRtcModels
      .findInputModelByInputId(inputId);
  }

  public findInputModelsByStreamType(streamType: StreamTypeEnum): WsRtcModel[] {
    return this._wsRtcModels
      .findInputModelsByStreamType(streamType);
  }

  public findOutputModelsByStreamType(streamType: StreamTypeEnum): WsRtcModel[] {
    return this._wsRtcModels
      .findOutputModelsByStreamType(streamType);
  }

  public existsConnectedOutputModelByStreamUserId(streamUserId: number): boolean {
    return this._wsRtcModels
      .existsConnectedOutputModelByStreamUserId(streamUserId);
  }

  public existsConnectedToStreamUserIdOutputModelsByOutputStreamUserId(streamUserId: number, outputStreamUserId: number): boolean {
    return this._wsRtcModels
      .existsConnectedToStreamUserIdOutputModelsByOutputStreamUserId(streamUserId, outputStreamUserId);
  }

  public findConnectedToStreamUserIdOutputModelsByOutputStreamUserId(streamUserId: number, outputStreamUserId: number): number {
    return this._wsRtcModels
      .findConnectedToStreamUserIdOutputModelsByOutputStreamUserId(streamUserId, outputStreamUserId);
  }

  private recreateRTCPeerConnection(model: WsRtcModel) {

    this.logger.info("recreateRTCPeerConnection: initiated");

    // only for output models
    if (model.isInput) {
      this.logger.info("recreateRTCPeerConnection: input model, skip");
      return;
    }

    // only for model with streamContainer
    if (!model.streamContainer) {
      this.logger.info("recreateRTCPeerConnection: streamContainer not exist, skip");
      return;
    }

    model.pcConnectionState = PCStateEnum.RECONNECTING;
    let oldPC = model.pc;
    let newOutputId = model.generateNewOutputId(model.outputUserId);
    this.logger.debug("recreateRTCPeerConnection: generated new output id");
    model.pc = this.createRTCPeerConnection(model, newOutputId);
    this.logger.debug("recreateRTCPeerConnection: created new RTCPeerConnection");

    let createOfferOption: RTCOfferOptions = {};

    if (model.isInput && model.streamContainer && model.streamContainer instanceof InputStreamContainer) {
      // not implemented
    } else {
      createOfferOption.offerToReceiveAudio = true;
      createOfferOption.offerToReceiveVideo = true;

      // for connection to stream
      model.pc.addTransceiver('audio', { direction: 'sendrecv' });
      model.pc.addTransceiver('video', { direction: 'sendrecv' });

      model.pc.ontrack = (event: RTCTrackEvent) => {
        let stream = event.streams[0];
        if (model.pcConnectionState === PCStateEnum.RECONNECTING) {
          let newavStreamContainer = new OutputAudioVideoStreamContainer(stream);

          if (!this._soundEnable) {
            newavStreamContainer.mute(true);
            this.logger.debug("recreateRTCPeerConnection: ontrack newavStreamContainer.mute({}), oavStreamContainer: {}", true, newavStreamContainer.toString());
          }

          this.logger.debug("recreateRTCPeerConnection: closing old container and pc");
          model.oavStreamContainer.stopStreams();
          oldPC.close();
          this.logger.debug("recreateRTCPeerConnection: old container and pc are closed");

          model.streamContainer = newavStreamContainer;
          model.onNewStreamContainerSubj.next(model.streamContainer);

          this.logger.info("recreateRTCPeerConnection: finished => new stream created, old stream and pc closed");
          model.pcConnectionState = PCStateEnum.CONNECTED;

        }
        this.logger.debug("model.pc.ontrack Model: {}", model.toString());
      };

      this.logger.debug("recreateRTCPeerConnection: onTrackListenerAdded");
    }

    model.pc.createOffer(createOfferOption).then(offer => {
      this.logger.debug("recreateRTCPeerConnection: offer created");
      return model.pc.setLocalDescription(offer);
    }).then(() => {
      if (model.isInput) {
        // not implemented
      } else {
        this.ws.sendOutputNewCommand(model.inputId, newOutputId, model.pc.localDescription.sdp);

        let oldOutputId = model.outputId;
        model.outputId = newOutputId;

        this.logger.debug("recreateRTCPeerConnection: OutputNewCommand sent");

        setTimeout(()=> {
          this.logger.debug("recreateRTCPeerConnection:check for PCStateEnum.RECONNECTING");
          if (model.pcConnectionState == PCStateEnum.RECONNECTING) {
            this.logger.info("recreateRTCPeerConnection: change RECONNECTING to CONNECTION_FAILED");
            model.pcConnectionState = PCStateEnum.CONNECTION_FAILED;
            model.pc.close();
            model.pc = oldPC;
            model.outputId = oldOutputId;
          }
        }, 2000)
      }
    }).catch((exception) => {
      this.logger.error("recreateRTCPeerConnection: error when model.pc.createOffer(" + JSON.stringify(createOfferOption) + ")", exception)
    });

  }

  private onRTCPeerConnection(inputId: string, contentId?: string, inputStreamContainer?: InputStreamContainer): void {
    this.logger.debug("onRTCPeerConnection({}, {}, {})", inputId, contentId, inputStreamContainer?.toString());

    let model = new WsRtcModel(this.logger, inputId, this.globals.user.id, contentId, inputStreamContainer);
    let pc = this.createRTCPeerConnection(model, model.isInput ? model.inputId : model.outputId);
    model.pc = pc;

    let createOfferOption: RTCOfferOptions = {};

    if (model.isInput && model.streamContainer && model.streamContainer instanceof InputStreamContainer) {

      createOfferOption.offerToReceiveAudio = false;
      createOfferOption.offerToReceiveVideo = false;

      //add tracks
      model.streamContainer.getTracksForRTCPeerConnection()
        .forEach((row) => {
          this.logger.debug("RTCPeerConnection addTrack! kind:" + row.track.kind + ", id: " + row.track.id);
          model.pc.addTrack(row.track, row.stream);
        });

    } else {

      createOfferOption.offerToReceiveAudio = true;
      createOfferOption.offerToReceiveVideo = true;

      // for connection to stream
      model.pc.addTransceiver('audio', { direction: 'sendrecv' });
      model.pc.addTransceiver('video', { direction: 'sendrecv' });

      model.pc.ontrack = (event: RTCTrackEvent) => {
        let stream = event.streams[0];
        if (!model.streamContainer || model.streamContainer.stream.id !== stream.id) {
          model.streamContainer = new OutputAudioVideoStreamContainer(event.streams[0]);
          model.state = WsRtcModelStateEnum.CONNECTED;
          model.pcConnectionState = PCStateEnum.CONNECTED;
          if (!this._soundEnable) {
            model.oavStreamContainer.mute(true);
            this.logger.debug("ontrack model.oavStreamContainer.mute({}), oavStreamContainer: {}", true, model.oavStreamContainer.toString());
          }
          this.logger.info("model.pc.ontrack Model: {}", model.toString());
          this._wsOnOutputStatusChangeSubject.next(model);
        }
      };
    }

    this._wsRtcModels.push(model);

    model.pc.createOffer(createOfferOption).then(offer => {
      return model.pc.setLocalDescription(offer);
    }).then(() => {
      if (model.isInput) {
        if (this.existDuplicateInputModel(model)) {
          this.logger.info("found duplicate input model, closing streams " + model.id);
          this.onCloseModel([model], false);
          model.streamContainer.stopStreams();
          this._wsRtcModels.removeModelByIdIfExists(model.id);
          return;
        }

        this.logger.debug("trying to sendInputNewCommand " + model.id);
        this.ws
          .sendInputNewCommand(model, this.globals.user.email, this.globals.browser);

      } else {
        this.ws
          .sendOutputNewCommand(model.inputId, model.outputId, model.pc.localDescription.sdp);
      }
    }).catch((exception) => {
      this._wsRtcModels.removeModelByIdIfExists(model.id);
      this.logger.error("model.pc.createOffer(" + JSON.stringify(createOfferOption) + ")", exception)
    });

  }

  private createRTCPeerConnection(model: WsRtcModel, pcId: string): RTCPeerConnection {
    let pc = new RTCPeerConnection({
      iceServers: [
        {
          urls: 'stun:stun.l.google.com:19302'
        }
      ]
    });

    pc.onicecandidate = (event: RTCPeerConnectionIceEvent) => {
      this.logger.debug(" onicecandidate event " + JSON.stringify(event));
      this.ws.sendIceCandidateCommand(pcId, event);
    };

    pc.onicecandidateerror = (event: RTCPeerConnectionIceErrorEvent) => {
      this.logger.debug("RTCPeerConnection.onicecandidateerror. Status: " + event.errorCode + ", Error: " + event.errorText);
      this._serverErrorSubject.next("RTCPeerConnection.onicecandidateerror. Status: " + event.errorCode + ", Error: " + event.errorText);
    };

    pc.onconnectionstatechange = () => {
      // this.logger.debug("State Change: ", pc);
      switch (pc.connectionState) {
        case 'connected':
          // this._serverErrorSubject.next("RTCPeerConnection.connectionState. Status: connected");
          break;
        case 'disconnected':
          this._serverErrorSubject.next("RTCPeerConnection.connectionState. Status: disconnected");
          break;
        case 'failed':
          model.pcConnectionState = PCStateEnum.CONNECTION_FAILED;
          // console.log("checkConnectionsFailedModels onconnectionstatechange failed model " + model.toString());
          this._serverErrorSubject.next("RTCPeerConnection.connectionState. Status: failed");
          break;
        case 'closed':
          // this._serverErrorSubject.next("RTCPeerConnection.connectionState. Status: closed");
          break;
      }
    };

    pc.oniceconnectionstatechange = (ev:any) => {
      // console.log('pcstatus', 'oniceconnectionstatechange ' + JSON.stringify(ev.currentTarget.iceConnectionState));
    };

    return pc;
  }

  private onSDPAnswerEvent(wsData: WsMessageEvent): void {
    const id = wsData.header.id;
    const sdp = wsData.body.sdp;
    const model = this._wsRtcModels.findModelByInputIdOrOutputId(id);
    if (model) {
      model.pc.setRemoteDescription(new RTCSessionDescription({
        type: 'answer',
        sdp: sdp
      }));
    }
  }

  private createWsConnection(roomLink: string): WsWrapper {
    let ws = new WsWrapper(this.logger, roomLink);
    ws
      .onMessage()
      .subscribe((wsMessageEvent) => {
        if (wsMessageEvent.event === WsEventEnum.SDP_ANSWER) {
          this.logger.info("Event: " + new Date().toLocaleString() + ", eventType: {}, eventHeader: {}", wsMessageEvent.event, wsMessageEvent.header);
        } else {
          this.logger.info("Event: " + new Date().toLocaleString() + ", event: {}", wsMessageEvent);
        }
        this.onMessageEventHandler(wsMessageEvent);
      });

    ws.onOpen().subscribe(() => this._wsConnectedSubject.next());
    ws.onError().subscribe(() => {
      if (!ws.isActive) {
        this.onCloseModel(this._wsRtcModels.clear(), false)
      }
    })
    ws.onClose().subscribe( () => this.onCloseModel(this._wsRtcModels.clear(), false));
    return ws;
  }

  private openWsConnection(ws): void {
    if (ws.state() !== WebSocket.CONNECTING || ws.state() !== WebSocket.OPEN) {
      ws.open();
    }
  }

  private onMessageEventHandler(wsData: WsMessageEvent) {
    switch (wsData.event) {
      case WsEventEnum.SDP_ANSWER: {
        this.onSDPAnswerEvent(wsData);
        break;
      }
      case WsEventEnum.INPUTS_OFFLINE: {
        this._wsOnInputStatusChangeSubject.next(new InputIdInfo(wsData.header.id, false));
        this.onInputStatusEvent(wsData);
        break;
      }
      case WsEventEnum.INPUTS_LIST: {
        if (this.globals.userRoomRole.isUser && wsData.body.inputs.length === 0) {
          this._soundEnable = true;
          this._soundEnableSwitchSubject.next(this._soundEnable);
        }
        this.onInputStatusEvent(wsData);
        break;
      }
      case WsEventEnum.INPUTS_ONLINE: {
        this._wsOnInputStatusChangeSubject.next(new InputIdInfo(wsData.header.id, true));
        this._wsRtcModels.setInputModelStateByInputId(wsData.header.id, WsRtcModelStateEnum.CONNECTED);
        this.globals.isTranslationStarted = true;
        this.onInputStatusEvent(wsData);
        break;
      }
      case WsEventEnum.SERVER_ERROR: {
        this._serverErrorSubject.next(JSON.stringify(wsData));
        break;
      }
    }
  }

  private onInputStatusEvent(wsData: WsMessageEvent): void {
    let allOutputIds = this._wsRtcModels.getAllInputIds();
    let connectedActualOutputIds = this._wsRtcModels.findActualOutputInputIds();
    let actualOnlineInputIds = wsData.body.inputs.map((input) => input.id);
    this.logger.debug("allOutputIds: {}", allOutputIds);
    this.logger.debug("connectedActualOutputIds: {}", connectedActualOutputIds);
    this.logger.debug("actualOnlineInputIds: {}", actualOnlineInputIds);

    let newInputs = actualOnlineInputIds.filter((inputId) => !connectedActualOutputIds.includes(inputId));
    let orphanedInputs = allOutputIds.filter((inputId) => !actualOnlineInputIds.includes(inputId));

    this.logger.debug("newInputs: {}", newInputs);
    newInputs.forEach((inputId) => {
      this.onRTCPeerConnection(inputId);
    });

    this.logger.debug("orphanedInputs: {}", orphanedInputs);
    orphanedInputs.forEach((inputId) => {
      this._wsRtcModels.removeByInputIdIfExists(inputId)
        .filter((wsRtcModel) => wsRtcModel.isConnected)
        .forEach((model) => this.onCloseModel([model], false));
    });

    this.logger.debug("this._wsRtcModels: {}", this._wsRtcModels.list.toString());

  }
  private checkConnectionsFailedModels() {
    // console.log("checkConnectionsFailedModels Interval 3");

    // this._wsRtcModels.findAllOutputModels().forEach(model => {console.log("checkConnectionsFailedModels Interval 4 model " + model.toString())})

    this._wsRtcModels.findAllOutputModels()
      .filter((wsRtcModel) =>
        wsRtcModel.pcConnectionState == PCStateEnum.CONNECTION_FAILED
        && wsRtcModel.isActual
      )
      .forEach((wsRtcModel) => {this.recreateRTCPeerConnection(wsRtcModel)});
  }

  private onCloseModel(wsRtcModels: WsRtcModel[], sendIoClose: boolean = true): void {
    wsRtcModels
      .filter((wsRtcModel) => wsRtcModel.state !== WsRtcModelStateEnum.TO_REMOVE)
      .forEach((wsRtcModel) => {
        this.logger.info("onCloseModel: sendIoClose: {}, wsModelToClose: {}", sendIoClose, wsRtcModel.toString());
        wsRtcModel.pc.close();
        wsRtcModel.state = WsRtcModelStateEnum.TO_REMOVE;
        if (wsRtcModel.isInput) {
          if (sendIoClose && this.ws.state() === WebSocket.OPEN) {
            this.ws.sendIoCloseCommand(wsRtcModel.inputId);
          }
        } else {
          this._wsOnOutputStatusChangeSubject.next(wsRtcModel);
        }
      })
  }

  beforeOnload(): WsRtcModel[] {
    this.stopInputStreams();
    this.onCloseModel(this._wsRtcModels.list);

    if (this.ws) {
      this.ws.close();
      this.ws = null;
    }

    return this._wsRtcModels.clear();
  }

  ngOnDestroy(): void {
    this.logger.debug("MediaServerService ngOnDestroy!!!!!!!!!!!!!!!!!");
  }

  private existDuplicateInputModel(newModel: WsRtcModel) {
    let sameModels = this._wsRtcModels.findInputModelsByStreamType(newModel.streamType).filter(value => value.id !== newModel.id);
    if (sameModels.length > 0) {
      return true;
    }

    return false;
  }

  ngOnInit(): void {

  }
}
