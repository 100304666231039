import {RoomType} from "./enum/room-type";

export class Room {
  id: number;
  name: string;
  pictureUrl: string;
  description: string;
  dateStart: string;
  timeStart: string;
  link: string;
  shortLink: string;
  isNew: boolean;
  hallId: number;
  dateAdded: string;
  userCount: number;
  password: string;
  type: RoomType;

  constructor(link?: string) {
    this.link = link;
  }

  static clone(source: Room): Room {
    const room = new Room();
    room.id = source.id;
    room.name = source.name;
    room.dateStart = source.dateStart;
    room.timeStart = source.timeStart;
    room.link = source.link;
    room.shortLink = source.shortLink;
    room.isNew = source.isNew;
    room.password = source.password;
    room.type = source.type;
    return room;
  }
}
