import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {CookieService} from 'ngx-cookie-service';
import {Observable} from 'rxjs';
import {registerLocaleData} from '@angular/common';
import localeRu from '@angular/common/locales/ru';
import {Language} from '../model/language.model';
import {UtilsService} from './utils.service';

@Injectable({ providedIn: 'root' })
export class LanguageService {
  private readonly localeCookieName = 'glagol_locale';
  private readonly localeCodeRu = 'ru';
  private readonly localeCodeEn = 'en';

  private languages: Language[] = [
    new Language(this.localeCodeEn, 'language', localeRu),
    new Language(this.localeCodeRu, 'language', localeRu),
  ];

  private currentLanguage: Language;

  constructor(
    private translate: TranslateService,
    private cookieService: CookieService) {
    this.translate.setDefaultLang(this.localeCodeEn);
    this.init();
  }

  init(): void {
    const cookieLangCode = this.cookieService.get(this.localeCookieName);
    if (cookieLangCode) {
      const lang = this.languages.find(language => language.code === cookieLangCode);
      if (lang !== null) {
        this.changeLanguage(lang);
        return;
      }
    }
    this.changeLanguage(this.languages[0]);
  }

  changeLanguage(language: Language): void {
    this.currentLanguage = language;
    this.translate.use(language.code);
    this.cookieService.set(this.localeCookieName, language.code, 365, window.location.pathname, window.location.hostname, true, "None");
    registerLocaleData(language.locale);
    UtilsService.moment.locale(language.code);
  }

  getLanguages(): Language[] {
    return this.languages;
  }

  getCurrentLanguage(): Language {
    return this.currentLanguage;
  }

  getValueByKey(key: string, value?: any): Observable<string> {
    return this.translate.get(key, value);
  }
}
