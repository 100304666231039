import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Room} from "../model/room.model";
import {share} from "rxjs/operators";
import {environment} from "../environments/environment";
import {Hall} from "../model/hall.model";

@Injectable({ providedIn: 'root' })
export class HallService {
  private readonly baseUrl = environment.apiUrl;

  constructor(private http: HttpClient) {
  }

  getLink(hall: Hall): string {
    const origin = window.location.origin;
    return hall.shortLink ? origin + hall.shortLink : `${origin}/hall/id/${hall}`;
  }

  getAll(): Observable<Hall[]> {
    return this.http.get<Hall[]>(this.baseUrl + 'hall/all', {
      withCredentials: true
    }).pipe(share());
  }

  getByLink(id: string): Observable<Hall> {
    return this.http.get<Hall>(this.baseUrl + `hall/link/${id}`, {
      withCredentials: true
    }).pipe(share());
  }

  removeHallRoom(room: Room): Observable<Hall> {
    return this.http.delete<Hall>(this.baseUrl + `hall/${room.hallId}/room/${room.id}`, {
      withCredentials: true,
    }).pipe(share());
  }

  editHallRoom(room: Room): Observable<Hall> {
    return this.http.put<Hall>(this.baseUrl + `hall/${room.hallId}/room`, room, {
      withCredentials: true,
    }).pipe(share());
  }

  createNewRoomHall(room: Room): Observable<Hall> {
    return this.http.post<Hall>(this.baseUrl + `hall/${room.hallId}/room`, room, {
      withCredentials: true,
    }).pipe(share());
  }

  createNewHall(hall: Hall): Observable<Hall> {
    return this.http.post<Hall>(this.baseUrl + 'hall', hall, {
      withCredentials: true,
    }).pipe(share());
  }
}
