import {Injectable, OnDestroy} from '@angular/core';
import {Observable} from 'rxjs';
import {VideoStreamMessage} from '../model/message/video-stream.message';
import {VideoStreamCommandTypeEnum} from "../model/enum/video-stream-command-type.enum";
import {Globals} from "../app/globals";
import {UserStreamInfo} from "../model/user-stream-info.model";
import {share} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";
import {Content} from "../model/content.model";
import {environment} from "../environments/environment";
import {StreamMessageCreationUtils} from "../utils/stream-message-creation.utils";
import {WsRtcModel} from "../model/ws-rtc.model";
import {InputIdInfo} from "../model/input-id-info.model";
import {BaseStompSupportedService} from "./base-stomp-supported.service";
import {NGXLogger} from "ngx-logger";


@Injectable({ providedIn: 'root' })
export class VideoStreamService extends BaseStompSupportedService<VideoStreamMessage> implements OnDestroy {
  private readonly baseUrl = environment.apiUrl;
  protected readonly WS_PATH: string = "video-stream";

  private readonly _messageCreationUtils: StreamMessageCreationUtils;

  private _isAdminStreamStarted: boolean;
  private _isAdminSharedStreamStarted: boolean;


  constructor(private globals: Globals,
              private http: HttpClient,
              logger: NGXLogger) {
    super(logger, VideoStreamMessage);
    this._messageCreationUtils = new StreamMessageCreationUtils(globals);
  }

  get isAdminSharedStreamStarted(): boolean {
    return this._isAdminSharedStreamStarted;
  }

  set isAdminSharedStreamStarted(value: boolean) {
    this._isAdminSharedStreamStarted = value;
  }

  get isAdminStreamStarted(): boolean {
    return this._isAdminStreamStarted;
  }

  set isAdminStreamStarted(value: boolean) {
    this._isAdminStreamStarted = value;
  }

  public sendPublishCamStreamMessage(toUserId: number, reason: string): void {
    let message = this._messageCreationUtils.createPublishCamStreamMessage(toUserId, reason);
    this.send(message);
  }

  public sendPublishScreenStreamMessage(toUserId: number, reason: string): void {
    let message = this._messageCreationUtils.createPublishScreenStreamMessage(toUserId, reason);
    this.send(message);
  }

  public sendFinishStreamMessage(streamId: number, roomId: number, reason: string): void {
    let message = this._messageCreationUtils.createFinishStreamMessage(streamId, roomId, reason);
    this.send(message);
  }

  public sendStopCamStreamMessage(streamId: number, toUserId: number, reason: string): void {
    let message = this._messageCreationUtils.createStopCamStreamMessage(streamId, toUserId, reason);
    this.send(message);
  }

  public sendStopScreenStreamMessage(streamId: number, toUserId: number, reason: string): void {
    let message = this._messageCreationUtils.createStopScreenStreamMessage(streamId, toUserId, reason);
    this.send(message);
  }

  public sendUpdateStreamMessage(streamId: number, reason: string): void {
    this.logger.debug("sendUpdateStreamMessage({}, {})", streamId, reason);
    let message = this._messageCreationUtils.createUpdateStreamMessage(streamId, reason);
    this.send(message);
  }

  public sendInviteUserToStreamMessage(streamId: number, toUserId: number): void {
    this.logger.debug("sendInviteUserToStreamMessage({}, {})", streamId, toUserId);
    let message = this._messageCreationUtils.createInviteUserToStreamMessage(streamId, toUserId);
    this.send(message);
  }

  public sendUserMuteStreamMessage(streamId: number, toUserId: number, muted: boolean, reason: string): void {
    let message = this._messageCreationUtils.createUserMuteStreamMessage(streamId, toUserId, muted, reason);
    this.send(message);
  }

  public sendUserCameraDisabledMessage(streamId: number, toUserId: number, disabled: boolean, reason: string): void {
    let message = this._messageCreationUtils.createUserCameraDisableMessage(streamId, toUserId, disabled, reason);
    this.send(message);
  }

  public sendStreamOnlineMessage(inputIdInfo: InputIdInfo, status: boolean): void {
    try {
      let message = this._messageCreationUtils.createStreamOnlineMessage(inputIdInfo, status);
      this.send(message);
    } catch (e) {
      this.logger.error("sendStreamOnlineMessage failed", e);
      console.error("sendStreamOnlineMessage failed, ", e);
    }
  }

  public sendConnectedToStreamMessage(wsRtcModel: WsRtcModel, status: boolean): void {
    try {
      let message = this._messageCreationUtils.createConnectedToStreamMessage(wsRtcModel, status);
      this.send(message);
    } catch (e) {
      this.logger.error("sendConnectedToStreamMessage failed", e);
      console.error("sendConnectedToStreamMessage failed, ", e);
    }
  }

  public sendErrorStreamMessage(error: any, videoStreamMessage?: VideoStreamMessage): void {
    let message = videoStreamMessage;
    if (message) {
      message.reason = error;
      message.command = VideoStreamCommandTypeEnum.ERROR_STREAM;
    } else {
      message = this._messageCreationUtils.createErrorStreamMessage(error);
    }
    this.send(message);
  }

  public openVideoInFullScreen(): void {
    const video: any = document.querySelector('video');
    if (video.requestFullscreen) {
      video.requestFullscreen();
    } else if (video.mozRequestFullScreen) {
      /* Firefox */
      video.mozRequestFullScreen();
    } else if (video.webkitRequestFullscreen) {
      /* Chrome, Safari & Opera */
      video.webkitRequestFullscreen();
    } else if (video.msRequestFullscreen) {
      /* IE/Edge */
      video.msRequestFullscreen();
    }
  }

  public getContents(room_id: number): Observable<Content[]> {
    return this.http.get<Content[]>(this.baseUrl + `finished-contents-sorted/${room_id}`, {
      withCredentials: true
    }).pipe(share());
  }

  public isTranslationStarted(room_id: number): Observable<boolean> {
    return this.http.get<boolean>(this.baseUrl + `is-translation-started/${room_id}`, {
      withCredentials: true
    }).pipe(share());
  }
}
