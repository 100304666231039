import {Component, OnInit} from '@angular/core';
import {Globals} from "../../globals";
import {UtilsService} from "../../../services/utils.service";

@Component({
  selector: 'app-block-chat-modal',
  templateUrl: './block-chat-modal.component.html',
  styleUrls: ['./block-chat-modal.component.css']
})
export class BlockChatModalComponent implements OnInit {

  constructor(public globals: Globals) {
  }

  ngOnInit(): void {
  }

  block(): void {
    this.globals.userBlockCallback.emit();
    UtilsService.closeFancyBox();
  }
}
