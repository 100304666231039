import {BaseMessage} from "./base.message";

export class UserRoomParamMessage extends BaseMessage {
  userId: number;
  roomId: number;
  muted: boolean;
  cameraDisabled: boolean;
  cameraNotAllowed: boolean;

  constructor(userId?: number, roomId?: number, muted?: boolean, cameraDisabled?: boolean, cameraNotAllowed?: boolean) {
    super();
    this.userId = userId;
    this.roomId = roomId;
    this.muted = muted;
    this.cameraDisabled = cameraDisabled;
    this.cameraNotAllowed = cameraNotAllowed;
  }
}
