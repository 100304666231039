import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {VideoStreamService} from "../../../../services/video-stream.service";
import {StateService} from "../../../../services/state.service";
import {RecordPlayerStateService} from "../../../../services/states/record-player-state-service";

@Component({
  selector: '[room-player-record-controls-block]',
  templateUrl: './room-player-record-controls-block.component.html',
  styleUrls: ['./room-player-record-controls-block.component.css']
})
export class RoomPlayerRecordControlsBlockComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('div_filling', { static: false }) div_filling: ElementRef;
  @ViewChild('div_circle', { static: false }) div_circle: ElementRef;
  @ViewChild('div_full', { static: false }) div_full: ElementRef;

  public currentTime: string = "00:00:00";

  constructor(public videoStreamService: VideoStreamService,
              public subscriptionService: StateService,
              public plState: RecordPlayerStateService
  ) {
  }

  ngOnInit(): void {

    // let comp_this = this;
    // let firstContentVideo = this.plState.firstContentVideo;
    // firstContentVideo.ontimeupdate = function () {
    //   let progressPercent = firstContentVideo.currentTime / firstContentVideo.duration;
    //   comp_this.updateSlider(progressPercent);
    //   comp_this.currentTime = comp_this.timeStringFromSeconds(firstContentVideo.currentTime);
    //   comp_this.currentTime += " / ";
    //   comp_this.currentTime += comp_this.timeStringFromSeconds(firstContentVideo.duration);
    //   comp_this.subscriptionService.subscriptions.sendAdminRecordTimeUpdateEvent(firstContentVideo.currentTime);
    // };
    // firstContentVideo.onclick = function () {
    //   if (firstContentVideo.paused) {
    //     firstContentVideo.play();
    //   } else {
    //     firstContentVideo.pause();
    //   }
    // };
    // window.onkeypress = function (e) {
    //   if (e.key === ' ' || e.key === 'Spacebar') {
    //     if (firstContentVideo.paused) {
    //       firstContentVideo.play();
    //     } else {
    //       firstContentVideo.pause();
    //     }
    //   }
    //   ;
    // };
    //
    // firstContentVideo.onpause = function () {
    //   comp_this.subscriptionService.subscriptions.sendAdminRecordStopEvent();
    // }
  }

  ngAfterViewInit(): void {
    // this.plState.firstContentVideo.ontimeupdate(null);
  }

  ngOnDestroy(): void {
    window.onkeypress = null;
  }

  startVideo() {
    this.plState.firstContentVideo.play();
  }

  stopVideo() {
    this.plState.firstContentVideo.pause();
  }

  onMouseClick($event: MouseEvent) {
    let percent = $event.clientX / this.div_full.nativeElement.clientWidth;
    this.updateSlider(percent);
    // this.updateVideoCurrentTime(percent);
  }

  private updateSlider(percent) {
    let strPercent = (percent * 100).toFixed(2);

    let div_filling = this.div_filling.nativeElement as HTMLDivElement;
    let div_circle = this.div_circle.nativeElement as HTMLDivElement;

    div_filling.style.width = "calc(" + strPercent + "% - 3px/2)";
    div_circle.style.left = "calc(" + strPercent + "% - 9px/2)";
  }

  private updateVideoCurrentTime(percent) {
    // this.plState.firstContentVideo.currentTime = this.firstContentVideo.duration * percent;
  }

  forwardVideo() {
  }

  backVideo() {
    this.plState.firstContentVideo.currentTime -= 10;
  }

  private timeStringFromSeconds(seconds: number) {

    if (isNaN(seconds)) {
      return "00:00:00";
    }

    let hours = Math.floor(seconds / 3600);
    let minutes = Math.floor((seconds - (hours * 3600)) / 60);
    let curSeconds = seconds - (hours * 3600) - (minutes * 60);

    let hoursString = hours.toString();
    let minutesString = minutes.toString();
    let secondsString = curSeconds.toFixed(0).toString();
    if (hours < 10) {
      hoursString = "0" + hours.toString();
    }
    if (minutes < 10) {
      minutesString = "0" + minutes;
    }
    if (curSeconds < 10) {
      secondsString = "0" + secondsString;
    }

    return hoursString + ':' + minutesString + ':' + secondsString;
  }
}
