import {Component, ElementRef, Input, ViewChild} from '@angular/core';
import {ChatAttachment} from "../../../../model/chatAttachment";
import {Room} from "../../../../model/room.model";
import {StringUtils} from "../../../../utils/string-utils";
import {StateService} from "../../../../services/state.service";
import {Globals} from "../../../globals";
import {ChatService} from "../../../../services/chat.service";
import {AttachmentService} from "../../../../services/attachment.service";
import {ChatMessage} from "../../../../model/message/chat.message";
import {ChatMessageTypeEnum} from "../../../../model/enum/chat-message-type.enum";

declare const $;

@Component({
  selector: '[app-room-chat-controls]',
  templateUrl: './room-chat-controls.component.html',
  styleUrls: ['./room-chat-controls.component.css']
})
export class RoomChatControlsComponent {
  @ViewChild("chatMessage", {static: true}) chatMessage: ElementRef;
  @Input() public room: Room;
  @ViewChild('file') fileInput;

  attachments: ChatAttachment[];
  messageText: string;

  constructor(public globals: Globals,
              public subscriptionService: StateService,
              public chatService: ChatService,
              private attachmentService: AttachmentService) {
    this.messageText = '';
    this.attachments = [];
  }

  chatMessageOnKeyDownEnter(event: any): void {
    event.stopPropagation();
    event.preventDefault();
    this.sendMessageToChat();
  }

  chatMessageAddNewLine(event: any): void {
    event.stopPropagation();
    event.preventDefault();
    this.messageText += '\n';
  }

  sendMessageToChat(): void {
    if (this.canSendMessage()) {
      const chatMessage = new ChatMessage();
      chatMessage.roomId = this.room.id;
      chatMessage.userId = this.globals.user.id;
      chatMessage.userName = this.globals.user.name;
      chatMessage.userPictureUrl = this.globals.user.pictureUrl;
      chatMessage.message = this.messageText;
      chatMessage.messageType = ChatMessageTypeEnum.USER;
      chatMessage.attachments = this.attachments.filter(a => a.complete && !a.error);
      this.chatService.send(chatMessage);
      this.messageText = '';
      this.attachments = [];
      document.getElementById('chat-message').focus();
    }
  }

  canSendMessage() {
    return !this.subscriptionService.translationIsFinished &&
      (StringUtils.isNotEmpty(this.messageText) || this.attachments.filter(value => !value.error).length !== 0) &&
      this.attachments.filter(value => value.complete !== true).length === 0;
  }

  filesSelected(element): void {
    this.uploadFiles(element.target.files);
    this.chatMessage.nativeElement.focus();
  }

  public uploadFiles(files: FileList): void {
    this.attachmentService.attach(this.room.id, files).forEach(a => this.attachments.push(a));
  }

  deleteAttachment(attachment: ChatAttachment): void {
    if (attachment.progress < 100) {
      this.attachmentService.cancelUploading(attachment);
    }

    this.attachments = this.attachments.filter(a => a !== attachment);
  }

  public getFileSize(attachment: ChatAttachment): string {
    return StringUtils.convertToFileSize(attachment.fileSize);
  }

  isOversize(attachment: ChatAttachment): boolean {
    //100 MB
    return attachment.fileSize > 100 * 1024 * 1024;
  }
}
