import {WsEventEnum} from '../enum/ws-event.enum';
import {Header} from "./media-server/header-param";
import {Body} from "./media-server/body-param";
import {BaseWsEvent} from "./media-server/base-ws.event";

export class WsMessageEvent implements BaseWsEvent {
  readonly event: WsEventEnum;
  readonly header: Header;
  readonly body: Body;


  constructor(json: string) {
    let parsed = JSON.parse(json);
    if (parsed["event"]) {
      this.event = parsed["event"];
    }
    if (parsed["header"]) {
      this.header = new Header(parsed["header"]['id']);
    }
    if (parsed["body"]) {
      this.body = new Body(parsed["body"])
    }
  }
}
