import {Component} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {UtilsService} from '../services/utils.service';
import {Globals} from './globals';
import {LanguageService} from '../services/language.service';
import {AuthService} from '../services/auth.service';
import {BROWSERS, DeviceDetectorService} from "ngx-device-detector";
import {CookieService} from "ngx-cookie-service";
import {StateService} from "../services/state.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  private unsupportedCookieName = 'unsupported_popup_shown';
  private unsupportedBrowser: boolean;
  public oldBrowser: boolean;

  constructor(
    public globals: Globals,
    private languageService: LanguageService,
    private authService: AuthService,
    private router: Router,
    private deviceService: DeviceDetectorService,
    private cookieService: CookieService,
    public subscriptionService: StateService) {

    authService.authenticate();

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        setTimeout(() => {
          UtilsService.generalScripts();
        });
      }
    });

    this.checkBrowser();
  }

  private checkBrowser(): void {
    this.unsupportedBrowser = false;
    this.oldBrowser = false;

    const deviceInfo = this.deviceService.getDeviceInfo();
    const versionComp = deviceInfo.browser_version.split('.');
    const version = parseFloat(versionComp[0] + (versionComp.length > 1 ? '.' + versionComp[1] : ''));

    switch (deviceInfo.browser) {
      case BROWSERS.CHROME:
        if (version < 77) {
          this.oldBrowser = true;
        }
        break;
      case BROWSERS.FIREFOX:
        if (version < 70) {
          this.oldBrowser = true;
        }
        break;
      case BROWSERS.SAFARI:
        if (version < 12.1) {
          this.oldBrowser = true;
        }
        break;
      case BROWSERS.OPERA:
        if (version < 60) {
          this.oldBrowser = true;
        }
        break;
      default:
        this.unsupportedBrowser = true;
    }
    if (this.unsupportedBrowser || this.oldBrowser) {
      if ('true' !== this.cookieService.get(this.unsupportedCookieName)) {
        this.globals.showUnsupportedModal = true;
        this.cookieService.set(this.unsupportedCookieName, 'true',
          new Date(new Date().getTime() + 2 * 60 * 1000));
      }
    }
  }
}
