import {Header} from "./header-param";
import {WsEventEnum} from "../../enum/ws-event.enum";
import {BaseWsEvent} from "./base-ws.event";

export class IoCloseEvent implements BaseWsEvent {
  readonly event: WsEventEnum = WsEventEnum.IO_CLOSE;
  readonly header: Header;
  readonly body: any;

  constructor(inputId: string) {
    this.header = new Header(inputId);
  }
}
