import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Globals} from '../../globals';
import {RoomService} from '../../../services/room.service';
import {UserService} from '../../../services/user.service';
import {Room} from '../../../model/room.model';
import {HttpErrorResponse} from '@angular/common/http';
import {NGXLogger} from "ngx-logger";

@Component({
  selector: 'room-access-denied-page-component',
  templateUrl: './room-access-denied-page.component.html'
})
export class RoomAccessDeniedPageComponent implements OnInit {
  roomId: string;
  room: Room;

  constructor(public globals: Globals,
              public userService: UserService,
              private roomService: RoomService,
              private route: ActivatedRoute,
              private router: Router,
              private logger: NGXLogger) {

    this.initChangeRoleSubscription();
  }

  private initChangeRoleSubscription(): void {
    this.userService.onCurrentUserRoomRoleUpdate
      .subscribe((changeRoleEvent) => {
        if (!changeRoleEvent.newRole.isDeleted) {
          this.goToRoomPage();
        }
      });
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.roomId = params['id'];
      if (this.roomId) {
        this.roomService.getByLink(this.roomId).subscribe((room: Room) => {
            this.room = room;
            this.globals.room = room;
            this.userService.createWsConnection(room.id);
          }, (error: HttpErrorResponse) => {
            this.logger.error("Getting room by link error", error);
          }
        );
      } else {
        this.goToMainPage();
      }
    });
  }

  goToMainPage(): void {
    this.router.navigate(['/']);
  }

  goToRoomPage(): void {
    this.router.navigate([`/room/id/${this.roomId}`]);
  }
}
