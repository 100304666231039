import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from "@angular/router";
import {Observable, Subject} from "rxjs";
import {Injectable} from "@angular/core";
import {Globals} from "../globals";
import {AuthService} from "../../services/auth.service";
import {User} from "../../model/user.model";

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(private router: Router,
              private globals: Globals,
              private authService: AuthService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.globals.authenticated === null) {
      const subject = new Subject<boolean>();

      this.authService.authenticate(() => {
        subject.next(this.checkRoles(this.globals.user, route));
        subject.complete();
      });

      return subject.asObservable();
    } else {
      return this.checkRoles(this.globals.user, route);
    }
  }

  private checkRoles(user: User, route: ActivatedRouteSnapshot) {
    if (!route.data.roles) {
      return true;
    } else if (!user || !user.id || (route.data.roles && route.data.roles.indexOf(user.globalRole) === -1)) {
      document.location.href = document.location.origin + '/' + route.url.map(u => u.path).join('/');
      return false;
    } else {
      return true;
    }
  }
}
