import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {FormUtils} from "../../../utils/form-utils";
import {environment} from "../../../environments/environment";
import {Subscription} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {NGXLogger} from "ngx-logger";

@Component({
  selector: 'app-set-new-password-page',
  templateUrl: './set-new-password-page.component.html',
  styleUrls: ['./set-new-password-page.component.css']
})
export class SetNewPasswordPageComponent implements OnInit, OnDestroy {
  private readonly apiUrl = environment.apiUrl;

  private paramsSubscription: Subscription;
  private token: string;

  public passwordSaved = false;
  public form: FormGroup;

  constructor(public route: ActivatedRoute,
              private router: Router,
              private fb: FormBuilder,
              private http: HttpClient,
              private logger: NGXLogger) {
    this.form = this.fb.group(
      {
        password: new FormControl("",
          [Validators.required,
            Validators.maxLength(20),
            Validators.minLength(4)]),
        confirmPassword: new FormControl("",
          [Validators.required,
            Validators.maxLength(20),
            Validators.minLength(4)])
      },
      {
        validators: [FormUtils.arePasswordsMatch, this.regCheck]
      }
    );
  }

  private regCheck(group: FormGroup): void {
    if (group.controls['password'].hasError("serverError") ||
      group.controls['confirmPassword'].hasError("serverError")) {
      group.controls['password'].setErrors(null);
      group.controls['confirmPassword'].setErrors(null);
    }
  }

  ngOnInit(): void {
    this.paramsSubscription = this.route.params.subscribe((params) => {
      this.token = params['token'];
      if (!this.token) {
        this.router.navigateByUrl("/error/404");
      }
    });
  }

  ngOnDestroy(): void {
    this.paramsSubscription.unsubscribe();
  }

  submitPassword(): void {
    this.form.markAllAsTouched();
    if (!this.form.pristine && this.form.valid) {
      this.changePassword();
    }
  }

  changePassword() {
    let formData: any = new FormData();
    formData.append("password", this.form.get('password').value);
    formData.append("confirmPassword", this.form.get('confirmPassword').value);

    this.http.post(this.apiUrl + 'login/restore/' + this.token, formData, { responseType: 'text' }).subscribe(
      (response) => {
        this.passwordSaved = true;
        setTimeout(() => this.goToLogin(), 1500);
      },
      (error: HttpErrorResponse) => {
        this.form.controls["password"].setErrors({ serverError: true });
        this.form.controls["confirmPassword"].setErrors({ serverError: true });
        this.logger.error("Change password error", error);
      }
    )
  }

  goToLogin(): void {
    this.router.navigate(['/login']);
  }
}
