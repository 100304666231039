import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-devices-common-error-modal',
  templateUrl: './devices-common-error-modal.component.html',
  styleUrls: ['./devices-common-error-modal.component.css']
})
export class DevicesCommonErrorModalComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
