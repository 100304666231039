import {Component, Input} from "@angular/core";
import {Router} from "@angular/router";

@Component({
  selector: '[hall-room-list-empty-block-component]',
  templateUrl: './hall-room-list-empty-block.component.html'
})
export class HallRoomListEmptyBlockComponent {
  @Input("hallLink")
  public hallLink: string;

  constructor(private router: Router) {
  }

  public goToTheRoom(link: string): void {
    this.router.navigate([`/room/id/${link}`]);
  }

  public createRoom(): void {
    this.router.navigateByUrl(`hall/id/${this.hallLink}/new/room`);
  }
}
