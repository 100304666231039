export enum WsEventEnum {
  INPUTS_NEW = 'inputs:new',
  INPUTS_ONLINE = 'inputs:online',
  INPUTS_OFFLINE = 'inputs:offline',
  INPUTS_LIST = 'inputs:list',
  OUTPUTS_NEW = 'outputs:new',
  IO_CLOSE = 'io:close',
  ICE_CANDIDATE = 'ice:candidate',
  SDP_ANSWER = 'sdp:answer',
  SERVER_ERROR = 'server:error'

}
