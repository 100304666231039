import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Content} from "../../../../../model/content.model";
import {MediaStreamService} from "../../../../../services/media-stream-service";
import {
  FromControlsType,
  FromMainPlayerType,
  RecordPlayerSubscriptionsService
} from "../../../../../services/subscriptions/record-player-subscriptions.service";

@Component({
  selector: '[app-record-grid-item]',
  templateUrl: './record-grid-item.component.html',
  styleUrls: ['./record-grid-item.component.css']
})
export class RecordGridItemComponent implements OnInit, AfterViewInit {

  @Input() airContents: Content[] = [];
  @Output() airContentsChange: EventEmitter<Content[]> = new EventEmitter<Content[]>();

  @Input() content: Content;
  @Input() isFirstContent: boolean;
  @Input() contentsStartMap: Map<Content, number> = new Map<Content, number>();

  @Output() recalculate = new EventEmitter<void>();

  private htmlVideoElement: HTMLVideoElement;

  constructor(
    public mediaStreamService: MediaStreamService,
    public plSubs: RecordPlayerSubscriptionsService
  ) {
  }

  ngOnInit(): void {
    this.sendRecalculate();
  }

  ngAfterViewInit(): void {

    this.htmlVideoElement = document.getElementById(this.content.contentId) as HTMLVideoElement;
    let urlStartDateTime = this.content.startTime.replace('T', '-').substring(0, 19);
    let sourceUrl = `https://dvr02-tele2test.bradburylab.tv/stream/${this.content.contentId}/hls/playlist.m3u8?start=${urlStartDateTime}&duration=9999999999`;
    // let sourceUrl = `https://bitdash-a.akamaihd.net/content/MI201109210084_1/m3u8s/f08e80da-bf1d-4e3d-8899-f0f6155f6efa.m3u8`;

    this.mediaStreamService.initializeHlsVideo(this.htmlVideoElement, sourceUrl);

    if (this.isFirstContent) {
      this.initializeFirstContentPlayer();
    } else {
      this.initializeOtherContentPlayer();
    }
  }

  private initializeOtherContentPlayer() {
    this.htmlVideoElement.play();
    this.plSubs.fromMainPlayerObs.subscribe((message) => {
      switch (message.type) {
        case FromMainPlayerType.PROGRESS_UPDATED:
          console.log("content " + this.content.contentId + " time " + (this.htmlVideoElement.currentTime + message.value.currentTime));
          break;
        case FromMainPlayerType.PLAYED:
          this.htmlVideoElement.play();
          break;
        case FromMainPlayerType.STOPPED:
          this.htmlVideoElement.pause();
          break;
      }
    })
  }
  private checkForNewContents(mainPlayer: HTMLVideoElement) {
    for (const entry of this.contentsStartMap.entries()) {

      let content: Content = entry[0];
      if (!this.airContents.includes(content)) {
        let secondsToStart = entry[1];

        if (mainPlayer.currentTime > secondsToStart) {
          this.airContents.push(content);
          this.airContentsChange.emit(this.airContents);
        }
      }

    }
  }

  private initializeFirstContentPlayer() {
    let comp_this = this;
    this.htmlVideoElement.ontimeupdate = function () {
      console.log("content " + comp_this.content.contentId + " time " + comp_this.htmlVideoElement.currentTime);
      comp_this.checkForNewContents(comp_this.htmlVideoElement);
      comp_this.plSubs.sendProgressUpdatedFromMainPlayer(comp_this.htmlVideoElement);
    };

    this.htmlVideoElement.onplay = function () {
      comp_this.plSubs.sendPlayedMainPlayer();
    };

    this.htmlVideoElement.onpause = function () {
      comp_this.plSubs.sendStoppedFromMainPlayer();
    };

    // htmlVideoElement.play();
    this.plSubs.fromControlsObs.subscribe((message) => {
      switch (message.type) {
        case FromControlsType.UPDATE_PROGRESS:
          let percent = message.value as number;

          this.htmlVideoElement.currentTime = this.htmlVideoElement.duration * percent;
          break;
        case FromControlsType.PLAY:
          this.htmlVideoElement.play();
          break;
        case FromControlsType.STOP:
          this.htmlVideoElement.pause();
          break;

        case FromControlsType.FORWARD_VIDEO:
          let f_seconds = message.value as number;
          this.htmlVideoElement.currentTime = this.htmlVideoElement.currentTime + f_seconds;
          break;

        case FromControlsType.BACK_VIDEO:
          let b_seconds = message.value as number;
          this.htmlVideoElement.currentTime = this.htmlVideoElement.currentTime - b_seconds;
          break;
      }
    })
  }

  private updateVideoCurrentTime(percent) {
    // this.plState.firstContentVideo.
  }

  ngOnDestroy(): void {
    this.sendRecalculate();
  }

  sendRecalculate(): void {
    this.recalculate.emit();
  }


  // let comp_this = this;
  // let firstContentVideo = this.plState.firstContentVideo;
  // firstContentVideo.ontimeupdate = function () {
  //   let progressPercent = firstContentVideo.currentTime / firstContentVideo.duration;
  //   comp_this.updateSlider(progressPercent);
  //   comp_this.currentTime = comp_this.timeStringFromSeconds(firstContentVideo.currentTime);
  //   comp_this.currentTime += " / ";
  //   comp_this.currentTime += comp_this.timeStringFromSeconds(firstContentVideo.duration);
  //   comp_this.subscriptionService.subscriptions.sendAdminRecordTimeUpdateEvent(firstContentVideo.currentTime);
  // };
  // firstContentVideo.onclick = function () {
  //   if (firstContentVideo.paused) {
  //     firstContentVideo.play();
  //   } else {
  //     firstContentVideo.pause();
  //   }
  // };
  // window.onkeypress = function (e) {
  //   if (e.key === ' ' || e.key === 'Spacebar') {
  //     if (firstContentVideo.paused) {
  //       firstContentVideo.play();
  //     } else {
  //       firstContentVideo.pause();
  //     }
  //   }
  //   ;
  // };
  //
  // firstContentVideo.onpause = function () {
  //   comp_this.subscriptionService.subscriptions.sendAdminRecordStopEvent();
  // }


}
