import {Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild} from '@angular/core';

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.css']
})
export class DatepickerComponent implements OnInit {
  @Input() placeholder: string;
  @Input() tabindex: number;
  @Input() disabled: boolean;

  @Output() valueChange = new EventEmitter<string>();

  @Output() onInput = new EventEmitter<Event>();
  @Output() onEnter = new EventEmitter<Event>();

  @ViewChild('calendar', { static: true }) calendar: ElementRef;
  @ViewChild('inputWrapper', { static: true }) inputWrapper: ElementRef;

  private _value: string;

  opened: boolean;
  year: number;
  month: number;
  selectedYear: number;
  selectedMonth: number;
  selectedDay: number;
  days: number[][];

  constructor() {
  }

  @Input()
  set value(value: string) {
    if (value) {
      const vals = value.split('.');
      if (vals.length === 3 && vals[0].length > 0 && vals[1].length > 0 && vals[2].length == 4) {
        this.fillData(parseInt(vals[0]), parseInt(vals[1]) - 1, parseInt(vals[2]));
      }
    }
  }

  get value(): string {
    return this._value;
  }

  ngOnInit(): void {
    this.opened = false;
    if (!this._value) {
      const now = new Date();
      this.fillData(now.getDate(), now.getMonth(), now.getFullYear());
    }
  }

  fillData(day, month, year): void {
    this.selectedYear = this.year = year;
    this.selectedMonth = this.month = month;
    this.selectedDay = day;
    this.days = [];
    this._value = this.formatDate();
    this.fillMonth();
  }

  onChange(event: Event): void {
    this.onInput.emit(event);
    this.valueChange.emit(this._value);
  }

  changeOpened(opened: boolean): void {
    this.opened = opened;
  }

  @HostListener('document:click', ['$event'])
  onClick(event) {
    if (this.opened && !this.inputWrapper.nativeElement.contains(event.target) &&
      !this.calendar.nativeElement.contains(event.target)) {
      this.changeOpened(false);
    }
  }

  next(): void {
    if (this.month >= 11) {
      this.month = 0;
      this.year++;
    } else {
      this.month++;
    }
    this.fillMonth();
  }

  previous(): void {
    if (this.month <= 0) {
      this.month = 11;
      this.year--;
    } else {
      this.month--;
    }
    this.fillMonth();
  }

  fillMonth(): void {
    this.days.splice(0);
    const date = new Date(this.year, this.month, 1);
    let week: number[] = [];

    while (date.getMonth() === this.month) {
      let day = date.getDay();
      if (day === 0) {
        day = 7;
      }
      week[day - 1] = date.getDate();
      if (day === 7) {
        this.days.push(week);
        week = [];
      }
      date.setDate(date.getDate() + 1);
    }
    if (week.length > 0) {
      this.days.push(week);
    }
  }

  choseDay(day: number): void {
    this.changeOpened(false);

    this.selectedDay = day;
    this.selectedMonth = this.month;
    this.selectedYear = this.year;

    this._value = this.formatDate();

    this.onChange(null);
  }

  formatDate(): string {
    const day = this.selectedDay < 10 ? '0' + this.selectedDay : String(this.selectedDay);
    const month = (this.selectedMonth + 1) < 10 ? '0' + (this.selectedMonth + 1) : String(this.selectedMonth + 1);
    const year = this.selectedYear;

    return [day, month, year].join('.');
  }
}
