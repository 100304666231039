import {Component, Input, OnInit} from '@angular/core';
import {Globals} from "../../globals";

@Component({
  selector: 'app-unsupported-browser',
  templateUrl: './unsupported-browser.component.html',
  styleUrls: ['./unsupported-browser.component.css']
})
export class UnsupportedBrowserComponent implements OnInit {
  @Input() old: boolean;

  constructor(public globals: Globals) {
  }

  ngOnInit(): void {
  }

  close(): void {
    this.globals.showUnsupportedModal = false;
  }
}
