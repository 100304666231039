import {Header} from "./header-param";
import {WsInputTypeEnum} from "../../enum/ws-input-type.enum";
import {WsEventEnum} from "../../enum/ws-event.enum";
import {BaseWsEvent} from "./base-ws.event";

export class InputsNewEvent implements BaseWsEvent {
  readonly event: WsEventEnum;
  readonly header: Header;
  readonly body: InputsNewBody;

  constructor(headerId: string, sdp: string, kind: WsInputTypeEnum, contentId: string) {
    this.event = WsEventEnum.INPUTS_NEW;
    this.header = new Header(headerId);
    this.body = new InputsNewBody(sdp, kind, contentId);
  }

  addFlags(audio: boolean, video: boolean): void {
    if (audio && video) {
      this.body.flags = ['video', 'audio'];
    } else if (audio) {
      this.body.flags = ['audio'];
    } else {
      this.body.flags = ['video'];
    }
  }

  addMeta(timestamp: Date, userId: any, browser: string): void {
    this.body.meta = new Meta(timestamp, userId, browser);
  }

  addVideo(width: number, height: number, bitrate?: string): void {
    this.body.video = new Video(width, height, bitrate);
  }
}

export class InputsNewBody {
  readonly kind: WsInputTypeEnum;
  readonly sdp: string;
  readonly content_id: string;
  video: Video;
  meta: Meta;
  flags: string[];


  constructor(sdp: string, kind: WsInputTypeEnum, contentId: string) {
    this.kind = kind;
    this.sdp = sdp;
    this.content_id = contentId;
  }
}

export class Video {
  width: number;
  height: number;
  bitrate: string; // Optional


  constructor(width: number, height: number, bitrate?: string) {
    this.width = width;
    this.height = height;
    this.bitrate = bitrate;
  }
}

export class Meta {
  timestamp: Date;
  user_id: any;
  browser: string;

  constructor(timestamp: Date, user_id: any, browser: string) {
    this.timestamp = timestamp;
    this.user_id = user_id;
    this.browser = browser;
  }
}
