import {Component, DoCheck, Input, OnInit} from '@angular/core';
import {StateService} from "../../../../services/state.service";
import {Room} from "../../../../model/room.model";
import {NotificationsService} from "../../../../services/notifications.service";

@Component({
  selector: '[app-room-player-time-to-start-component]',
  template:
    '{{ \'room.stream-time-to-start\' | translate: ' +
    '{' +
    'day: day, day_caption: day_caption,' +
    'hour: hour, hour_caption: hour_caption,' +
    'minute: minute, minute_caption: minute_caption' +
    '} ' +
    '}}'
})
export class RoomPlayerTimeToStartComponentComponent implements OnInit, DoCheck {
  @Input('room') room: Room;

  createdAt: any;
  day: Number;
  hour: Number;
  minute: Number;

  day_caption: string;
  hour_caption: string;
  minute_caption: string;

  notificationShown: boolean;

  constructor(public subscriptionService: StateService,
              private notificationsService: NotificationsService) {
  }

  ngOnInit() {
    setInterval(() => {
      let currentTime: any = new Date();

      let timeZoneNumber: number = (-currentTime.getTimezoneOffset() / 60);


      let timeZoneString: string
      if (timeZoneNumber > 0) {
        timeZoneString = '+0' + timeZoneNumber + ':00';
      } else {
        timeZoneString = '-0' + -timeZoneNumber + ':00';
      }

      // '2018-02-06T20:00:00.000+00:00'
      let dateStart: any = new Date(this.room.dateStart + 'T' + this.room.timeStart + '.000' + timeZoneString);

      dateStart.setMinutes(dateStart.getMinutes() + 1);

      let delta = (dateStart - currentTime) / 1000;

      // console.log("currentTime " + currentTime);
      // console.log("dateStart " + dateStart);
      //
      // console.log("delta " + delta);

      if (delta > 0) {
        let dayCount = Math.floor(delta / 86400);
        delta -= dayCount * 86400;
        let hourCount = Math.floor(delta / 3600) % 24;
        delta -= hourCount * 3600;
        let minuteCount = Math.ceil(delta / 60) % 60;

        this.day = dayCount;
        this.hour = hourCount;
        this.minute = minuteCount;
      } else {
        this.day = 0;
        this.hour = 0;
        this.minute = 0;
      }

      if (this.day === 0 && this.hour === 0 && this.minute === 5 && !this.notificationShown) {
        this.notificationsService.sendTranslationWillStart(this.room?.name);
        this.notificationShown = true;
      }

      this.day_caption = this.num2str(this.day, ["день", "дня", "дней"]);
      this.hour_caption = this.num2str(this.hour, ["час", "часа", "часов"]);
      this.minute_caption = this.num2str(this.minute, ["минуту", "минуты", "минут"]);
    }, 1000)
  }

  private num2str(n, text_forms): string {
    n = Math.abs(n) % 100;
    var n1 = n % 10;
    if (n > 10 && n < 20) {
      return text_forms[2];
    }
    if (n1 > 1 && n1 < 5) {
      return text_forms[1];
    }
    if (n1 == 1) {
      return text_forms[0];
    }
    return text_forms[2];
  }

  ngDoCheck(): void {
    // console.log('RoomPlayerTimeToStartComponentComponent ngDoCheck room = ' + this.room);
  }
}
