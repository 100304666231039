import {Subject} from "rxjs";

export class StompConfiguration<T> {
  private readonly _roomId: number;
  private readonly _wsPath: string;
  private readonly _messageSubject: Subject<T>;
  private readonly _messagesSubject: Subject<T[]>;
  private readonly _connectedSubject: Subject<void>;
  private readonly _senderSubject: Subject<T>;
  private readonly _tClass: new () => T;


  constructor(roomId: number,
              wsPath: string,
              messageSubject: Subject<T>,
              messagesSubject: Subject<T[]>,
              connectedSubject: Subject<void>,
              senderSubject: Subject<T>,
              tClass: new () => T) {
    this._roomId = roomId;
    this._wsPath = wsPath;
    this._messageSubject = messageSubject;
    this._messagesSubject = messagesSubject;
    this._connectedSubject = connectedSubject;
    this._senderSubject = senderSubject;
    this._tClass = tClass;
  }


  get tClass(): { new(): T } {
    return this._tClass;
  }

  get roomId(): number {
    return this._roomId;
  }

  get wsPath(): string {
    return this._wsPath;
  }

  get messageSubject(): Subject<T> {
    return this._messageSubject;
  }

  get messagesSubject(): Subject<T[]> {
    return this._messagesSubject;
  }

  get connectedSubject(): Subject<void> {
    return this._connectedSubject;
  }

  get senderSubject(): Subject<T> {
    return this._senderSubject;
  }
}
