import {Component, OnInit} from '@angular/core';
import {environment} from "../../../environments/environment";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {Globals} from "../../globals";
import {AuthService} from "../../../services/auth.service";
import {Router} from "@angular/router";
import {FormUtils} from "../../../utils/form-utils";
import {NGXLogger} from "ngx-logger";

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.css']
})
export class LoginPageComponent implements OnInit {
  private readonly apiUrl = environment.apiUrl;

  public tabId: string;
  public loginForm: FormGroup;
  public regForm: FormGroup;

  constructor(public globals: Globals,
              public authService: AuthService,
              private router: Router,
              private fb: FormBuilder,
              private http: HttpClient,
              private logger: NGXLogger) {
    this.tabId = 'auth';

    this.loginForm = this.fb.group({
        email: new FormControl('', [Validators.email, Validators.required]),
        password: new FormControl('', [Validators.required])
      },
      {
        validators: this.loginCheck
      });

    this.regForm = this.fb.group(
      {
        email: new FormControl("",
          [Validators.required,
            Validators.email,
            Validators.maxLength(100),
            Validators.minLength(5)]),
        name: new FormControl("",
          [Validators.required,
            Validators.maxLength(100),
            Validators.minLength(1)]),
        password: new FormControl("",
          [Validators.required,
            Validators.maxLength(20),
            Validators.minLength(4)]),
        confirmPassword: new FormControl("",
          [Validators.required,
            Validators.maxLength(20),
            Validators.minLength(4)])
      },
      {
        validators: [FormUtils.arePasswordsMatch, this.regCheck]
      }
    );
  }

  ngOnInit(): void {
  }

  switchTab(tabId: string): void {
    this.tabId = tabId;
  }

  private loginCheck(group: FormGroup): void {
    if (group.controls['email'].hasError("login")) {
      group.controls['email'].setErrors(null);
    }
    if (group.controls['password'].hasError("login")) {
      group.controls['password'].setErrors(null);
    }
  }

  private regCheck(group: FormGroup): void {
    if (group.controls['email'].hasError("serverError") ||
      group.controls['email'].hasError("exist")) {
      group.controls['email'].setErrors(null);
    }
  }

  submitLogin(): void {
    if (!this.loginForm.pristine && this.loginForm.valid) {
      this.login();
    }
  }

  submitRegistration(): void {
    if (!this.regForm.pristine && this.regForm.valid) {
      this.registration();
    }
  }

  login(): void {
    let formData: any = new FormData();
    formData.append("email", this.loginForm.get('email').value);
    formData.append("password", this.loginForm.get('password').value);

    this.http.post(this.apiUrl + 'login', formData, { responseType: 'text' }).subscribe(
      (response) => {
        this.auth(response);
      },
      (error: HttpErrorResponse) => {
        if (error.status === 403) {
          this.loginForm.controls["email"].setErrors({ login: true });
          this.loginForm.controls["password"].setErrors({ login: true });
        } else {
          this.loginForm.controls["email"].setErrors(null);
          this.loginForm.controls["password"].setErrors(null);
        }
        this.logger.error("Authentication error", error);
      }
    )
  }

  registration() {
    let formData: any = new FormData();
    formData.append("email", this.regForm.get('email').value);
    formData.append("name", this.regForm.get('name').value);
    formData.append("password", this.regForm.get('password').value);
    formData.append("confirmPassword", this.regForm.get('confirmPassword').value);

    this.http.post(this.apiUrl + 'login/reg', formData, { responseType: 'text' }).subscribe(
      (response) => {
        this.auth(response);
      },
      (error: HttpErrorResponse) => {
        if (error.status === 302) {
          this.regForm.controls["email"].setErrors({ exist: true });
        } else {
          this.regForm.controls["email"].setErrors({ serverError: true });
        }
        this.logger.error("Registration error", error);
      }
    )
  }

  private auth(response: string): void {
    this.authService.authenticate(() => {
      if (response) {
        this.router.navigateByUrl(response);
      } else {
        this.router.navigateByUrl("/");
      }
    });
  }
}
