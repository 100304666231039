export class CamDisabledEvent {
  private readonly _disabled: boolean;
  private readonly _userId: number;
  private readonly _streamId: number;

  constructor(userId: number, streamId: number, disabled: boolean) {
    this._disabled = disabled;
    this._userId = userId;
    this._streamId = streamId;
  }

  get streamId(): number {
    return this._streamId;
  }

  get disabled(): boolean {
    return this._disabled;
  }

  get userId(): number {
    return this._userId;
  }
}
