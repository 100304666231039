import {VideoStreamCommandTypeEnum} from '../enum/video-stream-command-type.enum';
import {StreamTypeEnum} from "../enum/stream-type-enum";
import {MessageScopeEnum} from "../enum/message-scope.enum";
import {BaseMessage} from "./base.message";

export class VideoStreamMessage extends BaseMessage {
  toUserId: number;
  fromUserId: number;
  streamId: number;
  streamUserId: number;
  roomId: number;
  contentId: string;
  inputId: string;
  outputId: string;
  streamType: StreamTypeEnum;
  command: VideoStreamCommandTypeEnum;
  messageScope: MessageScopeEnum;
  statusParam: boolean;
  reason: any;
}
