import {RoomUserCommandTypeEnum} from '../enum/room-user-command-type.enum';
import {RoleTypeEnum} from '../enum/role-type.enum';
import {Role} from "../role.model";
import {BaseMessage} from "./base.message";

export class RoomUserMessage extends BaseMessage {
  userId: number;
  streamId: number;
  userName: string;
  userPictureUrl: string;
  newRole: RoleTypeEnum;
  oldRole: RoleTypeEnum;
  commandType: RoomUserCommandTypeEnum;
  isOnline: boolean;
  statusParam: boolean;
  roomRole: Role;
  reason: string;

  decode(json: Object): BaseMessage {
    super.decode(json);
    this.roomRole = new Role(this.roomRole.role); //Object.assign<Role, object>(new Role(), this.roomRole);
    return this;
  }
}
