import {environment} from "../environments/environment";
import {OnDestroy} from "@angular/core";
import {Subject} from "rxjs";
import {StompConfiguration} from "./stomp.configuration";
import {BaseMessage} from "../model/message/base.message";
import {NGXLogger} from "ngx-logger";

declare const SockJS: any;
declare const Stomp: any;

export class StompWrapper<T extends BaseMessage> implements OnDestroy {
  private readonly wsUrl = environment.apiUrl;
  private readonly appWsUrl: string;
  private readonly topicWsUrl: string;
  private client: any;
  private readonly roomId: number;
  private readonly _messageSubject: Subject<T>;
  private readonly _senderSubject: Subject<T>;
  private readonly _messagesSubject: Subject<T[]>;
  private readonly _connectedSubject: Subject<void>;
  private readonly _sendQueue: T[];

  private readonly connectionAttemptsNumber = 10;
  private currentAttempt = 0;
  private shouldBeConnected = false;

  private tClass: new (json?: object) => T;


  constructor(stompConfiguration: StompConfiguration<T>,
              private logger: NGXLogger) {
    this.tClass = stompConfiguration.tClass
    this.roomId = stompConfiguration.roomId;
    this.wsUrl += stompConfiguration.wsPath;
    this.appWsUrl = `/app/${stompConfiguration.wsPath}/${stompConfiguration.roomId}`;
    this.topicWsUrl = `/topic/${stompConfiguration.wsPath}/${stompConfiguration.roomId}`;
    this._messageSubject = stompConfiguration.messageSubject;
    this._messagesSubject = stompConfiguration.messagesSubject;
    this._connectedSubject = stompConfiguration.connectedSubject;
    this._sendQueue = [];
    this._senderSubject = stompConfiguration.senderSubject;
    if (this._senderSubject) {
      this._senderSubject
        .asObservable()
        .subscribe((message) => {
          if (this.client.connected) {
            this.send(message);
          } else {
            this.logger.warn("Stomp client is connected: {}, push message to queue: {}", this.client.connected, message);
            this._sendQueue.push(message);
            if (this.shouldBeConnected && this.currentAttempt === 0) {
              this.connect();
            }
          }
        })
    }
    this.connect();
  }

  get isConnected(): boolean {
    return this.client.connected;
  }

  public reconnect(): void {
    this.disconnect();
    setTimeout(() => {
      this.connect();
    }, 150);
  }

  private send(message: T): void {
    if (this.client) {
      try {
        this.client.send(message.destWsPath ? message.destWsPath : this.appWsUrl, {}, JSON.stringify(message));
      } catch (e) {
        this.logger.error("this.client.send failed {}", e);
        console.error(e);
      }

    }
  }

  private connect(): void {
    const socket = new SockJS(this.wsUrl);
    this.client = Stomp.over(socket);
    this.client.debug = null;
    this.currentAttempt++
    this.shouldBeConnected = true;

    this.logger.info("Trying to connect to WebSocket ({}). Attempt: {}/{}", this.wsUrl, this.currentAttempt,
      this.connectionAttemptsNumber);

    this.client.connect({}, (frame): void => {
      this.currentAttempt = 0;

      this.client.subscribe(this.appWsUrl, (response): void => {
        let messages: [] = JSON.parse(response.body);
        let ts: T[] = messages.map((message) => <T>new this.tClass().decode(message));

        if (this._messagesSubject) {
          this._messagesSubject.next(ts);
        }
      });

      this.client.subscribe(this.topicWsUrl, (response): void => {
        // console.log("get -- " + this.topicWsUrl + " -- " + response.body);
        const message: T = <T>new this.tClass().decode(JSON.parse(response.body));
        if (this._messageSubject) {
          this._messageSubject.next(message);
        }
      });

      if (this._sendQueue.length > 0) {
        this.logger.warn("Stomp client is connected, send messages from queue, size: {}", this._sendQueue.length);
        while (this._sendQueue.length > 0) {
          let message = this._sendQueue.shift();
          this.logger.warn("send from queue: {}", message);
          this.send(message);
        }
      }
      this._connectedSubject.next();
    }, (error): void => {
      this.logger.error("Stomp client.connect error: {}", error);

      if (this.currentAttempt <= this.connectionAttemptsNumber && this.shouldBeConnected) {
        this.connect();
      } else {
        this.currentAttempt = 0;
      }
    });
  }

  private disconnect(): void {
    if (this.client) {
      this.client.disconnect();
    }

    if (!environment.production) {
      this.logger.debug('Stomp Disconnected: {}', this.wsUrl);
    }

    this.currentAttempt = 0;
    this.shouldBeConnected = false;
  }

  ngOnDestroy(): void {
    this.disconnect();
  }
}
