import {Injectable} from '@angular/core';
import {HttpClient, HttpEventType} from '@angular/common/http';
import {Observable} from 'rxjs';
import {share} from 'rxjs/operators';
import {environment} from '../environments/environment';
import {ChatAttachment} from '../model/chatAttachment';
import {Globals} from '../app/globals';

@Injectable({ providedIn: 'root' })
export class AttachmentService {
  private readonly baseUrl = environment.apiUrl;

  private inProgressAttachments = new Map();

  constructor(private http: HttpClient,
              private globals: Globals) {
  }

  getAttachmentsByRoomId(roomId: number): Observable<ChatAttachment[]> {
    return this.http.get<ChatAttachment[]>(this.baseUrl + `file/${roomId}`, {
      withCredentials: true
    });
  }

  attach(roomId: number, files: FileList, completeCallback?): ChatAttachment[] {
    const attachments: ChatAttachment[] = [];

    let formData = null;

    for (let i = 0; i < files.length; i++) {
      let file = files.item(i);
      let attachment = new ChatAttachment();
      attachments.push(attachment);
      attachment.dateAdded = new Date();
      attachment.fileName = file.name;
      attachment.fileSize = file.size;
      attachment.roomId = roomId;
      attachment.progress = 0;
      attachment.complete = false;
      attachment.attachmentUserId = this.globals.user.id;

      if (file.size > 100 * 1024 * 1024) {
        attachment.progress = 100;
        attachment.complete = true;
        attachment.error = true;
      } else {
        formData = new FormData();
        formData.append('file', file);

        this.inProgressAttachments.set(file.name, this.http.post<ChatAttachment>(this.baseUrl + `file/${roomId}`, formData, {
          withCredentials: true,
          reportProgress: true,
          observe: 'events'
        }).pipe(share())
          .subscribe((httpSentEvent) => {
            switch (httpSentEvent.type) {
              case HttpEventType.UploadProgress: {
                attachment.progress = Math.round(100 * httpSentEvent.loaded / httpSentEvent.total);
                break;
              }
              case HttpEventType.Response: {
                attachment.complete = true;
                attachment.fileName = httpSentEvent.body.fileName;
                attachment.fileUrl = httpSentEvent.body.fileUrl;
                attachment.mappedName = httpSentEvent.body.mappedName;
                if (completeCallback) {
                  completeCallback(attachment);
                }
                break;
              }
            }
          }, (error) => {
            console.log(error);
            attachment.complete = true;
            attachment.error = true;
          }));
      }
    }

    return attachments;
  }

  cancelUploading(attachment: ChatAttachment): void {
    this.inProgressAttachments.get(attachment.fileName).unsubscribe();
  }

  deleteAttach(attachment: ChatAttachment): void {
    this.http.delete<void>(this.baseUrl + `file/${attachment.id}`).subscribe();
  }
}
