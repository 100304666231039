import { Component, OnInit } from '@angular/core';
import {StringUtils} from "../../../utils/string-utils";
import {ActivatedRoute, Router} from "@angular/router";
import {environment} from "../../../environments/environment";
import {Subscription} from "rxjs";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";

@Component({
  selector: 'app-room-password-page',
  templateUrl: './room-password-page.component.html',
  styleUrls: ['./room-password-page.component.css']
})
export class RoomPasswordPageComponent implements OnInit {
  private readonly apiUrl = environment.apiUrl;
  private paramsSubscription: Subscription;
  private roomId: string;

  password: string;
  passwordError: boolean;
  errorText: string;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private http: HttpClient) { }

  ngOnInit(): void {
    this.paramsSubscription = this.route.params.subscribe((params) => {
      this.roomId = params['id'];
    });
  }

  clearErrors(): void {
    this.passwordError = false;
    this.errorText = null;
  }

  auth(): void {
    this.clearErrors();
    if (StringUtils.isEmpty(this.password)) {
      this.passwordError = true;
      this.errorText = 'fields-errors.enter-room-password';
    } else {
      let formData: any = new FormData();
      formData.append("password", this.password);

      this.http.post(this.apiUrl + `room/id/${this.roomId}/password`, formData, { responseType: 'text' })
        .subscribe(() => {
          this.router.navigate([`/room/id/${this.roomId}`]);
        }, (error: HttpErrorResponse) => {
          if (error.status === 403) {
            this.errorText = 'fields-errors.password-incorrect';
          } else if (error.status === 429) {
            this.errorText = 'fields-errors.5-min-lock';
          } else if (error.status === 423) {
            this.errorText = 'fields-errors.1-hour-lock';
          } else {
            this.errorText = 'fields-errors.server-error';
            console.log(error);
          }
        });
    }
  }
}
