import {MessageTypeEnum} from "../enum/message-type.enum";

export class BaseMessage {
  destWsPath: string;
  type: MessageTypeEnum = MessageTypeEnum.OTHER;

  decode(json: Object): BaseMessage {
    Object.assign<this, object>(this, json);
    return this;
  }

  isChat(): boolean {
    return this.type === MessageTypeEnum.CHAT;
  }

  isCall(): boolean {
    return this.type === MessageTypeEnum.CALL;
  }

  isOther(): boolean {
    return this.type === MessageTypeEnum.OTHER;
  }
}
