import {StreamTypeEnum} from "../model/enum/stream-type-enum";
import {VideoStreamMessageBuilder} from "./video-stream-message.builder";
import {VideoStreamCommandTypeEnum} from "../model/enum/video-stream-command-type.enum";
import {MessageScopeEnum} from "../model/enum/message-scope.enum";
import {VideoStreamMessage} from "../model/message/video-stream.message";
import {Globals} from "../app/globals";
import {WsRtcModel} from "../model/ws-rtc.model";
import {WsEventEnum} from "../model/enum/ws-event.enum";
import {InputIdInfo} from "../model/input-id-info.model";

export class StreamMessageCreationUtils {


  constructor(private globals: Globals) {
  }

  public createPublishScreenStreamMessage(toUserId: number, reason: string): VideoStreamMessage {
    return this
      .createPublishStreamMessageBuilder(StreamTypeEnum.SCREEN_INPUT, toUserId, reason).build();
  }

  public createPublishCamStreamMessage(toUserId: number, reason: string): VideoStreamMessage {
    return this
      .createPublishStreamMessageBuilder(StreamTypeEnum.CAM_INPUT, toUserId, reason).build();
  }

  public createStopCamStreamMessage(streamId: number, toUserId: number, reason: string): VideoStreamMessage {
    return this
      .createStopStreamMessageBuilder(StreamTypeEnum.CAM_INPUT, streamId, toUserId, reason).build()
  }

  public createStopScreenStreamMessage(streamId: number, toUserId: number, reason: string): VideoStreamMessage {
    return this
      .createStopStreamMessageBuilder(StreamTypeEnum.SCREEN_INPUT, streamId, toUserId, reason).build()
  }

  public createConnectedToStreamMessage(wsRtcModel: WsRtcModel, status: boolean): VideoStreamMessage {
    return new VideoStreamMessageBuilder()
      .command(VideoStreamCommandTypeEnum.CONNECTED_TO_STREAM)
      // .contentId("")
      .fromUserId(this.globals.user.id)
      .inputId(wsRtcModel.inputId)
      .messageScope(MessageScopeEnum.USER)
      .outputId(wsRtcModel.outputId)
      .reason(wsRtcModel.closeReason)
      // .roomId(roomId)
      .statusParam(status)
      .streamId(wsRtcModel.streamId)
      .streamType(wsRtcModel.streamType)
      .streamUserId(wsRtcModel.streamUserId)
      .toUserId(this.globals.user.id)
      .build();
  }

  public createStreamOnlineMessage(inputIdInfo: InputIdInfo, status: boolean): VideoStreamMessage {
    return new VideoStreamMessageBuilder()
      .command(VideoStreamCommandTypeEnum.STREAM_ONLINE)
      // .contentId("")
      .fromUserId(this.globals.user.id)
      .inputId(inputIdInfo.inputId)
      .messageScope(MessageScopeEnum.USER)
      // .outputId(wsRtcModel.outputId)
      .reason(status ? WsEventEnum.INPUTS_ONLINE : WsEventEnum.INPUTS_OFFLINE)
      // .roomId(roomId)
      .statusParam(status)
      .streamId(inputIdInfo.streamId)
      .streamType(inputIdInfo.streamType)
      .streamUserId(inputIdInfo.streamUserId)
      .toUserId(this.globals.user.id)
      .build();
  }

  public createUpdateStreamMessage(streamId: number, reason: string): VideoStreamMessage {
    return new VideoStreamMessageBuilder()
      .command(VideoStreamCommandTypeEnum.UPDATE_STREAM)
      // .contentId("")
      .fromUserId(this.globals.user.id)
      // .inputId(inputIdInfo.inputId)
      .messageScope(MessageScopeEnum.USER)
      // .outputId(wsRtcModel.outputId)
      .reason(reason)
      // .roomId(roomId)
      // .statusParam(status)
      .streamId(streamId)
      .streamType(StreamTypeEnum.CAM_INPUT)
      .streamUserId(this.globals.user.id)
      .toUserId(this.globals.user.id)
      .build();
  }

  public createErrorStreamMessage(error: string): VideoStreamMessage {
    return new VideoStreamMessageBuilder()
      .command(VideoStreamCommandTypeEnum.ERROR_STREAM)
      // .contentId("")
      .fromUserId(this.globals.user.id)
      // .inputId(inputIdInfo.inputId)
      .messageScope(MessageScopeEnum.USER)
      // .outputId(wsRtcModel.outputId)
      .reason(error)
      // .roomId(roomId)
      // .statusParam(status)
      // .streamId(streamId)
      // .streamType(StreamTypeEnum.CAM_INPUT)
      // .streamUserId(this.globals.user.id)
      .toUserId(this.globals.user.id)
      .build();
  }

  public createFinishStreamMessage(streamId: number, roomId: number, reason: string): VideoStreamMessage {
    return new VideoStreamMessageBuilder()
      .command(VideoStreamCommandTypeEnum.FINISH_STREAM)
      // .contentId("")
      .fromUserId(this.globals.user.id)
      //.inputId("")
      .messageScope(MessageScopeEnum.ROOM)
      //.outputId("")
      .reason(reason)
      .roomId(roomId)
      //.statusParam(false)
      .streamId(streamId)
      .streamType(StreamTypeEnum.CAM_INPUT)
      .streamUserId(this.globals.user.id)
      .toUserId(this.globals.user.id)
      .build();
  }

  public createInviteUserToStreamMessage(streamId: number, toUserId: number): VideoStreamMessage {
    return new VideoStreamMessageBuilder()
      .command(VideoStreamCommandTypeEnum.INVITE_USER_TO_STREAM)
      // .contentId("")
      .fromUserId(this.globals.user.id)
      //.inputId("")
      .messageScope(MessageScopeEnum.USER)
      //.outputId("")
      // .reason(reason)
      // .roomId(roomId)
      //.statusParam(false)
      .streamId(streamId)
      // .streamType(StreamTypeEnum.CAM_INPUT)
      .streamUserId(this.globals.user.id)
      .toUserId(toUserId)
      .build();
  }

  public createUserMuteStreamMessage(streamId: number, toUserId: number, muted: boolean, reason: string): VideoStreamMessage {
    return new VideoStreamMessageBuilder()
      .command(VideoStreamCommandTypeEnum.USER_MUTE_STREAM)
      // .contentId("")
      .fromUserId(this.globals.user.id)
      //.inputId("")
      .messageScope(MessageScopeEnum.USER)
      //.outputId("")
      .reason(reason)
      // .roomId(roomId)
      .statusParam(muted)
      .streamId(streamId)
      // .streamType(StreamTypeEnum.CAM_INPUT)
      .streamUserId(toUserId)
      .toUserId(toUserId)
      .build();
  }

  public createUserCameraDisableMessage(streamId: number, toUserId: number, disabled: boolean, reason: string): VideoStreamMessage {
    return new VideoStreamMessageBuilder()
      .command(VideoStreamCommandTypeEnum.USER_CAMERA_DISABLE)
      // .contentId("")
      .fromUserId(this.globals.user.id)
      //.inputId("")
      .messageScope(MessageScopeEnum.USER)
      //.outputId("")
      .reason(reason)
      // .roomId(roomId)
      .statusParam(disabled)
      .streamId(streamId)
      // .streamType(StreamTypeEnum.CAM_INPUT)
      .streamUserId(toUserId)
      .toUserId(toUserId)
      .build();
  }

  private createPublishStreamMessageBuilder(streamTypeEnum: StreamTypeEnum, toUserId: number, reason: string): VideoStreamMessageBuilder {
    return new VideoStreamMessageBuilder()
      .command(VideoStreamCommandTypeEnum.PUBLISH_STREAM)
      // .contentId("")
      .fromUserId(this.globals.user.id)
      //.inputId("")
      .messageScope(MessageScopeEnum.USER)
      //.outputId("")
      .reason(reason)
      //.roomId(0)
      //.statusParam(false)
      //.streamId(0)
      .streamType(streamTypeEnum)
      .streamUserId(this.globals.user.id)
      .toUserId(toUserId);
  }

  private createStopStreamMessageBuilder(streamTypeEnum: StreamTypeEnum, streamId: number, toUserId: number, reason: string): VideoStreamMessageBuilder {
    return new VideoStreamMessageBuilder()
      .command(VideoStreamCommandTypeEnum.STOP_STREAM)
      // .contentId("")
      .fromUserId(this.globals.user.id)
      //.inputId("")
      .messageScope(MessageScopeEnum.USER)
      //.outputId("")
      .reason(reason)
      //.roomId(0)
      //.statusParam(false)
      .streamId(streamId)
      .streamType(streamTypeEnum)
      .streamUserId(toUserId)
      .toUserId(toUserId);
  }

}
