import {Injectable} from "@angular/core";
import {UserRoomParamMessage} from "../model/message/user-room-param.message";
import {BaseStompSupportedService} from "./base-stomp-supported.service";
import {Subscription} from "rxjs";
import {NGXLogger} from "ngx-logger";

@Injectable({ providedIn: 'root' })
export class UserRoomParamService extends BaseStompSupportedService<UserRoomParamMessage> {
  protected readonly WS_PATH: string = "user-room-param";

  private subscriptions: Subscription[] = [];

  private userParamsMap = new Map<number, UserRoomParamMessage>();

  constructor(logger: NGXLogger) {
    super(logger, UserRoomParamMessage);
    // this.logSubjects("UserRoomParamService");

    this.subscriptions.push(this.onMessage
      .subscribe((message: UserRoomParamMessage) => {
        if (message) {
          this.userParamsMap.set(message.userId, message);
        }
      }));
  }

  public getUserParams(userId: number): UserRoomParamMessage {
    return this.userParamsMap.get(userId);
  }


  ngOnDestroy() {
    super.ngOnDestroy();
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
