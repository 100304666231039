export class StringUtils {
  public static isEmpty(str: string): boolean {
    return !str || str.trim().length === 0;
  }

  public static isNotEmpty(str: string): boolean {
    return !this.isEmpty(str);
  }

  public static getFirstSymbols(name: string) {
    let result = [];

    if (this.isNotEmpty(name)) {
      name = name.trim();
      const parts = name.split(' ');

      for (let i = 0; i < parts.length && result.length < 2; i++) {
        if (parts[i].trim().length > 0) {
          result.push(parts[i].trim()[0].toUpperCase());
        }
      }
    }

    return result.join('');
  }
  static readonly colors = ['#0F2347', '#1C3F6E', '#2E67A0', '#5AACCF', '#EFFC93', '#80C271',
                     '#f66060', '#ac463a', '#AB5A88', '#43DCEF', '#0B4996', '#FADFE2',
                     '#15D7E7', '#a5a239', '#ffb24a', '#f18449',
                     '#35B2E6', '#45D0D0', '#9BDB85', '#c7b427', '#E39677', '#D26E85',
                     '#8646b3', '#d45c77', '#b6a935', '#75ef19', '#98DEF3', '#AAB3F3',
                     '#9B99FF', '#41b0ff', '#32de3b', '#99992b', '#FFBBBA', '#FF8B93'];

  public static getBackgroundColor(name: string): string {
    const symbols = this.getFirstSymbols(name);
    let sum = 0;

    for (let i = 0; i < symbols.length; i++) {
      sum += symbols.charCodeAt(i);
    }

    return this.colors[sum % this.colors.length];
  }

  public static convertToFileSize(size: number): string {
    if (isNaN(size) || !isFinite(size)) {
      return '-';
    }
    const units = ['б', 'кБ', 'МБ', 'ГБ'],
      number = Math.floor(Math.log(size) / Math.log(1024));
    return (size / Math.pow(1024, Math.floor(number))).toFixed(1) + units[number];
  }

}
