import {StreamTypeEnum} from "./enum/stream-type-enum";
import * as uuid from 'uuid';
import {InputIdInfo} from "./input-id-info.model";
import {PCStateEnum, WsRtcModelStateEnum} from "./enum/ws-rtc-model-state.enum";
import {OutputAudioVideoStreamContainer, StreamContainer} from "./stream-container";
import {Observable, Subject} from "rxjs";
import {NGXLogger} from "ngx-logger";

export class WsRtcModel {
  private readonly _id: string;
  private readonly _inputIdInfo: InputIdInfo;
  private _pc: RTCPeerConnection;
  private _streamContainer: StreamContainer;
  private _outputId: string;
  private readonly _outputUserId;
  private readonly logger:NGXLogger;
  private _state: WsRtcModelStateEnum;
  private _pcConnectionState: PCStateEnum;
  private _closeReason: string;
  private _onNewStreamContainerSubj: Subject<StreamContainer>;
  private _mySelfOutputModel: boolean;

  constructor(logger:NGXLogger, inputId: string, currentUserId: number, contentId?: string, streamContainer?: StreamContainer, ) {
    this._id = uuid.v4();
    this._inputIdInfo = new InputIdInfo(inputId);
    this._inputIdInfo.contentId = contentId;
    // need to set before streamContainer setter
    this._mySelfOutputModel = currentUserId === this._inputIdInfo.streamUserId;
    if (streamContainer) {
      this.streamContainer = streamContainer;
    } else {
      this._outputId = this.generateNewOutputId(currentUserId);
      this._outputUserId = currentUserId;
    }
    this._state = WsRtcModelStateEnum.NEW;
    this._onNewStreamContainerSubj = new Subject<StreamContainer>();
    this.logger = logger;
  }

  public generateNewOutputId(currentUserId): string {
    return this._inputIdInfo.generateOutputId(currentUserId);
  }

  get onNewStreamContainer(): Observable<StreamContainer> {
    return this._onNewStreamContainerSubj.asObservable();
  }

  get onNewStreamContainerSubj(): Subject<StreamContainer> {
    return this._onNewStreamContainerSubj;
  }

  get inputIdInfo(): InputIdInfo {
    return this._inputIdInfo;
  }

  get state(): WsRtcModelStateEnum {
    return this._state;
  }

  set state(value: WsRtcModelStateEnum) {
    this._state = value;
  }

  get outputId(): string {
    return this._outputId;
  }

  set outputId(value: string) {
    this._outputId = value;
  }

  get outputUserId() {
    return this._outputUserId;
  }

  get id(): string {
    return this._id;
  }

  get inputId(): string {
    return this._inputIdInfo.inputId;
  }

  get streamType(): StreamTypeEnum {
    return this._inputIdInfo.streamType;
  }

  get roomId(): number {
    return this._inputIdInfo.roomId;
  }

  get streamUserId(): number {
    return this._inputIdInfo.streamUserId;
  }

  get streamId(): number {
    return this._inputIdInfo.streamId;
  }

  get contentId(): string {
    return this._inputIdInfo.contentId;
  }

  get isInput(): boolean {
    return !this._outputId;
  }

  get isOutput(): boolean {
    return !!this._outputId;
  }

  get isConnected(): boolean {
    return this?._state === WsRtcModelStateEnum.CONNECTED;
  }

  get isActual(): boolean {
    return this._state !== WsRtcModelStateEnum.TO_REMOVE;
  }

  get isScreenShare(): boolean {
    return this._inputIdInfo.isInputScreen();
  }

  get pc(): RTCPeerConnection {
    return this._pc;
  }


  set pc(value: RTCPeerConnection) {
    this._pc = value;
  }

  get pcConnectionState(): PCStateEnum {
    return this._pcConnectionState;
  }

  set pcConnectionState(value: PCStateEnum) {
    this._pcConnectionState = value;
  }

  get streamContainer(): StreamContainer {
    return this._streamContainer;
  }

  set streamContainer(value: StreamContainer) {
    this._streamContainer = value;

    if (value instanceof OutputAudioVideoStreamContainer && this._mySelfOutputModel) {
      this.oavStreamContainer.mute(true);
      this.logger.debug("output model is muted because it's _mySelfOutputModel " +
        " wsRtcModel.streamUserId: {}, oavStreamContainer: {}", this.streamUserId, this.oavStreamContainer.toString());
    }
  }

  get closeReason(): string {
    return this._closeReason;
  }

  set closeReason(value: string) {
    this._closeReason = value;
  }

  get oavStreamContainer(): OutputAudioVideoStreamContainer {
    if (this.streamContainer instanceof OutputAudioVideoStreamContainer) {
      return this.streamContainer;
    } else {
      throw new Error("wrong attempt to get OutputAudioVideoStreamContainer");
    }
  }

  toString(): string {
    return "{ _id=" + this._id
    + ", _inputId=" + this._inputIdInfo.inputId
    + ", _online=" + this._inputIdInfo.online
    + ", _roomId=" + this._inputIdInfo.roomId
    + ", _streamUserId=" + this._inputIdInfo.streamUserId
    + ", _streamId=" + this._inputIdInfo.streamId
    + ", _streamType=" + this._inputIdInfo.streamType
    + ", _outputId=" + this._outputId
    + ", _outputUserId=" + this._outputUserId
    + ", pc_state=" + this._pc?.connectionState
    + ", model_pc_state=" + this._pcConnectionState
    + ", model_state=" + this._state.toString()
    + ", _closeReason=" + this._closeReason
    + " }";
  }
}
