import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {Subscription} from "rxjs";

declare const $: any;

@Component({
  selector: 'app-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.css']
})
export class MainPageComponent implements OnInit, OnDestroy {
  @ViewChild('video1', {static: true}) video1: ElementRef;
  @ViewChild('video2', {static: true}) video2: ElementRef;
  year: number;
  textPage: boolean;
  personalDataPolicyText: boolean;
  offerText: boolean;
  showScrollBtn: boolean;

  private paramsSubscription: Subscription;

  constructor(private router: Router,
              private route: ActivatedRoute) {
    this.year = new Date().getFullYear();

    this.textPage = false;
    this.personalDataPolicyText = false;
    this.offerText = false;

    this.showScrollBtn = false;
  }

  ngOnInit(): void {
    $('body').on('scroll', () => {
      this.showScrollBtn = document.body.scrollTop - document.body.clientHeight > 0;
    });
    this.paramsSubscription = this.route.queryParams.subscribe((params) => {
      switch (params['page']) {
        case 'offer':
          this.textPage = true;
          this.offerText = true;
          this.personalDataPolicyText = false;

          document.body.scrollTop = 0;
          document.documentElement.scrollTop = 0;
          break;
        case 'pdp':
          this.textPage = true;
          this.personalDataPolicyText = true;
          this.offerText = false;

          document.body.scrollTop = 0;
          document.documentElement.scrollTop = 0;
          break;
        default:
          this.textPage = false;
          this.personalDataPolicyText = false;
          this.offerText = false;

          document.body.scrollTop = 0;
          document.documentElement.scrollTop = 0;
          break;
      }
    });

    setTimeout(() => {
      this.video1.nativeElement.muted = true;
      this.video1.nativeElement.play();
      this.video2.nativeElement.muted = true;
      this.video2.nativeElement.play();
    }, 300)
  }

  ngOnDestroy(): void {
    $('body').off('scroll');
    this.paramsSubscription.unsubscribe();
  }

  showPersonalDataPolicyText(): void {
    this.router.navigate(['/'], {queryParams: {page: 'pdp'}});
  }

  showOfferText(): void {
    this.router.navigate(['/'], {queryParams: {page: 'offer'}});
  }

  scroll(el: HTMLElement) {
    if (this.textPage) {
      this.router.navigate(['/']);
      setTimeout(()=> this.scrollToElement(el), 100);
    } else {
      this.scrollToElement(el)
    }
  }

  scrollToElement(el: HTMLElement) {
    el.scrollIntoView({behavior:"smooth"});
  }
}
