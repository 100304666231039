import {Component, OnInit} from "@angular/core";
import {VideoPlayerControlsComponent} from "./video-player-controls.component";
import {Globals} from "../../../globals";
import {StateService} from "../../../../services/state.service";
import {VideoStreamService} from "../../../../services/video-stream.service";
import {CookieService} from "ngx-cookie-service";
import {MediaDevicesService} from "../../../../services/media-devices.service";
import {UtilsService} from "../../../../services/utils.service";
import {UserRoomParamService} from "../../../../services/user-room-param.service";
import {UserService} from "../../../../services/user.service";
import {MediaStreamService} from "../../../../services/media-stream-service";
import {MediaServerService} from "../../../../services/media-server.service";
import {NGXLogger} from "ngx-logger";

@Component({
  selector: '[app-video-player-admin-controls]',
  templateUrl: './video-player-controls.component.html',
  styleUrls: ['./video-player-controls.component.css']
})
export class VideoPlayerAdminControlsComponent extends VideoPlayerControlsComponent implements OnInit {
  private readonly STREAM_IS_STARTED_COOKIE_EXPIRY_MINUTES = 10;
  private readonly STREAM_IS_STARTED_COOKIE_PARAM = 'stream_is_started';
  public intervalUpdateStreamIsStartedCookieExpiryDateId: any;

  constructor(globals: Globals,
              subscriptionService: StateService,
              videoStreamService: VideoStreamService,
              mediaStreamService: MediaStreamService,
              private cookieService: CookieService,
              mediaDevicesService: MediaDevicesService,
              userRoomParamService: UserRoomParamService,
              mediaServerService: MediaServerService,
              userService: UserService,
              logger: NGXLogger) {

    super(globals, subscriptionService, videoStreamService, mediaDevicesService, mediaStreamService, userRoomParamService,
      mediaServerService, userService, logger, 'admin');
  }

  ngOnInit(): void {
    // setTimeout(() => {
    const streamId = this.cookieService.get(this.STREAM_IS_STARTED_COOKIE_PARAM);
    if (streamId) {
      this._camStreamId = parseInt(streamId);
      this.stopCamStream();
      // setTimeout(() => {
      this.intervalUpdateStreamIsStartedCookieExpiryDateId = setInterval(() => {
        this.updateStreamIsStartedCookieExpiryDate();
      }, 1000);
      if (!this.tryStartTranslation) {
        this.tryStartTranslation = true;
        this.initCamTimer();
        this.startCamStream();
      }
      // }, 300);
    }
    // });

    this.stateService.subscriptions
      .onStopCamStream
      .subscribe(() => {
        this.stopCamStream();
      });

    this.stateService.subscriptions
      .onFinishCamStream
      .subscribe(() => {
        this.finishCamStream();
      });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this.intervalUpdateStreamIsStartedCookieExpiryDateId) {
      clearInterval(this.intervalUpdateStreamIsStartedCookieExpiryDateId);
    }
  }

  updateStreamIsStartedCookieExpiryDate(): void {
    if (this._camStreamId) {
      const expiryDate = new Date();
      expiryDate.setMinutes(expiryDate.getMinutes() + this.STREAM_IS_STARTED_COOKIE_EXPIRY_MINUTES);
      this.cookieService.set(this.STREAM_IS_STARTED_COOKIE_PARAM, this._camStreamId.toString(), expiryDate);
    }
  }

  public requestStopCamStream() {
    UtilsService.showFancyBox('#modal_stop_translation');
  }
}
