import {ErrorHandler} from '@angular/core';
import {Notifier} from '@airbrake/browser';
import {environment} from "../../environments/environment";
import {NGXLogger} from "ngx-logger";

export class AirbrakeErrorHandler implements ErrorHandler {
  private readonly baseUrl = environment.apiUrl;
  private readonly logUrl = "ui/log";
  private readonly fullLogUrl: string;
  airbrake: Notifier;

  constructor(private logger: NGXLogger) {
    this.fullLogUrl = this.baseUrl + this.logUrl;
    this.airbrake = new Notifier({
      projectId: 267632,
      projectKey: 'd9658ac780bac4c3d0543da6a8ddd25c',
      environment: environment.production ? 'production' : 'test'
    });
  }

  handleError(error: any): void {
    if (environment.production) {
      this.airbrake.notify(error);
    } else {
      console.log(error);
    }

    this.logger.error(error);
  }
}
