import {Injectable, OnDestroy} from "@angular/core";
import {CreatedStreamInfoEvent} from "../model/event/created-stream-info-event.model";
import {Observable, Subject} from "rxjs";
import {MuteEvent} from "../model/event/mute-event.model";
import {CamDisabledEvent} from "../model/event/cam-disabled-event.model";
import {ReadyForCallEvent} from "../model/event/ready-for-call.event";
import {ChangeRoleEvent} from "../model/event/change-role-event.model";
import {RoleTypeEnum} from "../model/enum/role-type.enum";
import {Role} from "../model/role.model";
import {NGXLogger} from "ngx-logger";
import {InputScreenStreamContainer} from "../model/stream-container";

@Injectable({ providedIn: 'root' })
export class StateService implements OnDestroy {
  private readonly _subscriptionServiceRequest: RequestSubscriptions;
  private readonly _subscriptionServiceSubscriptions: Subscriptions;

  private _isConnectedToStream: boolean;
  private _isConnectedToShareStream: boolean;
  private _userIsConnectedToStream: boolean;
  private _userIsInvitedToStream: boolean;
  private _translationIsFinished: boolean;
  private _moderatorApproveFirstQuestion: boolean = false;
  private _deviceSettingsIsChecked: boolean = false;
  private _isRoomSettingsOpened: boolean = false;
  private _issContainer: Promise<InputScreenStreamContainer>;
  private _isSettingsOpenedFromHeader: boolean;

  private _camStreamId: number;

  constructor(private logger: NGXLogger) {
    this._subscriptionServiceRequest = new RequestSubscriptions(logger);
    this._subscriptionServiceSubscriptions = new Subscriptions(logger);


    this._userIsConnectedToStream = false;
    this._isConnectedToStream = false;
    this._translationIsFinished = false;
    // this.logSubjects();
  }


  get requests(): RequestSubscriptions {
    return this._subscriptionServiceRequest;
  }

  get subscriptions(): Subscriptions {
    return this._subscriptionServiceSubscriptions;
  }

  get translationIsFinished(): boolean {
    return this._translationIsFinished;
  }

  set translationIsFinished(value: boolean) {
    this._translationIsFinished = value;
  }

  get camStreamId(): number {
    return this._camStreamId;
  }

  set camStreamId(value: number) {
    this._camStreamId = value;
  }

  get isConnectedToShareStream(): boolean {
    return this._isConnectedToShareStream;
  }

  set isConnectedToShareStream(value: boolean) {
    this._isConnectedToShareStream = value;
  }

  get userIsInvitedToStream(): boolean {
    return this._userIsInvitedToStream;
  }

  set userIsInvitedToStream(value: boolean) {
    this._userIsInvitedToStream = value;
  }

  get isConnectedToStream(): boolean {
    return this._isConnectedToStream;
  }

  set isConnectedToStream(value: boolean) {
    this._isConnectedToStream = value;
  }

  get userIsConnectedToStream(): boolean {
    return this._userIsConnectedToStream;
  }

  set userIsConnectedToStream(value: boolean) {
    this._userIsConnectedToStream = value;
  }

  get moderatorApproveFirstQuestion(): boolean {
    return this._moderatorApproveFirstQuestion;
  }

  set moderatorApproveFirstQuestion(value: boolean) {
    this._moderatorApproveFirstQuestion = value;
  }

  get deviceSettingsIsChecked(): boolean {
    return this._deviceSettingsIsChecked;
  }

  set deviceSettingsIsChecked(value: boolean) {
    this._deviceSettingsIsChecked = value;
  }

  get isRoomSettingsOpened(): boolean {
    return this._isRoomSettingsOpened;
  }

  set isRoomSettingsOpened(value: boolean) {
    if (value == false) {
      this._isSettingsOpenedFromHeader = false;
    }
    this._isRoomSettingsOpened = value;
  }

  get issContainer(): Promise<InputScreenStreamContainer> {
    return this._issContainer;
  }

  set issContainer(value: Promise<InputScreenStreamContainer>) {
    this._issContainer = value;
  }

  get isSettingsOpenedFromHeader(): boolean {
    return this._isSettingsOpenedFromHeader;
  }

  set isSettingsOpenedFromHeader(value: boolean) {
    this._isSettingsOpenedFromHeader = value;
  }

  private logSubjects(): void {
    this.requests.logSubjects();
    this.subscriptions.logSubjects();
  }

  ngOnDestroy(): void {
    this.requests.ngOnDestroy();
    this.subscriptions.ngOnDestroy();
  }
}

export class RequestSubscriptions implements OnDestroy {
  private readonly _userReadyForCallRequestSubject: Subject<number>;
  private readonly _userConnectedToStreamRequestSubject: Subject<CreatedStreamInfoEvent>;
  private readonly _muteUserRequestSubject: Subject<MuteEvent>;
  private readonly _disableCamRequestSubject: Subject<CamDisabledEvent>;
  private readonly _changeRoleRequestSubject: Subject<ChangeRoleEvent>;


  constructor(private logger: NGXLogger) {
    this._userReadyForCallRequestSubject = new Subject<number>();
    this._userConnectedToStreamRequestSubject = new Subject<CreatedStreamInfoEvent>();
    this._muteUserRequestSubject = new Subject<MuteEvent>();
    this._disableCamRequestSubject = new Subject<CamDisabledEvent>();
    this._changeRoleRequestSubject = new Subject<ChangeRoleEvent>();
  }

  get onUserReadyForCallRequest(): Observable<number> {
    return this._userReadyForCallRequestSubject.asObservable();
  }

  get onUserConnectedToStreamRequest(): Observable<CreatedStreamInfoEvent> {
    return this._userConnectedToStreamRequestSubject.asObservable();
  }

  get onMuteUserRequest(): Observable<MuteEvent> {
    return this._muteUserRequestSubject.asObservable();
  }

  get onDisableCamRequest(): Observable<CamDisabledEvent> {
    return this._disableCamRequestSubject.asObservable();
  }

  get onChangeRoleRequest(): Observable<ChangeRoleEvent> {
    return this._changeRoleRequestSubject.asObservable();
  }

  public sendChangeRoleRequest(userId: number, newRole: RoleTypeEnum, oldRole: RoleTypeEnum): void {
    this._changeRoleRequestSubject.next(new ChangeRoleEvent(userId, new Role(newRole), new Role(oldRole)));
  }

  public sendIsUserReadyForCallRequest(userId: number): void {
    this._userReadyForCallRequestSubject.next(userId);
  }

  public sendIsUserConnectedToStreamRequest(userId: number, streamId?: number): void {
    this._userConnectedToStreamRequestSubject.next(new CreatedStreamInfoEvent(userId, streamId));
  }

  public sendMuteUserRequest(userId: number, streamId: number, mute: boolean): void {
    this._muteUserRequestSubject.next(new MuteEvent(userId, streamId, mute));
  }

  public sendDisableCamRequest(userId: number, streamId: number, disable: boolean): void {
    this._disableCamRequestSubject.next(new CamDisabledEvent(userId, streamId, disable));
  }

  ngOnDestroy(): void {
    this._userReadyForCallRequestSubject.complete()
    this._userConnectedToStreamRequestSubject.complete()
    this._muteUserRequestSubject.complete()
    this._disableCamRequestSubject.complete()
  }

  public logSubjects(): void {
    this._userReadyForCallRequestSubject.asObservable().subscribe(value => this.logger.debug("_userReadyForCallRequestSubject: {}", value));
    this._userConnectedToStreamRequestSubject.asObservable().subscribe(value => this.logger.debug("_userConnectedToStreamRequestSubject: {}", value));
    this._muteUserRequestSubject.asObservable().subscribe(value => this.logger.debug("_muteUserRequestSubject: {}", value));
    this._disableCamRequestSubject.asObservable().subscribe(value => this.logger.debug("_disableCamRequestSubject: {}", value));
  }

}

export class Subscriptions implements OnDestroy {
  private readonly _mutedUserSubscription: Subject<MuteEvent>;
  private readonly _disabledCamSubscription: Subject<CamDisabledEvent>;
  private readonly _userConnectedToStream: Subject<CreatedStreamInfoEvent>;
  private readonly _connectUserToStream: Subject<void>;
  private readonly _userReadyForCall: Subject<ReadyForCallEvent>;
  private readonly _connectedToCreatedStream = new Subject<CreatedStreamInfoEvent>();
  private readonly _connectedToCreatedShareStream = new Subject<CreatedStreamInfoEvent>();
  private readonly _stopCamStreamSubject: Subject<void>;
  private readonly _finishCamStreamSubject: Subject<void>;
  private readonly _translationFinishedSubject: Subject<void>;
  private readonly _adminRecordTimeUpdateSubject: Subject<number>;
  private readonly _adminRecordStopSubject: Subject<void>;


  constructor(private logger: NGXLogger) {
    this._mutedUserSubscription = new Subject<MuteEvent>();
    this._disabledCamSubscription = new Subject<CamDisabledEvent>();
    this._userConnectedToStream = new Subject<CreatedStreamInfoEvent>();
    this._connectUserToStream = new Subject<void>();
    this._userReadyForCall = new Subject<ReadyForCallEvent>();
    this._connectedToCreatedStream = new Subject<CreatedStreamInfoEvent>();
    this._connectedToCreatedShareStream = new Subject<CreatedStreamInfoEvent>();
    this._stopCamStreamSubject = new Subject<void>();
    this._finishCamStreamSubject = new Subject<void>();
    this._translationFinishedSubject = new Subject<void>();
    this._adminRecordTimeUpdateSubject = new Subject<number>();
    this._adminRecordStopSubject = new Subject<void>();
  }

  get onReadyForCall(): Observable<ReadyForCallEvent> {
    return this._userReadyForCall.asObservable();
  }

  get onMutedUser(): Observable<MuteEvent> {
    return this._mutedUserSubscription.asObservable();
  }

  get onDisabledCam(): Observable<CamDisabledEvent> {
    return this._disabledCamSubscription.asObservable();
  }

  get onUserConnectedToStream(): Observable<CreatedStreamInfoEvent> {
    return this._userConnectedToStream.asObservable();
  }

  get onConnectedToCreatedShareStream(): Observable<CreatedStreamInfoEvent> {
    return this._connectedToCreatedShareStream.asObservable();
  }

  get onConnectedToCreatedStream(): Observable<CreatedStreamInfoEvent> {
    return this._connectedToCreatedStream.asObservable();
  }

  get onAdminRecordStop(): Observable<void> {
    return this._adminRecordStopSubject.asObservable();
  }

  get onAdminRecordTimeUpdate(): Observable<number> {
    return this._adminRecordTimeUpdateSubject.asObservable();
  }

  get onTranslationFinished(): Observable<void> {
    return this._translationFinishedSubject.asObservable();
  }

  get onStopCamStream(): Observable<void> {
    return this._stopCamStreamSubject;
  }

  get onFinishCamStream(): Observable<void> {
    return this._finishCamStreamSubject;
  }

  get connectUserToStream(): Subject<void> {
    return this._connectUserToStream;
  }

  sendReadyForCallEvent(userId: number, isReady: boolean): void {
    this._userReadyForCall.next(new ReadyForCallEvent(userId, isReady));
  }

  sendUserConnectedToStreamEvent(userId: number, streamId: number, status: boolean): void {
    this._userConnectedToStream.next(new CreatedStreamInfoEvent(userId, streamId, status));
  }

  sendMutedUserEvent(userId: number, streamId: number, mute: boolean): void {
    this._mutedUserSubscription.next(new MuteEvent(userId, streamId, mute));
  }

  sendDisabledCamEvent(userId: number, streamId: number, disabled: boolean): void {
    this._disabledCamSubscription.next(new CamDisabledEvent(userId, streamId, disabled));
  }

  sendConnectedToCreatedStreamEvent(userId: number, streamId: number, status: boolean = true, isShareScreen: boolean = false): void {
    this.logger.debug("sendConnectedToCreatedStreamEvent: {}", userId + ":" + status);
    if (isShareScreen) {
      this._connectedToCreatedShareStream.next(new CreatedStreamInfoEvent(userId, streamId, status, isShareScreen));
    } else {
      this._connectedToCreatedStream.next(new CreatedStreamInfoEvent(userId, streamId, status, isShareScreen));
    }
  }

  sendAdminRecordStopEvent(): void {
    this._adminRecordStopSubject.next();
  }

  sendAdminRecordTimeUpdateEvent(currentTime: number): void {
    this._adminRecordTimeUpdateSubject.next(currentTime);
  }

  sendTranslationFinishedEvent(): void {
    this._translationFinishedSubject.next();
  }

  sendStopCamStreamEvent(): void {
    this._stopCamStreamSubject.next();
  }

  sendFinishCamStreamEvent(): void {
    this._finishCamStreamSubject.next();
  }

  ngOnDestroy(): void {
    this._mutedUserSubscription.complete();
    this._disabledCamSubscription.complete();
    this._userConnectedToStream.complete();
    this._userReadyForCall.complete();
    this._connectedToCreatedStream.complete();
    this._connectedToCreatedShareStream.complete();
    this._stopCamStreamSubject.complete();
    this._finishCamStreamSubject.complete();
    this._translationFinishedSubject.complete();
    this._adminRecordTimeUpdateSubject.complete();
    this._adminRecordStopSubject.complete();
  }

  public logSubjects(): void {
    this._mutedUserSubscription.asObservable().subscribe(value => this.logger.debug("_mutedUserSubscription: {}", value));
    this._disabledCamSubscription.asObservable().subscribe(value => this.logger.debug("_disabledCamSubscription: {}", value));
    this._userConnectedToStream.asObservable().subscribe(value => this.logger.debug("_userConnectedToStream: {}", value));
    this._userReadyForCall.asObservable().subscribe(value => this.logger.debug("_userReadyForCall: {}", value));
    this._connectedToCreatedStream.asObservable().subscribe(value => this.logger.debug("_connectedToCreatedStream: {}", value));
    this._connectedToCreatedShareStream.asObservable().subscribe(value => this.logger.debug("_connectedToCreatedShareStream: {}", value));
    this._stopCamStreamSubject.asObservable().subscribe(value => this.logger.debug("_stopCamStreamSubject: {}", value));
    this._finishCamStreamSubject.asObservable().subscribe(value => this.logger.debug("_finishCamStreamSubject: {}", value));
    this._translationFinishedSubject.asObservable().subscribe(value => this.logger.debug("_translationFinishedSubject: {}", value));
    this._adminRecordTimeUpdateSubject.asObservable().subscribe(value => this.logger.debug("_adminRecordTimeUpdateSubject: {}", value));
    this._adminRecordStopSubject.asObservable().subscribe(value => this.logger.debug("_adminRecordStopSubject: {}", value));
  }
}
