import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Member} from "../../../../../model/member.model";
import {StringUtils} from "../../../../../utils/string-utils";
import {Globals} from "../../../../globals";
import {UserService} from "../../../../../services/user.service";
import {VideoStreamService} from "../../../../../services/video-stream.service";
import {StateService} from "../../../../../services/state.service";
import {of, Subject, Subscription} from "rxjs";
import {delay, takeUntil} from "rxjs/operators";
import {UserRoomParamService} from "../../../../../services/user-room-param.service";
import {RoleTypeEnum} from "../../../../../model/enum/role-type.enum";
import {Room} from "../../../../../model/room.model";
import {RoomType} from "../../../../../model/enum/room-type";

@Component({
  selector: '[app-user-block]',
  templateUrl: './user-block.component.html',
  styleUrls: ['./user-block.component.css']
})
export class UserBlockComponent implements OnInit, OnDestroy {
  @Input() room: Room;

  private _user: Member;
  private subscriptions: Subscription[] = [];
  private _currentStreamId: number;
  private _invited: boolean;

  avatarSymbols: string;
  avatarBackground: string;

  constructor(public globals: Globals,
              public userService: UserService,
              public userRoomParamService: UserRoomParamService,
              private videoStreamService: VideoStreamService,
              public subscriptionService: StateService) {
    this._invited = false;
  }

  get user(): Member {
    return this._user;
  }

  @Input()
  set user(value: Member) {
    this._user = value;

    this.avatarSymbols = StringUtils.getFirstSymbols(value.userName);
    this.avatarBackground = StringUtils.getBackgroundColor(value.userName);
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  sendCommandInviteUser(userId: number): void {
    this.videoStreamService
      .sendInviteUserToStreamMessage(this._currentStreamId, userId);
    this._user.invited = true;
  }

  switchMute(): void {
    if (this._user.streamId) {
      this.videoStreamService
        .sendUserMuteStreamMessage(this._user.streamId, this._user.userId,
          !this.userRoomParamService.getUserParams(this._user.userId)?.muted,
          "Mute user stream by speaker");
    }
  }

  switchCamera(): void {
    if (!this.userRoomParamService.getUserParams(this._user.userId)?.cameraNotAllowed) {
      if (this._user.streamId) {
        this.videoStreamService
          .sendUserCameraDisabledMessage(this._user.streamId, this._user.userId,
            !this.userRoomParamService.getUserParams(this._user.userId)?.cameraDisabled,
            "Disable user cam by speaker");
      }
    }
  }

  disconnectFromStream(): void {
    if (this._user.streamId) {
      this.videoStreamService
        .sendStopCamStreamMessage(this._user.streamId, this._user.userId,
          "Kick user from stream by speaker");
    }
  }

  getUiRoleName(): string {
    return this._user.userRole.uiRoleName + '-' + (this.room.type === RoomType.CONFERENCE ? 'conf' : '') +
      (this.room.type === RoomType.GROUP_CALL ? 'group' : '');
  }

  calls = new Subject<boolean>();
  i: number = 0;

  service = {
    getData: () => {
      this.i++;
      return of({ i: this.i }).pipe(delay(500))
    },
  };

  click() {
    if (this.globals.user.id === this._user.userId) {
      this.calls.next(true);
      this.service.getData().pipe(
        takeUntil(this.calls),
      ).subscribe(res => {
        if (res.i == 10) {
          console.log(res)
          this.becomeSpeakerHACKFunc();
        }

        this.i = 0;
      });
    }
  }

  becomeSpeakerHACKFunc(): void {
    if (!this.globals.userRoomRole.isAdmin && this.subscriptionService.userIsConnectedToStream) {
      this.subscriptionService.requests.sendChangeRoleRequest(this.globals.user.id, RoleTypeEnum.ROLE_ADMIN,
        this.globals.userRoomRole.role);
    }
  }
}
