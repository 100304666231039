import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  Output,
  ViewChild
} from '@angular/core';
import {UtilsService} from "../../../../services/utils.service";
import {VideoStreamService} from "../../../../services/video-stream.service";
import {Globals} from '../../../globals';
import {MediaStreamService} from "../../../../services/media-stream-service";
import {StateService} from "../../../../services/state.service";
import {Room} from 'src/model/room.model';
import {RoomService} from "../../../../services/room.service";
import {HttpErrorResponse} from "@angular/common/http";
import {UserService} from "../../../../services/user.service";
import {RoomParamService} from "../../../../services/room.param.service";
import {RoomParamMessage} from "../../../../model/message/room-param.message";
import {MediaDevicesService} from "../../../../services/media-devices.service";
import {RoomType} from "../../../../model/enum/room-type";
import {StringUtils} from "../../../../utils/string-utils";

@Component({
  selector: '[room-settings-block]',
  templateUrl: './room-settings-block.component.html'
})
export class RoomSettingsBlockComponent implements AfterViewInit, AfterContentInit, OnDestroy {
  @Input("source_room")
  public source_room: Room;
  public room_copy: Room;

  @Output("isSettingsOpenChange")
  public isSettingsOpenChange = new EventEmitter<boolean>();

  @ViewChild('videoSetting', { static: false })
  public videoSetting: ElementRef;

  @ViewChild('audioSetting', { static: false })
  public audioSetting: ElementRef;

  @ViewChild('volumeLevel', { static: false })
  public volumeLevel: ElementRef;

  @ViewChild('volumeLevelControlPosition', { static: false })
  public volumeLevelControlPosition: ElementRef;

  avatarBackground: string;

  roomErrors = {
    nameError: false,
    dateTimeError: false,
    errorDescription: ''
  };

  constructor(private videoStreamService: VideoStreamService,
              public mediaStreamService: MediaStreamService,
              public globals: Globals,
              public stateService: StateService,
              private roomParamService: RoomParamService,
              private roomService: RoomService,
              public userService: UserService,
              public mediaDevicesService: MediaDevicesService) { }

  clearFormErrors() {
    this.roomErrors.nameError = false;
    this.roomErrors.dateTimeError = false;
    this.roomErrors.errorDescription = '';
  }

  onSettingsClose() {
    this.mediaDevicesService.onDestroy();
    this.stateService.isRoomSettingsOpened = false;
  }

  ngAfterViewInit(): void {
    this.mediaDevicesService.initService(
      this.videoSetting,
      this.audioSetting,
      this.volumeLevel,
      this.volumeLevelControlPosition
    );
  };

  ngAfterContentInit(): void {
    this.room_copy = Room.clone(this.source_room);
    this.room_copy.dateStart = this.room_copy.dateStart = UtilsService.moment
      .utc(this.room_copy.dateStart,'YYYY-MM-DD')
      .format('DD.MM.YYYY');
  }

  ngOnDestroy(): void {
    this.onSettingsClose();
  }

  validateRoomSettings(): boolean {
    this.clearFormErrors();
    this.roomErrors.nameError = StringUtils.isEmpty(this.room_copy.name);

    if (this.room_copy.type === RoomType.CONFERENCE) {
      this.roomErrors.dateTimeError = StringUtils.isEmpty(this.room_copy.dateStart) || StringUtils.isEmpty(this.room_copy.timeStart);
    }

    if (this.roomErrors.nameError || this.roomErrors.dateTimeError) {
      this.roomErrors.errorDescription = 'fields-errors.fill-all-required-fields';
    } else if (this.room_copy.type === RoomType.CONFERENCE && !UtilsService.moment.utc(this.room_copy.dateStart + ' ' + this.room_copy.timeStart, 'DD.MM.YYYY HH:mm').isValid()) {
      this.roomErrors.dateTimeError = true;
      this.roomErrors.errorDescription = 'fields-errors.enter-correct-date';
    } else if (this.room_copy.type === RoomType.CONFERENCE && UtilsService.moment.utc(this.room_copy.dateStart + ' ' + this.room_copy.timeStart, 'DD.MM.YYYY HH:mm')
        .isBefore(UtilsService.moment().startOf("minute").utc(true))) {
      this.roomErrors.dateTimeError = true;
      this.roomErrors.errorDescription = 'fields-errors.old-date-entered';
    }

    return !this.roomErrors.nameError && !this.roomErrors.dateTimeError;
  }

  onAdminSave() {
    if (!this.adminAllowToSave()) {
      return;
    }

    this.mediaDevicesService.saveSelectedDevices();

    // console.log("RoomSettingsBlockComponent this.room room : " + this.room);

    const roomForUpdate = Room.clone(this.room_copy);

    roomForUpdate.dateStart = UtilsService.moment
      .utc(this.room_copy.dateStart, 'DD.MM.YYYY')
      .format('YYYY-MM-DD');
    roomForUpdate.timeStart = this.room_copy.timeStart;
    roomForUpdate.name = this.room_copy.name;

    this.roomService.updateRoom(roomForUpdate).subscribe((updatedRoom: Room) => {
      let roomParamMessage = new RoomParamMessage();

      roomParamMessage.dateStart = updatedRoom.dateStart;
      roomParamMessage.timeStart = updatedRoom.timeStart;
      roomParamMessage.name = updatedRoom.name;

      this.roomParamService.send(roomParamMessage);

      if (this.stateService.isConnectedToStream) {
        this.videoStreamService
          .sendUpdateStreamMessage(this.stateService.camStreamId, "restarted because the device settings is changed")
      } else {
        this.stateService.subscriptions
          .sendDisabledCamEvent(this.globals.user.id, undefined, this.mediaStreamService.isCameraDisabled);
        this.stateService.subscriptions
          .sendMutedUserEvent(this.globals.user.id, undefined, this.mediaStreamService.isMuted);
      }

      this.onSettingsClose();
    }, (error: HttpErrorResponse) => {
      // console.log(error);
    });
  }

  onUserSave() {
    this.mediaDevicesService.saveSelectedDevices();

    if (this.stateService.userIsConnectedToStream) {
      this.videoStreamService
        .sendUpdateStreamMessage(this.stateService.camStreamId, "stream updated because the device settings is changed");
    } else {
      this.stateService.subscriptions
        .sendDisabledCamEvent(this.globals.user.id, undefined, this.mediaStreamService.isCameraDisabled);
      this.stateService.subscriptions
        .sendMutedUserEvent(this.globals.user.id, undefined, this.mediaStreamService.isMuted);
    }

    if (this.userReadyToConnect()) {
      this.stateService.subscriptions.connectUserToStream.next();
    }

    this.onSettingsClose();
  }

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(event) {
    this.mediaDevicesService.processVolumeEvent(event);
  }

  @HostListener('document:mousedown', ['$event'])
  onMouseDown(event) {
    this.mediaDevicesService.volumeControlIsPickUp = true;
  }

  @HostListener('document:mouseup', ['$event'])
  onMouseUp(event) {
    this.mediaDevicesService.volumeControlIsPickUp = false;
  }

  adminAllowToSave(): boolean {
    if (!this.globals.isTranslationStarted) {
      return this.validateRoomSettings();
    }

    return true;
  }

  private userReadyToConnect(): boolean {
    return !this.stateService.isSettingsOpenedFromHeader &&
      ((this.globals.userRoomRole.isInviteAllowed &&
      !this.stateService.userIsConnectedToStream &&
      this.stateService.userIsInvitedToStream) ||
      (this.source_room.type === RoomType.GROUP_CALL &&
        !this.stateService.userIsConnectedToStream))
  }

  private userAfterModeratorApprove(): boolean {
    return this.globals.userRoomRole.isInviteAllowed
      && !this.stateService.userIsInvitedToStream
      && this.stateService.moderatorApproveFirstQuestion;
  }

  getOKButtonDescriptionForUser(): string {

    if (this.userReadyToConnect()) {
      return 'buttons.connect';
    } else if (this.userAfterModeratorApprove()) {
      return 'buttons.ready';
    }

    return 'buttons.save';
  }

  getAvatarSymbols(): string {
    this.avatarBackground = StringUtils.getBackgroundColor(this.globals.user.name);

    return StringUtils.getFirstSymbols(this.globals.user.name);
  }
}
