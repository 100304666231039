import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {StringUtils} from "../../../../../utils/string-utils";
import {WsRtcModel} from "../../../../../model/ws-rtc.model";
import {Subscription} from "rxjs";

@Component({
  selector: '[app-video-player-grid-item]',
  templateUrl: './video-player-grid-item.component.html',
  styleUrls: ['./video-player-grid-item.component.css']
})
export class VideoPlayerGridItemComponent implements OnInit, OnDestroy {
  @Input() picture: string;
  @Input() mute: boolean;
  @Output() recalculate = new EventEmitter<void>();

  @Output() speaking = new EventEmitter<boolean>();

  private _name: string;
  private _video: WsRtcModel;
  private _cameraDisabled: boolean;
  private speak: boolean;
  private speakSubscription: Subscription;
  private interval;

  avatarSymbols: string;
  avatarBackground: string;

  constructor() {
  }

  get name(): string {
    return this._name;
  }

  @Input()
  set name(value: string) {
    this._name = value;

    this.avatarSymbols = StringUtils.getFirstSymbols(value);
    this.avatarBackground = StringUtils.getBackgroundColor(value);
  }

  get video(): WsRtcModel {
    return this._video;
  }

  @Input()
  set video(value: WsRtcModel) {
    this._video = value;
    this.speakerReSubscribe();

    value.onNewStreamContainer.subscribe((streamContainer) => {
      this.speakerReSubscribe();
    })
  }

  get cameraDisabled(): boolean {
    return this._cameraDisabled;
  }

  @Input()
  set cameraDisabled(value: boolean) {
    if (value !== this._cameraDisabled) {
      this._cameraDisabled = value;
      this.recalculate.emit();
    }
  }

  ngOnInit(): void {
    this.sendRecalculate();
  }

  ngOnDestroy(): void {
    this.sendRecalculate();
    if (this.speakSubscription) {
      this.speakSubscription.unsubscribe();
    }
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  sendRecalculate(): void {
    this.recalculate.emit();
  }

  private speakerReSubscribe() {
    if (!this._video.isScreenShare) {
      if (this.speakSubscription) {
        this.speakSubscription.unsubscribe();
      }
      let lastTrueTime = Date.now();
      this.speakSubscription = this._video.streamContainer.onSpeak.subscribe(speak => {
        if ((!speak && lastTrueTime + 300 < Date.now()) || speak) {
          this.speak = speak;
          lastTrueTime = Date.now();
        }
      });

      this.interval = setInterval(() => this.speaking.emit(this.speak), 100);
    }
  }
}
