import {VideoStreamMessage} from "../model/message/video-stream.message";
import {VideoStreamCommandTypeEnum} from "../model/enum/video-stream-command-type.enum";
import {StreamTypeEnum} from "../model/enum/stream-type-enum";
import {MessageScopeEnum} from "../model/enum/message-scope.enum";

export class VideoStreamMessageBuilder {
  private videoStreamMessage = new VideoStreamMessage();

  public toUserId(userId: number): VideoStreamMessageBuilder {
    this.videoStreamMessage.toUserId = userId;
    return this;
  }

  public fromUserId(fromUserId: number): VideoStreamMessageBuilder {
    this.videoStreamMessage.fromUserId = fromUserId;
    return this;
  }

  public streamId(streamId: number): VideoStreamMessageBuilder {
    this.videoStreamMessage.streamId = streamId;
    return this;
  }

  public roomId(roomId: number): VideoStreamMessageBuilder {
    this.videoStreamMessage.roomId = roomId;
    return this;
  }

  public streamUserId(streamUserId: number): VideoStreamMessageBuilder {
    this.videoStreamMessage.streamUserId = streamUserId;
    return this;
  }

  public contentId(contentId: string): VideoStreamMessageBuilder {
    this.videoStreamMessage.contentId = contentId;
    return this;
  }

  public inputId(inputId: string): VideoStreamMessageBuilder {
    this.videoStreamMessage.inputId = inputId;
    return this;
  }

  public outputId(outputId: string): VideoStreamMessageBuilder {
    this.videoStreamMessage.outputId = outputId;
    return this;
  }

  public streamType(streamType: StreamTypeEnum): VideoStreamMessageBuilder {
    this.videoStreamMessage.streamType = streamType;
    return this;
  }

  public command(command: VideoStreamCommandTypeEnum): VideoStreamMessageBuilder {
    this.videoStreamMessage.command = command;
    return this;
  }

  public statusParam(statusParam: boolean): VideoStreamMessageBuilder {
    this.videoStreamMessage.statusParam = statusParam;
    return this;
  }

  public reason(reason: string): VideoStreamMessageBuilder {
    this.videoStreamMessage.reason = reason;
    return this;
  }

  public messageScope(messageScope: MessageScopeEnum): VideoStreamMessageBuilder {
    this.videoStreamMessage.messageScope = messageScope;
    return this;
  }

  public build(): VideoStreamMessage {
    return this.videoStreamMessage;
  }
}
