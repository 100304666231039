import {EventEmitter, Injectable} from '@angular/core';
import {User} from '../model/user.model';
import {Role} from "../model/role.model";
import {environment} from "../environments/environment";
import {RoleTypeEnum} from "../model/enum/role-type.enum";
import {NGXLogger} from "ngx-logger";
import {HttpParams} from "@angular/common/http";
import {Room} from "../model/room.model";

@Injectable()
export class Globals {
  authenticated: boolean = null;
  private _user: User = new User();
  private _room: Room;
  showUnsupportedModal: boolean = false;
  browser: string;

  inviteLink: string;
  invitePass: string;
  currentStreamId: number;

  userNameForBlock: string;
  userBlockCallback: EventEmitter<void>;

  hasModeratorInRoom: boolean = false;
  adminUserId: number;
  isTranslationStarted: boolean;
  showSidebar: boolean = true;

  private _userRoomRole: Role = new Role(RoleTypeEnum.UNKNOWN);
  private _oldUserRoomRole: Role = new Role(RoleTypeEnum.UNKNOWN);

  constructor(private logger: NGXLogger) {
    this.setLoggerUserInfoHeaders();
  }

  get user(): User {
    return this._user;
  }

  set user(user: User) {
    this._user = user;
    this.setLoggerUserInfoHeaders();
  }

  get room(): Room {
    return this._room;
  }

  set room(room: Room) {
    this._room = room;
    this.setLoggerUserInfoHeaders();
  }

  get userRoomRole(): Role {
    return this._userRoomRole;
  }

  set userRoomRole(role: Role) {
    if (this._oldUserRoomRole.isUnknown) {
      this._oldUserRoomRole = role;
    } else {
      this._oldUserRoomRole = this._userRoomRole;
    }

    this._userRoomRole = role;
    this.setLoggerUserInfoHeaders();
  }

  get isRoleChanged(): boolean {
    return !this._oldUserRoomRole.equals(this._userRoomRole);
  }

  public isProdTest(): boolean {
    return environment.name === 'prod' || environment.name === 'test'
  }

  private setLoggerUserInfoHeaders(): void {
    let params = new HttpParams()
      .set("user-id", this._user.id?.toString())
      .set("user-name", this._user?.name)
      .set("user-role", this._userRoomRole?.role)
      .set("room-id", this._room?.id.toString())
      .set("room-name", this._room?.name);

    this.logger.setCustomParams(params);
  }
}
